import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-input-prompt-dialog',
  templateUrl: './generic-input-prompt-dialog.component.html',
  styleUrls: ['./generic-input-prompt-dialog.component.scss']
})
export class GenericInputPromptDialogComponent implements OnInit {

  message = new FormControl('');

  top: number;

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.adjustDialogPlacement();
  }

  constructor(public dialogRef: MatDialogRef<GenericInputPromptDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: InputPromptData) {}

  ngOnInit(): void {
    this.adjustDialogPlacement();
  }

  get textAreaMaxHeight(): number {
    // subtract top position of dialog from total window height, add 200 extra margin at bottom
    return window.innerHeight - this.top - 200;
  }

  adjustDialogPlacement() {
    if (this.data.overlayOnElement) {
      const bound = this.data.overlayOnElement.getBoundingClientRect();

      // bit hacky/ugly, don't allow the top to be higher than the core filter lower bound + padding
      const maxTop = document.getElementById('app-support-core-filter-container').getBoundingClientRect().bottom + 8;
      this.top = Math.max(bound.y, maxTop);
      const position = {
        left: `${bound.x.toString()}px`,
        top: `${this.top.toString()}px`
      };
      this.dialogRef.updatePosition(position);
      this.dialogRef.updateSize(`${bound.width.toString()}px`, `${bound.height.toString()}px`);

    }
  }

  submit() {
    const value = this.message.value.length > 0 ? this.message.value : null;
    this.dialogRef.close({message: value});
  }

  noComment() {
    this.dialogRef.close({message: null, noComment: true});
  }

  close() {
    this.dialogRef.close();
  }

}

export interface InputPromptData {
  text?: string;
  html?: string;
  overlayOnElement?: HTMLElement;
  inputPlaceholder?: string;
  optional: boolean;
}
