import { Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CoreParameters } from '../../model/core-parameters.model';
import { Happiness } from '../../model/happiness.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
})
export class DefaultComponent extends CoreParametersConsumer {
  titleVisitorRating = 'Visitor rating';
  titleTotalRating = 'Total rating';
  titleLeadsInPeriod = 'Number of leads';
  cardClassLeft = 'half-card left';
  cardClassRight = 'half-card right';
  defaultColorClass = 'happiness-default';
  averageRating: number;
  totalRating: number;
  noOfLeads: number;
  colorClassByRating = '';

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters): void {
    this.visitorHappiness(coreParameters);
    this.getTotalRating(coreParameters);
    this.leadsInPeriod(coreParameters);
  }

  visitorHappiness(coreParameters: CoreParameters) {
    this.graphService.getVisitorHappiness(
      coreParameters).pipe(
      tap((visitorHappiness: Happiness) => {
          if (visitorHappiness) {
            this.averageRating = visitorHappiness.averageRating;
            this.colorClassByRating = this.colorService.getHappinessColorClass(Number(this.averageRating));
          }
        },
      ),
    ).subscribe();
  }

  getTotalRating(coreParameters: CoreParameters) {
    this.graphService.getTotalRating(
      coreParameters).pipe(
      tap((totalHappiness: Happiness) => {
          if (totalHappiness) {
            this.totalRating = totalHappiness.averageRating;
            this.colorClassByRating = this.colorService.getHappinessColorClass(Number(this.totalRating));
          }
        },
      ),
    ).subscribe();
  }

  leadsInPeriod(coreParameters: CoreParameters) {
    this.graphService.getLeadsInPeriod(
      coreParameters).pipe(
      tap((leads: number) => {
          this.noOfLeads = leads;
        },
      ),
    ).subscribe();
  }
}
