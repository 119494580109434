import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthorizationService } from '../../core/authorization.service';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  @Input() buttonsDisabled: boolean;

  actionCounts: Array<{view: string, count: number}>;

  activeRoute: string;

  constructor(private authService: AuthorizationService,
              private supportService: SupportService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.activeRoute = this.router.url;

    this.getActionCounts();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.activeRoute = event.url;
        this.getActionCounts();
      }
    });
  }

  getActionCounts() {
    this.supportService.getActionCounts().subscribe(a => this.actionCounts = a);
  }

  get isTeamleadOrBetter(): boolean {
    return this.authService.isTeamLead || this.authService.isLcd || this.authService.isInAdminGroup;
  }

  get isIntermediatePhase(): boolean {
    return this.authService.isIntermediatePhase;
  }

  get isClient(): boolean {
    return this.authService.isClient;
  }

  get isLCDOrBetter(): boolean {
    return this.authService.isLcd || this.authService.isInAdminGroup;
  }

  hasPermission(permission: string, view: string) {
    return this.authService.hasPermission(permission, view, 'SS');
  }

  hasAction(view: string) {
    return this.actionCounts?.find(a => a.view === view)?.count > 0;
  }

  countByView(view: string): string {
    return this.actionCounts?.find(ac => ac.view === view)?.count?.toString();
  }

  isActiveRoute(route: string) {
    return this.activeRoute === `/support/${route}`;
  }
}
