import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { ReviewMessage } from '../../../model/support/review-message.model';
import { SessionChatInfo } from '../../../model/support/session-chat-info.model';
import { SessionInfo } from '../../../model/support/session-info.model';
import { MessageService } from '../../service/message.service';
import { ReviewService } from '../../service/review.service';
import { SupportService } from '../../service/support.service';

@Component({
  selector: 'app-feedback-recieved',
  templateUrl: './feedback-recieved.component.html',
})
export class FeedbackRecievedComponent implements OnInit, OnDestroy {

  tableDataLength: number;
  displayedColumns: string[] = ['startDate',
    'visitorRating', 'clientRating', 'conversationUid', 'account', 'classificationCategory'];
  tableDataSource: MatTableDataSource<SessionInfo>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @Input()
    isHQ: boolean = false;
  @Input()
  title: string = 'Open Feedback';
  subscription: Subscription;
  conversationUid: string;
  @Output()
  detailscreen = new EventEmitter<string>();
  sessionInfoPlayList: SessionInfo[];
  sessionChatInfo: SessionChatInfo;
  reviewMessages: ReviewMessage[];

  constructor(private supportService: SupportService,
              private authorizationService: AuthorizationService,
              private reviewService: ReviewService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.subscription = this.supportService
      .getSessionInfoWithFeedback(this.isHQ)
      .subscribe((sessionInfo: SessionInfo[]) => {
      this.tableDataSource = new MatTableDataSource(sessionInfo);
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.paginator = this.paginator;
      this.tableDataLength = sessionInfo.length;

    });
    if (this.isTeamLead && !this.displayedColumns.includes('servedBy')) {
      this.displayedColumns.push('servedBy');
    }

  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  setConversationUid(conversationUid: string) {
    this.sessionInfoPlayList = this.tableDataSource.data;
    this.conversationUid = conversationUid;
    this.messageService.getMessagesByConversationUid(this.conversationUid, 'feedback').subscribe((messages) => {
      this.reviewMessages = messages;
    });
    this.detailscreen.emit(this.title);
  }

  unsetConversationUid($event) {
    this.conversationUid = undefined;
    this.ngOnInit();
    this.detailscreen.emit('');
  }

  getNextReview($event) {
    if (this.sessionInfoPlayList.length === 1) {
      this.sessionInfoPlayList = [];
      this.unsetConversationUid($event);
    } else {
      const oldConversationUid: string = this.conversationUid;
      const index = this.sessionInfoPlayList.findIndex((s) => s.conversationUid === this.conversationUid);
      if ($event === -1) {
        this.sessionPlayListGoBack(index);
      } else {
        this.sessionPlayListGoNext(index);
      }
      this.sessionInfoPlayList = this.sessionInfoPlayList.filter((s) => s.conversationUid !== oldConversationUid);
    }
  }

  sessionPlayListGoBack(index: number) {
    if (index === 0) {
      this.conversationUid = this.sessionInfoPlayList[this.sessionInfoPlayList.length - 1].conversationUid;
    } else {
      this.conversationUid = this.sessionInfoPlayList[index - 1].conversationUid;
    }
  }

  sessionPlayListGoNext(index: number) {
    if (index === this.sessionInfoPlayList.length - 1) {
      this.conversationUid = this.sessionInfoPlayList[0].conversationUid;
    } else {
      this.conversationUid = this.sessionInfoPlayList[index + 1].conversationUid;
    }
  }

  get isData(): boolean {
    return this.tableDataLength != null && this.tableDataLength > 0;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setLoadedSession($event) {
    this.sessionChatInfo = $event;
    this.reviewService.conversationUid = this.sessionChatInfo.sessionInfo.conversationUid;
  }

  getReview() {
    return this.reviewService.review;
  }

  sendReviewMessage(reviewMessage: ReviewMessage) {
    this.messageService.sendReviewMessage(reviewMessage).subscribe((result) => {
      this.reviewMessages.push(result);
    });
  }
}
