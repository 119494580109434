import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../../core/authorization.service';
import { ClassificationDto } from '../../../../model/support/review/classification-dto';
import { Correction } from '../../../../model/support/review/corrections.model';
import { Review } from '../../../../model/support/review/review.model';
import { SimpleComment } from '../../../../model/support/simple-comment.model';
import {
  GenericInputPromptDialogComponent
} from '../../../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import {
  GenericTextDialogComponent
} from '../../../../shared/components/generic-text-dialog/generic-text-dialog.component';
import { SmallButtonComponent } from '../../../../shared/components/small-button/small-button.component';
import { AlertifyService } from '../../../service/alertify.service';
import { MessageService } from '../../../service/message.service';
import { ReviewService } from '../../../service/review.service';
import { SupportService } from '../../../service/support.service';
import { ClassificationFieldsComponent } from './classification-fields/classification-fields.component';

@Component({
  selector: 'app-feedback-correction',
  templateUrl: './feedback-correction.component.html',
})
export class FeedbackCorrectionComponent implements OnInit, OnDestroy {

  @ViewChild(ClassificationFieldsComponent, {static: false})
  classificationFieldsComponent: ClassificationFieldsComponent;
  @Input()
  review: Review;
  conversationUid: string;
  disabled: boolean;
  departmentList: string[];
  subscriptions: Subscription[] = [];

  correction: Correction;
  comments: SimpleComment[] = [];

  valid = true;

  commentText;
  classifications: ClassificationDto[];
  classificationFieldsLoaded = false;

  departmentSearchControl = new FormControl('');
  departmentPipeForcedAt: Date;
  categorySearchControl = new FormControl('');
  categoryPipeForcedAt: Date;

  departmentFilterFn = (department: string) => {
    return !department || !this.departmentSearchControl.value ||
      department.toLowerCase().indexOf(this.departmentSearchControl.value.toLowerCase()) > -1;
  }

  categoryFilterFn = (classification: ClassificationDto) => {
    return !classification || !this.categorySearchControl.value ||
      classification.classification.toLowerCase().indexOf(this.categorySearchControl.value.toLowerCase()) > -1;
  }

  constructor(private alertify: AlertifyService,
              private supportService: SupportService,
              private formBuilder: FormBuilder,
              private authorizationService: AuthorizationService,
              private messageService: MessageService,
              private reviewService: ReviewService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.conversationUid = this.review.conversationUid;

    this.reviewService.getCorrection(this.review.id).subscribe(correction => {
      this.review.corrections = correction;
      this.correction = correction;

      if (this.correction) {
        this.initialize();
      }

      if (this.authorizationService.isTeamLead || this.authorizationService.isClient) {
        this.commentText = 'Add motivation (required)';
      } else {
        this.commentText = 'Add message';
      }
    });

    this.reviewService.getCorrectionComments(this.review.id).subscribe(comments => {
      this.comments = comments;
    });
  }

  initialize() {
    this.loadOptions();

    if (this.correction.classificationFieldValueDto) {
      this.classificationFieldsLoaded = true;
    }

    this.departmentSearchControl.valueChanges.subscribe(() => this.departmentPipeForcedAt = new Date());
    this.categorySearchControl.valueChanges.subscribe(() => this.categoryPipeForcedAt = new Date());
  }

  createCorrection() {
    this.reviewService.createCorrection(this.review.id).subscribe(correction => {
      this.review.corrections = correction;
      this.correction = correction;
      this.initialize();
    });
  }

  get closed(): boolean {
    return this.review.closedAt != null;
  }

  onClassificationChanged() {
    this.correction.newClassificationCategory =
      this.classifications.find(c => c.classification === this.correction.newClassification)?.classificationCategory;

    this.classificationFieldsLoaded = false;
    this.reviewService.getClassificationFields(this.review.id, this.correction.newClassification).subscribe((res) => {
      this.correction.classificationFieldValueDto = res;
      this.classificationFieldsLoaded = true;
    });
  }

  get showRequiredFields(): boolean {
    return !!this.classifications && this.classificationHasRequiredFields() && this.classificationFieldsLoaded;
  }

  get userCanEdit(): boolean {
    let canEdit = true;
    if (this.isTeamLead || this.isClient) {
      canEdit = (this.correction.processStage !== 'TO_SUPERVISOR');
    } else if (this.isTCOrBetter) {
      canEdit = (this.correction.processStage !== 'RETURNED');
    }
    return canEdit;
  }

  loadOptions() {
    this.supportService.getActiveDepartmentsForAccount(this.review.accountID).subscribe((d) => this.departmentList = d);
    this.reviewService.getClassificationsByReviewId(this.review.id, false).subscribe((c) => {
      this.classifications = c;
    });
  }

  get hasChanges(): boolean {
    return this.correction.newClassification !== null || this.correction.newDepartment !== null &&
      ((this.correction.newClassification !== this.correction.classification) ||
      (this.correction.newDepartment !== this.correction.department));
  }

  addComment(comment: string) {
    this.reviewService.addCommentToCorrection(this.correction.id, this.review.id, comment).subscribe((response) => {
      this.comments.push(response);
    });
  }

  setValidity($event) {
    this.valid = $event.valid;
  }

  saveFieldValues() {
    this.reviewService.saveCorrectionFieldValues(this.correction, this.review.id).subscribe();
  }

  suggestCorrection() {
    if (this.correction.classification != null) {
      if (this.isClassificationLead()) {
        this.classificationFieldsComponent.setClassificationFieldValues();
      }
      this.subscriptions.push(
        this.reviewService.suggestCorrection(this.correction, this.review.id)
          .subscribe((c) => this.correction = c));
    }
  }

  returnCorrectionSuggestion() {
    if (this.isClassificationLead()) {
      this.classificationFieldsComponent.setClassificationFieldValues();
    }
    this.subscriptions.push(this.reviewService.returnCorrectionSuggestion(this.correction, this.review.id)
      .subscribe((c) => this.correction = c));
  }

  approveCorrection() {
    if (!!this.classificationFieldsComponent?.form && !this.valid) {
      this.dialog.open(GenericTextDialogComponent, {
        data: {
          html: '<p>Classification not valid. Please check all fields before approving.</p>'
        }
      });
      return;
    }

    if (!this.correction.newClassification) {
      this.dialog.open(GenericTextDialogComponent, {
        data: {
          html: '<p>Please set a category before approving this correction</p>'
        }
      });
      return;
    }

    // TODO add a departmentRequired boolean to categories, avoid hard coded check on category names
    if (!this.correction.newDepartment && !['Wasted', 'Geen Dialoog'].some(cc => cc === this.correction.newClassificationCategory)) {
      this.dialog.open(GenericTextDialogComponent, {
        data: {
          html: '<p>A department is required before approving this correction</p>'
        }
      });
      return;
    }

    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: 'Please leave a comment before approving this correction',
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed()
      .subscribe((result) => {
        if (result?.message) {

          // wait for comment to be processed
          this.reviewService.addCommentToCorrection(this.correction.id, this.review.id, result.message).subscribe((response) => {
            this.comments.unshift(response);

            this.classificationFieldsComponent?.setClassificationFieldValues();

            this.reviewService.approveCorrection(this.correction, this.review.id)
              .subscribe((c) => {
                this.correction.closedAt = c.closedAt;
                this.correction.processStage = c.processStage;
              });
          });
        }
      });
  }

  classificationHasRequiredFields(): boolean {
    if (this.correction.classificationFieldValueDto) {
      return this.correction.classificationFieldValueDto.some((cf) => {
        return cf.fieldDefinition.required || cf.fieldDefinition.requirementSetting === 'YES';
      });
    } else {
      return false;
    }
  }

  isClassificationLead(): boolean {
    return this.classifications.some((c) =>
      c.classification === this.correction.newClassification && c.classificationCategory === 'Lead');
  }

  requiredFieldValuesPresent(): boolean {
    return !this.correction.classificationFieldValueDto.some((cf) => {
      return ((cf.value === null || cf.value.length === 0 ||
        (cf.fieldDefinition.type === 'CHECKBOX' && !cf.value))
        && cf.fieldDefinition.required);
    });
  }

  closeCorrection() {
    this.subscriptions.push(
      this.reviewService.closeCorrection(this.correction, this.review.id)
        .subscribe((c) => this.correction = c));
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get isTCOrBetter(): boolean {
    return this.authorizationService.isAdmin || this.authorizationService.isLcd || this.authorizationService.isTc;
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
