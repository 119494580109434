import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericFunctionFilter'
})
export class GenericFunctionFilterPipe implements PipeTransform {

  transform<T>(elements: T[], filterOptions: FilterOptions): T[] {
    if (!elements) {
      return null;
    }

    let filtered = elements;
    if (filterOptions.filterFns) {
      filterOptions.filterFns.forEach(fn => {
        filtered = filtered.filter(fn);
      });
    }

    if (filterOptions.sortFn) {
      filtered.sort(filterOptions.sortFn);
    }

    if (filterOptions.resultFn) {
      filterOptions.resultFn(filtered);
    }

    return filtered;
  }

}

export interface FilterOptions {
  pipeForcedAt?: Date;
  sortFn?: (a, b) => number;
  filterFns?: Array<(element: any, value: any) => boolean>;
  resultFn?: (filtered) => void;
}
