import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ReviewComponent } from '../review/review.component';
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ReviewCanDeactivateGuard implements CanDeactivate<ReviewComponent> {

  constructor(public dialog: MatDialog) { }

  canDeactivate(
    component: ReviewComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {

    if (!component.conversationUid || nextState.url === '/login') {
      return true;
    }

    if (component.hasData) {
      return component.openExitDialog();
    }
  }
}
