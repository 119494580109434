import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})
export class SupportComponent {

  constructor(public router: Router) {}

  get isHidden(): boolean {
    return this.router.url !== '/support/chatarchive';
  }

}
