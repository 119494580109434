import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountInformation } from '../model/account-information.model';
import { Role } from '../model/role.model';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizationService {

  constructor(private authenticationService: AuthenticationService,
              private http: HttpClient) {
  }

  hasRole(name: string): Observable<boolean> {
    let result = of(false);

    if (this.authenticationService.authenticated) {
      result = this.http.get('/api/roles').pipe(
        map((roles: Role[]) => {
          const roleValue: number = this.findRoleValueByName(name, roles);
          return roleValue && this.hasRoleByValue(roleValue);
        }),
      );
    }
    return result;
  }

  get isIntermediatePhase(): boolean {
    return false;
  }

  get currentUser(): AccountInformation {
    return this.authenticationService.accountInformation;
  }

  get isOperator(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_OPER');
  }

  get isClient(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_CLIENT');
  }

  get isAdmin(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_ADMIN');
  }

  get isTeamLead(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_TL');
  }

  get isTc(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_TC');
  }
  get isLcd(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_LCD');
  }

  get isSuperUser(): boolean {
    return this.currentUser.roles.some((role) => role.name === 'ROLE_SUPER');
  }

  get isInAdminGroup(): boolean {
    return this.isSuperUser || this.isAdmin || this.isLcd;
  }

  get isOperatorGroup(): boolean {
    return this.isOperator || this.isTeamLead;
  }

  hasLegacyPermission(permission: string, system: 'SS' | 'DB') {
    return this.hasPermission(permission, permission, system);
  }

  hasPermission(permission: string, view: string, system: 'SS' | 'DB') {
    return this.authenticationService.accountInformation.permissionObjects?.some(p => p.permission === permission && p.view === view && p.system === system);
  }

  private hasRoleByValue(value: number): boolean {
    return this.currentUser.roles.some((role) => role.value >= value);
  }

  private findRoleByName(name: string, roles: Role[]): Role {
    return roles.find((role) => role.name === name);
  }

  private findRoleValueByName(name: string, roles: Role[]): number {
    let roleValue: number;
    const foundRole: Role = this.findRoleByName(name, roles);

    if (foundRole) {
      roleValue = foundRole.value;
    }

    return roleValue;
  }
}
