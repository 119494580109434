import { Component } from '@angular/core';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  constructor(private authenticationService: AuthenticationService) {
  }

  get authenticated(): boolean {
    return this.authenticationService.authenticated;
  }

  get canSeeKB(): boolean {
    return true;
  }

  get canSeeCR(): boolean {
    return true;
  }
}
