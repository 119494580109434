import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OpenReview } from '../../model/support/open-review.model';
import { OpenReviewService } from '../service/open-review.service';

@Component({
  selector: 'app-lang-review',
  templateUrl: './lang-review.component.html',
})
export class LangReviewComponent implements OnInit {

  openLanguageReviews: Observable<OpenReview[]>;

  constructor(private openReviewService: OpenReviewService) { }

  ngOnInit() {
    this.openLanguageReviews = this.openReviewService.getOpenLanguageReviews();
  }

}
