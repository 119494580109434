import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject } from 'rxjs';
import { FilterParameters } from '../../model/support/filter-parameters.model';

@Injectable()
export class SupportFilterService {
  public coreParameterReturnedData: boolean = false;
  public singleAccountSelected: boolean;
  filterParameters$: Observable<FilterParameters>;
  private readonly replayBufferSize = 1;
  private filterParametersSource = new ReplaySubject<FilterParameters>(this.replayBufferSize);

  constructor() {
    this.filterParameters$ = this.filterParametersSource.asObservable();
  }

  generate(filterParameters: FilterParameters) {
    this.filterParametersSource.next(filterParameters);
  }
}
