import { SimpleComment } from '../simple-comment.model';
import { ClassificationFieldValue } from './classification-field-value.model';
import { ReviewChange } from './review-change.model';

export class Correction extends ReviewChange {
  id: number;
  classification: string;
  department: string;
  newDepartment: string;
  newClassification: string;
  newClassificationCategory: string;
  processStage: string;
  closedAt: Date;
  classificationFieldValueDto: ClassificationFieldValue[];
}
