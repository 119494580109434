import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data [title]',
  templateUrl: './no-data.component.html',
})

export class NoDataComponent implements OnInit {
  @Input()
  title: string;

  constructor() { }

  ngOnInit() {
  }

}
