import { Component, EventEmitter, Input, Output} from '@angular/core';
import { AuthorizationService } from '../../../core/authorization.service';
import { ClosedReview } from '../../../model/support/review/closed-review.model';
import { Review } from '../../../model/support/review/review.model';
import { FeedbackService } from '../../service/feedback.service';
import { ReviewService } from '../../service/review.service';

@Component({
  selector: 'app-close-review',
  templateUrl: './close-review.component.html',
})
export class CloseReviewComponent {

  @Input()
  review: Review;
  @Output()
  openReviewEvent = new EventEmitter<number>();
  markedAsSeen: boolean;

  constructor(private reviewService: ReviewService,
              private authorizationService: AuthorizationService,
              private feedbackService: FeedbackService) { }

  get closed() {
    return this.review.closedAt != null;
  }

  get closeLabel() {
    return this.closed ? 'Reopen review' : 'Close review';
  }

  closeReview() {
    this.reviewService.closeReview(this.review.id).subscribe((response: ClosedReview) => {
      this.review.closedAt = response.closedAt;
      this.review.closedBy = response.closedBy;
      this.markAsSeen();
    });
  }

  openReview() {
    this.reviewService.reopenReview(this.review.id).subscribe((response: boolean) => {
      if (response) {
        this.review.closedAt = null;
        this.review.closedBy = null;
        this.openReviewEvent.emit(this.review.id);
      }
    });
  }

  get closeable(): boolean {
    return this.feedbackService.userMayCloseReview();
  }

  get userHasCloseRights(): boolean {
    return this.feedbackService.userHasCloseRights();
}
  markAsSeen() {
    this.reviewService.closeReviewActions(this.review.id).subscribe(() => {
      this.markedAsSeen = true;
    });
  }

}
