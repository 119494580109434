import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentConcat',
})
export class PercentConcatPipe implements PipeTransform {

  transform(value: number): string {
    let reply = null;
    if (value !== null && value !== undefined) {
      reply = value.toString().concat('%');
    }
    return reply;
  }

}
