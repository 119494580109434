import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '../../../core/authorization.service';
import { ReviewMessage } from '../../../model/support/review-message.model';
import { Review } from '../../../model/support/review/review.model';
import { AlertifyService } from '../../service/alertify.service';
import { MessageService } from '../../service/message.service';
import { ReviewService } from '../../service/review.service';
import {SessionInfo} from "../../../model/support/session-info.model";

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
})
export class ReviewDetailsComponent implements OnInit {
  @Input()
  conversationUid: string;
  @Input()
  list: boolean;
  @Input()
  visibleToLowestRole: boolean;
  @Output()
  getNextEvent = new EventEmitter<number>();
  @Output()
  unloadSessionDetailsEvent = new EventEmitter<string>();
  @Input()
  review: Review;
  @Input()
  canApply: boolean;
  @Output()
  reviewMessageEvent = new EventEmitter<ReviewMessage>();
  @Input()
  sessionInfo: SessionInfo;

  reviewMessages: ReviewMessage[];

  constructor(private alertify: AlertifyService,
              private authorizationService: AuthorizationService,
              private messageService: MessageService,
              public reviewService: ReviewService) {}

  ngOnInit(): void {
    this.messageService.getMessagesByConversationUid(this.conversationUid, 'Feedback').subscribe((messages) => {
      this.reviewMessages = messages;
    });
  }

  getNextReview($event) {
    this.getNextEvent.emit($event);
  }

  unsetConversationUid(event: string) {
    this.unloadSessionDetailsEvent.emit(event);
  }

  emitReviewMessageEvent(reviewMessage: ReviewMessage) {
    this.reviewMessageEvent.emit(reviewMessage);
  }
}
