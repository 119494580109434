import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { ReviewActionType } from '../../enums/support/review-action-type.enum';
import { FeedBackRow } from '../../model/support/feedback-row.model';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
})
export class TicketsComponent implements OnInit {

  conversationUid: string;
  tickets: Observable<FeedBackRow[]>;
  type: ReviewActionType;

  constructor(private authorizationService: AuthorizationService,
              private activatedRoute: ActivatedRoute,
              private supportService: SupportService) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.tickets = this.supportService.getTicketsByActionType(data.type);
      this.type = data.type;
    });
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  get isLCDOrBetter(): boolean {
    return this.authorizationService.isAdmin || this.authorizationService.isLcd;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

}
