import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { AuthorizationService } from '../../../../core/authorization.service';
import { KeyValuePair } from '../../../../model/key-value-pair';
import { Review } from '../../../../model/support/review/review.model';
import { ReviewService } from '../../../service/review.service';

@Component({
  selector: 'app-feedback-operator-errors',
  templateUrl: './feedback-operator-errors.component.html',
})
export class FeedbackOperatorErrorsComponent implements OnInit {

  @Input()
  review: Review;
  operatorErrors: Array<KeyValuePair<string, boolean>>;
  tableDataSource: MatTableDataSource<KeyValuePair<string, boolean>>;
  toggleSubject = new Subject<KeyValuePair<string, boolean>>();
  displayedColumns: string[] = ['select', 'error'];
  saving = false;

  constructor(private reviewService: ReviewService,
              private authorizationService: AuthorizationService) {}

  get disabled() {
    return this.review.closedAt != null || this.authorizationService.isOperator || this.isReviewForCurrentUser();
  }

  @Input()
  set operatorErrorOptions(operatorErrorOptions: string[]) {
    if (operatorErrorOptions) {
      this.operatorErrors = operatorErrorOptions.map((e) => {
        const keyValuePair: KeyValuePair<string, boolean> = new KeyValuePair();
        keyValuePair.key = e;
        keyValuePair.value = this.review.operatorErrors.errors.includes(e) ;
        return keyValuePair;
      });
      this.tableDataSource = new MatTableDataSource(this.operatorErrors);
    }
}

  isReviewForCurrentUser(): boolean {
    return this.authorizationService.currentUser.name === this.review.servedBy;
  }

  ngOnInit(): void {
    this.toggleSubject.pipe(
      debounceTime(1500),
    ).subscribe((e) => {
      this.saving = true;
      const selectedErrors = this.operatorErrors.filter((o) => o.value).map((o) => o.key);
      this.reviewService.updateOperatorErrors(selectedErrors, this.review.id).subscribe((r) => {
        this.review.operatorErrors.errors = r;
        this.saving = false;
      });
    });
  }

  toggleValue(pair: KeyValuePair<string, boolean>) {
    this.operatorErrors.find((o) => o.key === pair.key).value = !pair.value;
    this.toggleSubject.next(pair);
  }
}
