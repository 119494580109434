import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication.service';
import { AuthorizationService } from '../../../core/authorization.service';
import { ReviewService } from '../../service/review.service';
import { SessionChatInfoService } from '../../service/session-chat-info.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
})
export class SubscriptionComponent {

  @Input()
  disabled: boolean = false;

  constructor(public reviewService: ReviewService,
              private sessionChatInfoService: SessionChatInfoService,
              private authenticationService: AuthenticationService,
              private authorizationService: AuthorizationService) {
  }

  changeSubscriptionState(): void {
    if (this.reviewService.review.currentUserSubscribed) {
      this.reviewService.unsubscribeFromSession(this.reviewService.review.conversationUid);
    } else {
      this.reviewService.subscribeToSession(this.reviewService.review.conversationUid);
    }
    this.reviewService.review.currentUserSubscribed = !this.reviewService.review.currentUserSubscribed;
  }

  get canSubscribe(): boolean {
    return this.sessionChatInfoService.sessionChatInfo.sessionInfo.servedBy.toLowerCase()
      !== this.authenticationService.accountInformation.name.toLowerCase()
      && (this.authorizationService.isInAdminGroup);
  }

  get isSubscribed(): boolean {
    return this.reviewService.review.currentUserSubscribed;
  }

  get subscribeState(): string {
    let subscribeState: string;
    if (this.isSubscribed) {
      subscribeState = 'Unsubscribe';
    } else {
      subscribeState = 'Subscribe';
    }
    return subscribeState;
  }

}
