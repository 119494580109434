import { ClassificationFieldValue } from './classification-field-value.model';

export class ClassificationDto {
  id: number;
  classification: string; // wrong name, should be 'category', requires refactoring
  category: string;
  classificationCategory: string;
  department: string;
  comments: any[];
  sendToHq: boolean;
  languageReview: boolean;
  hqApproved: boolean;
  languageApproved: boolean;
  classificationFields: ClassificationFieldValue[];
  conversationUid: string;
  relatedConversations: any[];
}
