import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input, IterableDiffer, IterableDiffers,
  ViewChild,
} from '@angular/core';
import { AuthorizationService } from '../../../../core/authorization.service';
import { ReviewMessage } from '../../../../model/support/review-message.model';
import { SimplerComment } from '../../../../model/support/simpler-comment.model';
import { SessionChatInfoService } from '../../../service/session-chat-info.service';
import { SupportService } from '../../../service/support.service';

@Component({
  selector: 'app-messages-history',
  templateUrl: './message-history.component.html',
  styleUrls: ['./message-history.component.scss']
})
export class MessageHistoryComponent implements AfterViewChecked {
  @ViewChild('historyMessages', {static: false}) historyMessages: ElementRef;
  @Input()
  conversationUid: string;
  @Input()
  reviewMessages: ReviewMessage[];
  @Input()
  staticMessages: SimplerComment[];
  readonly systemName = 'System';
  iterableDiffer: IterableDiffer<any>;

  sortFn = (a: ReviewMessage, b: ReviewMessage) => {
    return new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime();
  }

  constructor(private supportService: SupportService,
              private sessionChatInfoService: SessionChatInfoService,
              private authorizationService: AuthorizationService,
              private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngAfterViewChecked(): void {
    const changes = this.iterableDiffer.diff(this.reviewMessages);
    if (changes) {
      this.scrollMessagesToBottom();
    }
  }

  getMessageClass(userName: string) {
    let returnClass: string;
    if (this.authorizationService.currentUser.name === userName) {
      returnClass = 'left';
    } else if (userName === this.systemName) {
      returnClass = 'system';
    } else if (userName != null) {
      returnClass = 'right';
    } else {
      returnClass = 'new';
    }
    return returnClass;
  }

  getMessageLines(message: ReviewMessage): string[] {
   const formatedMessage: string = this.getMessage(message);
   return formatedMessage.split('\n');
  }

  getMessage(message: ReviewMessage): string {
    let formattedMessage: string = '';
    if (message.sentBy) {
      formattedMessage = message.sentBy.userName + ': ' + message.message;
    } else {
      formattedMessage = '> ' + message.message;
    }
    return formattedMessage;
  }

  scrollMessagesToBottom() {
    this.historyMessages.nativeElement.scrollTo(0, this.historyMessages.nativeElement.scrollHeight);
  }

  withLineBreaks(message: string): string {
    return message.replace('\n', '<br/>');
  }

  isImg(message: ReviewMessage) {
    if (message.sentBy && message.sentBy.userName === 'System') {
      if (message.message.includes(']', (message.message.length - 1))) {
        return true;
      }
    }
    return false;
  }

  getImgSource(message: ReviewMessage): string {
    const regex = '[0-9]+';
    return this.supportService.getImgSource(+message.message.match(regex)[0]);
  }
}
