import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthorizationService } from '../../core/authorization.service';
import { AlertWithBody } from '../../model/support/alert-with-body.model';
import { AlertifyDto } from '../../model/support/alertify-dto';
import { EscalationMessage } from '../../model/support/escalation-message.model';
import { ReviewMessage } from '../../model/support/review-message.model';
import { EscalationProcess } from '../../model/support/review/escalation-process.model';
import { TopChat } from '../../model/support/review/top-chat.model';
import { SimpleComment } from '../../model/support/simple-comment.model';
import { AlertifyService } from './alertify.service';
import { ReviewService } from './review.service';

@Injectable()
export class MessageService {

  public messageType = {
    ClientComment: 'CLIENTCOMMENT',
    Correction: 'CORRECTION',
    Escalate: 'ESCALATE',
    FAQ: 'FAQ',
    Feedback: 'FEEDBACK',
    Optimization: 'OPTIMIZATION',
    TopChat: 'TOPCHAT',
  };

  constructor(private http: HttpClient,
              private reviewService: ReviewService,
              private alertify: AlertifyService) {

  }

  createReviewMessage(reviewId: number,
                      conversationUid: string,
                      message: string,
                      messageType: 'CLIENTCOMMENT' | 'CORRECTION' | 'ESCALATE' | 'FAQ' | 'FEEDBACK' | 'OPTIMIZATION' | 'TOPCHAT',
                      responseRequested: boolean): ReviewMessage {
    const reviewMessage = new ReviewMessage();
    reviewMessage.id = null;
    reviewMessage.reviewId = reviewId;
    reviewMessage.conversationUid = conversationUid;
    reviewMessage.message = message;
    reviewMessage.messageType = messageType;
    reviewMessage.responseRequested = responseRequested;
    return reviewMessage;
  }

  sendReviewMessage(message: ReviewMessage): Observable<ReviewMessage> {
    return this.http.post<AlertWithBody<ReviewMessage>>(`/api/support/review/sendmessage`, message)
      .pipe(map((result) => {
        this.alertify.success(result.alert.message);
        return result.body;
      }));
  }

  sendOptimizationMessage(message: ReviewMessage): Observable<ReviewMessage> {
    return this.http.post<AlertWithBody<ReviewMessage>>(`/api/support/review/message/optimization/send`, message)
      .pipe(map((result) => {
        this.alertify.success(result.alert.message);
        return result.body;
      }));
  }

  sendMessageNoAction(reviewId: number, messageType: 'CORRECTION' | 'FEEDBACK' | 'OPTIMIZATION' | 'TOPCHAT', message: string ) {
    return this.http.post<AlertWithBody<ReviewMessage>>(`/api/support/review/${reviewId}/message/append`, {message: message, messageType: messageType})
      .pipe(map((result) => {
        this.alertify.success(result.alert.message);
        return result.body;
      }));
  }

  addTopChatRequestComment(reviewId: number, comment: SimpleComment): Observable<TopChat> {
    return this.http.post<AlertWithBody<TopChat>>(`/api/support/review/${reviewId}/topchat/addcomment`, comment)
      .pipe(
        map((result) => {
          this.alertify[result.alert.type](result.alert.message);
          return result.body;
        }),
      );
  }

  sendEscalateReviewMessage(message: ReviewMessage): Observable<EscalationMessage> {
    return this.http.post<AlertWithBody<EscalationMessage>>('/api/support/review/send-escalation-message', message)
      .pipe(tap((result) => this.alertify.success(result.alert.message)),
            map((result) => result.body));
  }

  getMessagesByConversationUid(conversationUid: string, type: string): Observable<ReviewMessage[]> {
    const httpParams = new HttpParams().append('conversationUid', conversationUid.toString()).append('messageType', type);
    return this.http.get<ReviewMessage[]>('/api/support/review/messages', {params: httpParams});
  }

}
