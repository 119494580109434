import { Component, OnInit } from '@angular/core';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { GraphFilterService } from '../graph-filter.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-word-cloud',
  templateUrl: './wordcloud.component.html',
})
export class WordcloudComponent extends CoreParametersConsumer {
  subtitle: string;
  count: boolean = false;

  isOperator = true;

  dataSource;
  errorMessage: string;

  cloudOptions: CloudOptions = {
    width: 1,
    height: 500,
    overflow: false,
    realignOnResize: true,
  };

  tableData: any[];
  data: Array<{data: CloudData[], language: string, totalChats: number}> = [];
  displayedColumns: string[] = ['text', 'weight', 'count'];

  colorPalette: string[] = ['#AA028C', '#990488', '#890683', '#78087F', '#670A7A', '#570C76', '#460E71', '#35106D', '#251268', '#141464'];

  constructor(graphFilterService: GraphFilterService, private graphService: GraphService, public authorizationService: AuthorizationService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters) {
    this.subtitle = this.graphService.getLabel(coreParameters);
    const accountId = this.graphService.getParams(coreParameters).get('accounts');
    if (coreParameters.accounts && coreParameters.accounts.length === 1) {
      this.loadWordCloud(coreParameters);
    }
  }

  loadWordCloud(coreParameters: CoreParameters) {
    this.data = [];
    this.tableData = [];
    this.count = false;

    this.graphService.getWordcloud(coreParameters).subscribe((wcl) => {
      wcl.map((wc) => {
        const data =  wc.wordCounts.map((count) => {
          return {text: count.word, weight: count.weight, count: count.count, color: this.colorPalette[count.weight - 1]};
        });
        this.data.push({data, language: wc.language, totalChats: wc.totalChats});
      });

      this.tableData = this.data;
      this.count = true;
    });
  }
}
