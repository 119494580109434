export enum ReviewActionType {

  TOPCHAT = 'TOPCHAT',
  FEEDBACK = 'FEEDBACK',
  CORRECTION = 'CORRECTION',
  FAQ = 'FAQ',
  REVIEW = 'REVIEW',
  ESCALATE = 'ESCALATE',
  HQREVIEW = 'HQREVIEW',
  CLIENTCOMMENT = 'CLIENTCOMMENT',
  OPTIMIZATION = 'OPTIMIZATION'
}
