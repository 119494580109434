import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaqAdditionQna } from '../../../model/support/review/faq-addition-qna.model';
import { FaqAddition } from '../../../model/support/review/faq-addition.model';
import { FlagService } from '../../../shared/flag.service';
import { ReviewService } from '../../service/review.service';
import { ClientFaqDialogComponent } from './client-faq-dialog/client-faq-dialog.component';
import { SessionDetailsDialogComponent } from './session-details-dialog/session-details-dialog.component';

@Component({
  selector: 'app-feedback-client-faqs',
  templateUrl: './feedback-client-faqs.component.html',
})
export class FeedbackClientFaqsComponent implements OnInit {

  faqAdditions: FaqAddition[];
  tableDataSource: MatTableDataSource<FaqAddition>;
  displayedColumns = ['question', 'conversationUid', 'answer'];
  hasFaqs = false;

  flagService = FlagService;

  constructor(private reviewService: ReviewService,
              private dialog: MatDialog) {
  }

  questionFilterFn = (qna: FaqAdditionQna) => {
    return qna.question && qna.question.length > 0;
  }

  ngOnInit() {
    this.reviewService.getOpenFaqAdditionsForClient().subscribe((f) => {
      this.tableDataSource = new MatTableDataSource<FaqAddition>(f);
      this.faqAdditions = f;
      this.hasFaqs = this.faqAdditions.length > 0;
    });
  }

  answer(faqAddition: FaqAddition) {
    const index = this.faqAdditions.indexOf(faqAddition);
    this.dialog.open(ClientFaqDialogComponent,
      {
        minWidth: '550px',
        data: {
          faqAddition,
        }
      }).afterClosed().subscribe((result) => {
      if (result) {
        let observable: Observable<FaqAddition>;

        switch (result.action) {
          case 'reject' :
            observable = this.reviewService.returnFromClient([result.faqAddition], faqAddition.reviewId, true)
              .pipe(map((f) => f[0]));
            break;
          case 'submit' :
            observable = this.reviewService.returnFromClient([result.faqAddition], faqAddition.reviewId, false)
              .pipe(map((f) => f[0]));
            break;
          case 'concept' :
            observable = this.reviewService.saveSingleFaqAddition(result.faqAddition, faqAddition.reviewId);
            break;
          case 'cancel' :
            observable = this.reviewService.cancelFaqAddition(faqAddition.id, faqAddition.reviewId);
            break;
        }

        if (observable) {
          observable.subscribe((f) => {
            this.faqAdditions[index] = f;
            this.tableDataSource.data = this.faqAdditions;
          });
        }
      }
    });
  }

  showSessionDetails(conversationUid: string) {
    this.dialog.open(SessionDetailsDialogComponent,
      {
        width: '75vw',
        data: {conversationUid}
      });
  }

  clientHasResponded(faqAddition: FaqAddition): boolean {
    return faqAddition.faqAdditionProcessStage === 'ACCEPTED_BY_CLIENT' || faqAddition.faqAdditionProcessStage === 'REJECTED_BY_CLIENT';
  }

  inToClientStage(faqAddition: FaqAddition): boolean {
    return faqAddition.faqAdditionProcessStage === 'TO_CLIENT';
  }
}
