import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionChatInfo } from '../../../../model/support/session-chat-info.model';
import { SessionChatInfoService } from '../../../service/session-chat-info.service';

@Component({
  selector: 'app-session-details-dialog',
  templateUrl: './session-details-dialog.component.html',
})
export class SessionDetailsDialogComponent implements OnInit, OnDestroy {

  conversationUid: string;
  sessionChatInfo: SessionChatInfo;

  componentDestroyed$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<SessionDetailsDialogComponent>,
              public sessionChatInfoService: SessionChatInfoService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.conversationUid = this.data.conversationUid;
    this.sessionChatInfoService.getConversation(this.conversationUid)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(s =>
        this.sessionChatInfo = s);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
