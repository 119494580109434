import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationInterceptor } from './authentication-interceptor';
import { AuthenticationGuard } from './authentication.guard';
import { AuthenticationService } from './authentication.service';
import { AuthorizationService } from './authorization.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LogoutButtonComponent } from './logout-button/logout-button.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LogoutButtonComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LogoutButtonComponent,
  ],
    imports: [
        HttpClientModule,
        SharedModule,
        RouterModule,
    ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    // {
    //   multi: true,
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthenticationInterceptor,
    // },
    AuthorizationService,
  ],
})
export class CoreModule {
}
