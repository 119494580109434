
import { UserShort } from '../../user-short.model';
import { BconnectRating } from './bconnect-rating.model';
import { Correction } from './corrections.model';
import { EscalationProcess } from './escalation-process.model';
import { FaqAddition } from './faq-addition.model';
import { OperatorErrors } from './operator-errors.model';
import { QualityChecks } from './quality-checks.model';
import { ReviewProcessClaim } from './review-process-claim.model';
import { SupervisorSet } from './supervisorset.model';
import { TopChat } from './top-chat.model';

export class Review {
  id: number;
  conversationUid: string;
  servedBy: string;
  accountID: number;
  account: string;
  currentUserSubscribed: boolean;
  createdAt: Date;
  closedAt: Date;
  escalationProcess: EscalationProcess;
  closedBy: string;
  // should be faqAdditions, fix type at some point
  faqAddition: FaqAddition[];
  topChat: TopChat;
  bconnectRating: BconnectRating;
  corrections: Correction;
  operatorErrors: OperatorErrors;
  qualityChecks: QualityChecks;
  supervisorSet: SupervisorSet;
  participants: UserShort[];
  reviewProcessClaims: ReviewProcessClaim[];
  reviewed: boolean;
  feedbackFlowResolvedAt: Date;
  clientCommentFlowResolvedAt: Date;
}
