import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ChatMessage } from '../../model/support/chat-message.model';
import { OpenReviewAssignment } from '../../model/support/open-review-assignment.model';
import { OpenReview } from '../../model/support/open-review.model';
import { ClassificationDto } from '../../model/support/review/classification-dto';
import { SimpleUser } from '../../model/support/simple-user.model';
import {
  GenericInputPromptDialogComponent
} from '../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import {
  GenericSelectDialogComponent
} from '../../shared/components/generic-select-dialog/generic-select-dialog.component';
import { AlertifyService } from '../service/alertify.service';
import { OpenReviewService } from '../service/open-review.service';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-open-review',
  templateUrl: './open-review.component.html',
})
export class OpenReviewComponent implements OnInit, OnDestroy {

  @Input()
  reviewType: 'HQREVIEW' | 'LANGREVIEW' | 'QUEUE_ERROR';

  viewNames = {
    HQREVIEW: 'HQ Review',
    LANGREVIEW: 'Language Review',
    QUEUE_ERROR: 'Queue Errors'
  };

  @Input()
  openReviews: Observable<OpenReview[]>;
  conversationUid: string;
  displayedColumns = ['reviewDate', 'conversationDate', 'uid', 'assignedTo', 'account', 'servedBy', 'category',
    'classificationCategory', 'department', 'visitorRating', 'clientRating'];
  @Input()
  title: string;
  hasData = false;
  tableDataLength: number;
  dataSource: MatTableDataSource<OpenReview>;
  selectedOpenReview: OpenReview;
  messages: Observable<ChatMessage[]>;
  chatClassification: ClassificationDto;
  staticMessages: any[];
  error = false;
  resolved = false;
  classificationValid = true;

  constructor(private openReviewService: OpenReviewService,
              private supportService: SupportService,
              private alertifyService: AlertifyService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.openReviews
      .pipe(
        mergeMap((openReviews: OpenReview[]) => {
          const uids = openReviews.map(or => or.uid);
          return this.openReviewService.getAssignments(uids, this.reviewType)
            .pipe(map(assignments => {
              return {openReviews: openReviews, assignments: assignments};
            }));
      }))
      .subscribe((result: {openReviews: OpenReview[], assignments: OpenReviewAssignment[]}) => {
        if (result.openReviews.length > 0) {
          result.openReviews.forEach(or => or.assignedTo = result.assignments
            .find(ass => ass.conversationUid === or.uid && ass.openReviewType === or.reviewType)?.assignedTo);
          this.tableDataLength = result.openReviews.length;
          this.dataSource = new MatTableDataSource(result.openReviews);
          this.hasData = true;
        }
    });
  }

  updateClassificationValidity(valid: boolean) {
    this.classificationValid = valid;
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  selectUid(openReview: OpenReview) {
    this.selectedOpenReview = openReview;
    this.conversationUid = openReview.uid;
    this.resolved = false;
    this.error = false;
    this.classificationValid = true;
    this.messages = this.openReviewService.getMessages(openReview.uid);
    this.openReviewService.getChatClassification(openReview.uid).subscribe((c) => {
      this.resolved = this.reviewType === 'HQREVIEW' ? c.hqApproved : c.languageApproved;
      this.chatClassification = c;
      this.staticMessages = c.comments
        .filter((com) => com.type === 'HQ') // always show HQ messages if present
        .map((com) => {
          return {message: com.text, sentBy: openReview.servedBy};
        });
    }, () => {
      this.alertifyService.error('Error while loading chat classification.');
      this.unsetConversationUid();
    });
  }

  updateMessageFn = (messages: ChatMessage[]): Observable<ChatMessage[]> => {
    return this.openReviewService.updateMessages(messages).pipe(
      tap(() => this.alertifyService.success('Updated messages'),
        () => this.alertifyService.error('Failed to update messages')),
    );
  }

  filterFromDataSource(conversationUid: string) {
    this.dataSource.data = this.dataSource.data.filter((review) => review.uid !== conversationUid);
    this.tableDataLength = this.dataSource.data.length;
    this.hasData = this.tableDataLength > 0;
  }

  resolve() {
    this.error = false;
    if (this.classificationValid) {

      this.dialog.open(GenericInputPromptDialogComponent, {
        hasBackdrop: false,
        data: {
          text: 'Please leave a comment before resolving this review',
          inputPlaceholder: 'Leave a comment',
          optional: this.reviewType === 'LANGREVIEW',
          overlayOnElement: document.getElementById('open-review-card')
        }
      }).afterClosed().subscribe((result) => {

        if (result?.message || result.noComment) {
          this.setChatClassificationApproved();
          this.openReviewService.resolveChatClassification(result.message, this.chatClassification, this.selectedOpenReview.reviewType)
            .subscribe((response) => {
              this.filterFromDataSource(response.conversationUid);
              this.alertifyService.success('Resolved review.');
              this.resolved = true;
            });
        }
      });
    } else {
      this.error = true;
      this.alertifyService.warning('Classification is missing required fields.');
    }
  }

  assign() {
    const bound = document.getElementById('assign-button').getBoundingClientRect();
    this.dialog.open(GenericSelectDialogComponent, {
      minWidth: '450px',
      position: {top: `${bound.y + 10}.px`, left: `${Math.trunc(bound.x - 450 - 10)}px`},
      data: {
        text: 'Assign this review to another user.',
        options: this.supportService.getSupportAgents(this.selectedOpenReview.account, this.reviewType, this.viewNames[this.reviewType]),
        placeholder: 'Select a user',
        labelKey: 'screenName'
      }
    }).afterClosed().subscribe((result: SimpleUser) => {
      if (result) {
        this.openReviewService.assignTo(this.conversationUid, this.reviewType, result.id).subscribe((assignment) => {
          this.dataSource.data.find(r => r.uid === assignment.conversationUid).assignedTo = assignment.assignedTo;
          this.alertifyService.success('Assigned to ' + result.screenName);
        });
      }
    });
  }

  setChatClassificationApproved() {
    switch (this.reviewType) {
      case 'HQREVIEW':
        this.chatClassification.hqApproved = true;
        break;
      case 'LANGREVIEW':
        this.chatClassification.languageApproved = true;
        break;
    }
  }

  unsetConversationUid() {
    this.conversationUid = undefined;
    this.chatClassification = undefined;
  }

  selectNext() {
    const currentIndex = this.dataSource.data.findIndex((e) => e.uid === this.selectedOpenReview.uid);
    if (currentIndex === this.dataSource.data.length - 1) {
      this.selectUid(this.dataSource.data[0]);
    } else {
      this.selectUid(this.dataSource.data[currentIndex + 1]);
    }
  }

  selectPrevious() {
    const currentIndex = this.dataSource.data.findIndex((e) => e.uid === this.selectedOpenReview.uid);
    if (currentIndex > 0) {
      this.selectUid(this.dataSource.data[currentIndex - 1]);
    } else if (currentIndex === 0) {
      this.selectUid(this.dataSource.data[this.dataSource.data.length - 1]);
    }
  }

  canNavigate(): boolean {
    // multiple to navigate or the remaining element is not the currently selected element
    return this.dataSource.data.length > 1 ||
      (this.dataSource.data.length === 1 && this.dataSource.data[0].uid !== this.selectedOpenReview.uid);
  }

  hasPendingReviewOfType(openReview: OpenReview, type: string): boolean {
    return openReview.allPendingReviews.some((r) => r === type);
  }

  waitForLanguageReview(openReview: OpenReview) {
    return openReview.reviewType === 'HQREVIEW' && this.hasPendingReviewOfType(openReview, 'LANGUAGE');
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }
}
