import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatMessage } from '../../../model/support/chat-message.model';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessagesComponent {

  @Input()
  messages: ChatMessage[];
  @Input()
  fontSize: number;
  @Input()
  edit = false;
  @Output()
  onChange = new EventEmitter();
  @Input()
  servedBy: string;
  @Input()
  maxHeight: string;

  getMessageSender(message: ChatMessage): string {
    let sender = '';
    if (message.sender === 'visitor') {
      sender = 'Visitor';
    } else if (message.sender === 'agent') {
      sender = this.servedBy;
    }
    return sender;
  }

}
