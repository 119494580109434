import { Injectable } from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';
import { SessionChatInfoService } from './session-chat-info.service';

@Injectable()
export class FeedbackService {

  constructor(private authorizationService: AuthorizationService,
              private sessionChatInfoService: SessionChatInfoService) { }

  userHasCloseRights(): boolean {
    return !this.authorizationService.isOperator && !this.authorizationService.isClient;
  }

  userMayCloseReview(): boolean {
    let mayClose = false;

    if (this.sessionChatInfoService.sessionChatInfo !== undefined) {
      const currentUserName = this.authorizationService.currentUser.name;
      const servedBy = this.sessionChatInfoService.sessionChatInfo.sessionInfo.servedBy;
      if (this.authorizationService.isAdmin || this.authorizationService.isSuperUser
        || this.userHasCloseRights() && currentUserName !== servedBy) {
        mayClose = true;
      }
    }
    return mayClose;
  }

}
