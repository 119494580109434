import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
})
export class ScoreComponent {
  @Input() title: string;
  @Input() score: number;
  @Input() cardClass: string;
  @Input() colorClass: string;
  @Input() noOfCards: number;

  getFontSize() {
    if (this.score > 999 || this.noOfCards >= 3) {
      return '4vw';
    } else {
      return '5vw';
    }
  }
}
