import { Component, OnInit } from '@angular/core';
import { GraphFilterService } from './graph-filter.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
})
export class GraphComponent implements OnInit {
  buttonsDisabled: boolean = true;

  constructor(private graphFilterService: GraphFilterService) {
  }

  ngOnInit(): void {
    this.graphFilterService.coreParameters$.subscribe(
      () => {
        this.buttonsDisabled = false;
      },
    );
  }
}
