import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatMessage } from '../../model/support/chat-message.model';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
})
export class TranscriptComponent implements OnInit {

  @ViewChild('chatMessages', {static: false}) chatMessages: ElementRef;
  _messages: ChatMessage[];
  raw: ChatMessage[];
  fontSize = 14;
  @Input()
  maxHeight: string = '37vh';
  @Input()
  servedBy: string;
  @Input()
  editable = false;
  edit = false;
  pristine = true;
  @Input()
  messageUpdateFn: (messages: ChatMessage[]) => Observable<ChatMessage[]>;

  constructor() {}

  ngOnInit() {
  }

  @Input()
  set messages(messages: ChatMessage[]) {

    const sortedMessages = messages?.sort((a, b) =>
      new Date(a.date).getTime() - new Date(b.date).getTime());

    this._messages = sortedMessages;
  }

  copyRaw(messages: ChatMessage[]) {
    this.raw = !messages ? null : JSON.parse(JSON.stringify(messages));
  }

  changeFontSize(change: number) {
    const newSize = change + this.fontSize;
    if (newSize < 10) {
      this.fontSize = 10;
    } else if (newSize > 36) {
      this.fontSize = 36;
    } else {
      this.fontSize = newSize;
    }
  }

  toggleEdit() {
    this.edit = !this.edit;
  }

  reset() {
    this._messages = JSON.parse(JSON.stringify(this.raw));
    this.pristine = true;
  }

  saveMessages() {
    this.messageUpdateFn(this._messages).subscribe((response) => {
      this._messages = response;
      this.copyRaw(response);
      this.pristine = true;
      this.edit = false;
    });
  }
}
