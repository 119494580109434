import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-client-remark-view',
  templateUrl: './client-remark-view.component.html',
})
export class ClientRemarkViewComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) { }
  ngOnInit() {
  }
}
