import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { ReviewActionType } from '../../../enums/support/review-action-type.enum';
import { AlertifyDto } from '../../../model/support/alertify-dto';
import { SessionChatInfo } from '../../../model/support/session-chat-info.model';
import { SessionWithActionType } from '../../../model/support/session-with-update-type.model';
import { UpdateRow } from '../../../model/support/update-row.model';
import { AlertifyService } from '../../service/alertify.service';
import { ReviewService } from '../../service/review.service';
import { SupportService } from '../../service/support.service';
import { AcceptedCorrectionsDialogComponent } from '../accepted-corrections-dialog/accepted-corrections-dialog.component';
import { AcceptedFaqsDialogComponent } from '../accepted-faqs-dialog/accepted-faqs-dialog.component';

@Component({
  selector: 'app-standard-updates-table',
  templateUrl: './standard-updates-table.component.html',
})
export class StandardUpdatesTableComponent implements OnInit, OnDestroy {
  sessionChatInfo: SessionChatInfo;
  tableDataLength: number;
  displayedColumns: string[] = ['startDate', 'conversationUid', 'account', 'involvedUsers', 'servedBy', 'actionType',
    'reviewProcessState', 'remove'];
  tableDataSource: MatTableDataSource<UpdateRow>;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  subscriptions: Subscription[] = [];
  conversationUid: string;
  @Input()
  title: string;
  @Input()
  getHistory: boolean = false;
  tabsWithUpdate: string[] = [];
  openOnTab: ReviewActionType;

  constructor(private supportService: SupportService,
              private authorizationService: AuthorizationService,
              private alertifyService: AlertifyService,
              private reviewService: ReviewService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.generateData();
  }

  generateData() {
    this.subscriptions.push(this.supportService.getUpdates().subscribe((u: UpdateRow[]) => {
      this.tableDataSource = new MatTableDataSource(u);
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
        return data[sortHeaderId];
      };
      this.tableDataSource.paginator = this.paginator;
      this.tableDataLength = u.length;
    }));
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  showUpdate(updateRow: UpdateRow) {
    if (updateRow.actionType === 'FAQ') {
      this.showAddedFaqsDialog(updateRow);
    } else if (updateRow.actionType === 'CORRECTION' && this.isClient) {
      this.showAddedCorrectionsDialog(updateRow);
    } else {
      this.setConversationUid(updateRow);
    }
  }

  showAddedFaqsDialog(updateRow: UpdateRow) {
    const faqs = this.reviewService.getSimpleFaqAdditionsByReviewId(updateRow.reviewId);
    this.dialog.open(AcceptedFaqsDialogComponent,
      {data: {faqs, conversationUid: updateRow.conversationUid}},
      ).afterClosed().subscribe((result) => {
        if (result) {
          this.markAsSeenBySessionAndType(updateRow.conversationUid, 'FAQ');
        }
    });
  }

  showAddedCorrectionsDialog(updateRow: UpdateRow) {
    const correctionComments = this.reviewService.getCommentsFromAcceptedCorrections(updateRow.reviewId);
    this.dialog.open(AcceptedCorrectionsDialogComponent,
      {data: {correctionComments, conversationUid: updateRow.conversationUid}},
    ).afterClosed().subscribe((result) => {
      if (result) {
        this.markAsSeenBySessionAndType(updateRow.conversationUid, 'CORRECTION');
      }
    });
  }

  setConversationUid(updateRow: UpdateRow) {
    this.conversationUid = updateRow.conversationUid;
    this.openOnTab = ReviewActionType[updateRow.actionType];
    this.tabsWithUpdate = this.tableDataSource.data
      .filter((u) => u.conversationUid === this.conversationUid)
      .map((u) => u.actionType);
  }

  unsetConversationUid() {
    this.conversationUid = undefined;
    this.reviewService.conversationUid = undefined;
    this.tabsWithUpdate = [];
  }

  getNextReview($event) {
    const data = this.tableDataSource.data;
    const index = data.findIndex((s) => s.conversationUid === this.conversationUid);
    if (index === 0 && $event === -1) {
      this.conversationUid = data[data.length - 1].conversationUid;
    } else if (index === (data.length - 1) && $event === 1) {
      this.conversationUid = data[0].conversationUid;
    } else {
      this.conversationUid = this.tableDataSource.data[index + $event].conversationUid;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  markAsSeenBySessionAndType(conversationUid: string, actionType: string) {
    this.markAsSeen({conversationUid, actionType});
  }

  markAsSeen(event: SessionWithActionType) {
    this.supportService.markUpdateSeen(event.actionType, event.conversationUid)
      .subscribe((alert: AlertifyDto) => {
        if (alert.type === 'success') {
          const indexOfUpdate = this.tableDataSource.data.findIndex((u) =>
            u.conversationUid === event.conversationUid && u.actionType === event.actionType);
          this.tableDataSource.data.splice(indexOfUpdate, 1);
          if (this.table) {
            this.table.renderRows();
          }
          this.tabsWithUpdate = this.tabsWithUpdate.filter((t) => t !== event.actionType);
        }
        this.alertifyService[alert.type](alert.message);
      });
  }

  get hasData(): boolean {
    return this.tableDataLength != null && this.tableDataLength > 0;
  }

  setLoadedSession($event) {
    this.sessionChatInfo = $event;
    this.reviewService.conversationUid = this.sessionChatInfo.sessionInfo.conversationUid;
  }
}
