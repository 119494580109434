import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {

  @Input()
  disabled: boolean;
  @Output()
  onConfirm = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  delete() {
    this.onConfirm.emit();
  }

}
