import { Component, OnInit } from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";


export interface Trigger {

  name: string;
  triggerStats: TriggerStats;
  pathTriggerStats: PathTriggerStats[];

}

export interface TriggerStats {

  visits: number;
  chats: number;
  target: string;
  percentageChats: number;
  visitorRating: number;
  leads: number;
  service: number;
  wasted: number;
  geenDialoog: number;
  crxInvite: number;
  avgTimeSec: number;

}

export interface PathTriggerStats {

  path: string;
  triggerStats: TriggerStats;
}

@Component({
  selector: 'app-trigger-analytics',
  templateUrl: './trigger-analytics.component.html',
  styles: []
})
export class TriggerAnalyticsComponent implements OnInit {

  totals = [
    {
      "visits": 7423,
      "chats": 164,
      "target": "on-target",
      "percentageChats": 11.8,
      "visitorRating": 9.2,
      "leads": 32,
      "service": 58,
      "wasted": 30,
      "geenDialoog": 4,
      "crxInvite": 18,
      "avgTimeSec": 29
    }
  ]

  filteredTriggers = [];


  triggers: Trigger[] =
    [
      {
        "name": "trigger-1 NL A-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 3465,
              "chats": 177,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 2177,
              "chats": 192,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-2 NL B-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 78,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29,
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 91,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 302,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/g-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 222,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/h-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 199,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-3 NL C-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 198,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 155,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 165,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-4 NL D-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 254,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 133,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 112,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-5 NL A-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 103,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 177,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-6 NL B-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29,
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/g-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/h-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-7 NL C-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-8 NL D-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-9 NL A-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-10 NL B-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29,
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/g-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/h-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-11 NL C-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-12 NL D-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-13 NL A-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-14 NL B-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29,
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/g-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/h-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-15 NL C-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-16 NL D-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-17 NL A-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-18 NL B-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29,
        },
        "pathTriggerStats": [
          {
            "path": "http://panther.be/nl/panther-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://panther.be/nl/panther-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://panther.be/nl/panther-range/g-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://panther.be/nl/panther-range/h-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-19 NL C-PACE model-x pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      },
      {
        "name": "trigger-20 NL D-PACE model pagina 1st invite",
        "triggerStats": {
          "visits": 7423,
          "chats": 164,
          "target": "on-target",
          "percentageChats": 11.8,
          "visitorRating": 9.2,
          "leads": 32,
          "service": 58,
          "wasted": 30,
          "geenDialoog": 4,
          "crxInvite": 18,
          "avgTimeSec": 29
        },
        "pathTriggerStats": [
          {
            "path": "http://jaguar.be/nl/jaguar-range/e-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          },
          {
            "path": "http://jaguar.be/nl/jaguar-range/f-pace/index.html",
            "triggerStats": {
              "visits": 7423,
              "chats": 164,
              "target": "on-target",
              "percentageChats": 11.8,
              "visitorRating": 9.2,
              "leads": 32,
              "service": 58,
              "wasted": 30,
              "geenDialoog": 4,
              "crxInvite": 18,
              "avgTimeSec": 29
            }
          }
        ]
      }

    ]

  // triggers = [
  //   {"name": 'trigger-1 NL E-PACE model pagina 1st invite',
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //   "visits": 7423,
  //   "chats": 164,
  //     "target": "on-target",
  //   "percentageChats": 11.8,
  //   "visitorRating": 9.2,
  //   "leads": 32,
  //   "service": 58,
  //   "wasted": 30,
  //   "geenDialoog": 4,
  //   "crxInvite": 18,
  //   "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-2 NL E-PACE model pagina 1st invite',
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "visits": 9923,
  //     "chats": 194,
  //     "target": "off-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 20,
  //     "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-3 NL E-PACE model pagina 1st invite',
  //     "visits": 8123,
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "chats": 124,
  //     "target": "on-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 15,
  //     "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-4 NL E-PACE model pagina 1st invite',
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "visits": 9923,
  //     "chats": 194,
  //     "target": "off-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 20,
  //     "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-5 NL E-PACE model pagina 1st invite',
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "visits": 7423,
  //     "chats": 164,
  //     "target": "on-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 18,
  //     "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-6 NL E-PACE model pagina 1st invite',
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "visits": 9923,
  //     "chats": 194,
  //     "target": "off-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 20,
  //     "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-7 NL E-PACE model pagina 1st invite',
  //     "visits": 8123,
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "chats": 124,
  //     "target": "on-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 15,
  //     "avgTimeSec": 29
  //   },
  //   {"name": 'trigger-8 NL E-PACE model pagina 1st invite',
  //     "pathName": 'http://jaguar.be/nl/jaguar-range/e-pace/index.html',
  //     "visits": 9923,
  //     "chats": 194,
  //     "target": "off-target",
  //     "percentageChats": 11.8,
  //     "visitorRating": 9.2,
  //     "leads": 32,
  //     "service": 58,
  //     "wasted": 30,
  //     "geenDialoog": 4,
  //     "crxInvite": 20,
  //     "avgTimeSec": 29
  //   }
  // ];

  filterControl = new FormControl();

  pageSize = 5;
  pageSizeOptions = [5, 10, 15]
  public pageSlice = this.triggers.slice(0, 5);

  toggleSetting: 'percentage' | 'number' = 'percentage';

  panelOpenState = false;

  constructor() { }

  ngOnInit() {
    this.filteredTriggers = JSON.parse(JSON.stringify(this.triggers));

    this.filterControl.valueChanges.pipe(debounceTime(800)).subscribe((value) => this.filter(value))
  }

  expandAll() {
    this.panelOpenState = !this.panelOpenState;
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.filteredTriggers.length) {
      endIndex = this.filteredTriggers.length;
    }
    this.pageSlice = this.filteredTriggers.slice(startIndex, endIndex);
  }

  filter(searchTerm: string) {
    const lowerCased = searchTerm.toLowerCase();
    this.filteredTriggers = this.triggers.filter((t) => t.name.toLowerCase().indexOf(lowerCased) >= 0 ||
      t.pathTriggerStats.some((p) => p.path.toLowerCase().indexOf(lowerCased) >= 0 ));
    this.pageSlice = this.filteredTriggers.slice(0, this.pageSize);
  }

  calculateValueTrigger(trigger: Trigger, key: string) {
    if (this.toggleSetting == 'number') {
      return trigger.triggerStats[key];
    } else {
      return Math.floor(trigger.triggerStats[key] / trigger.triggerStats.chats * 100) + '%';
    }
  }

  calculateValuePath(triggerPath: PathTriggerStats, key: string) {
    if (this.toggleSetting == 'number') {
      return triggerPath.triggerStats[key];
    } else {
      return Math.floor(triggerPath.triggerStats[key] / triggerPath.triggerStats.chats * 100) + '%';
    }
  }

}
