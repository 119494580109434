import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';;
import { CoreParameters } from '../../model/core-parameters.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
})
export class FunnelComponent extends CoreParametersConsumer implements OnDestroy {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  readonly displayedColumns: string[] = [
    'label',
    'total',
    'percentageOfVisits',
    'percentageOfInvites',
    'percentageOfChats',
  ];

  basicTrendsColumns: string[] = [
    'label',
    'visitorRating',
    'clientRating',
    'visits',
    'chatsTotal',
    'reach',
    'chatsVisitsPerc',
  ];
  displayedTrendsColumns: string[];
  dynamicColumns: string[] = [];
  subtitle: string;
  numberView: string = 'percentage';
  chatView: string = 'percentage';
  graphType: string = 'classifications';
  graphName: string = 'Categories';
  dataLineChart;
  optionsLineChart = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 0,
        },
      }],
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };
  trendsData: FunnelTrendsRow[];
  dataSubscription: Subscription;
  dataSource: MatTableDataSource<FunnelDataRow>;
  classificationInviteDataSource: MatTableDataSource<FunnelDataRow>;
  classificationNoInviteDataSource: MatTableDataSource<FunnelDataRow>;
  trendsDataSource: MatTableDataSource<FunnelTrendsRow>;
  errorMessage: string;

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters): void {
    this.subtitle = this.graphService.getLabel(coreParameters);
    this.dataSubscription = this.graphService.getFunnelData(
      coreParameters)
      .subscribe((ds: FunnelDataWrapper) => {
        if (this.hasInformation(ds.defaultRows)) {
          this.dataSource = new MatTableDataSource(this.filterRedundantData(ds.defaultRows));
          this.classificationInviteDataSource = new MatTableDataSource(ds.classificationInviteRows);
          this.classificationNoInviteDataSource = new MatTableDataSource(ds.classificationNoInviteRows);
          this.trendsDataSource = new MatTableDataSource(this.setTrends(JSON.parse(JSON.stringify(ds.trendRows))));
          this.trendsDataSource.paginator = this.paginator;
          this.trendsData = ds.trendRows;
          this.setGraph();
        }
      });
  }

  chatsByClassificationLineChart(trendsData: FunnelTrendsRow[]) {
    const objArray = [];
    const classifications = trendsData.length > 0 ? trendsData[0].chatsByClassification.labels : [];
    for (const c of classifications) {
      const ind = classifications.indexOf(c);
      const dataCollect = [];
      trendsData.forEach((t) => {
        let value = t.chatsByClassification.data[ind];
        if (this.chatView === 'percentage') {
          value = (value / t.chatsTotal * 100);
        }
        dataCollect.push(value);
      });
      const obj = {
        borderColor: this.colorService.getColor(ind),
        data: dataCollect,
        fill: false,
        label: c,
      };
      objArray.push(obj);
    }
    this.dataLineChart = {};
    this.dataLineChart.labels = trendsData.map((t) => t.label);
    this.dataLineChart.datasets = objArray;
    this.dataLineChart.options = this.optionsLineChart;
  }

  filterRedundantData(funnelData: FunnelDataRow[]): FunnelDataRow[] {
    funnelData.forEach((data) => {
      switch (data.label) {
        case 'Visits': {
          data.percentageOfVisits = null;
          data.percentageOfInvites = null;
          data.percentageOfChats = null;
          break;
        }
        case 'Unique visitors': {
          data.percentageOfVisits = null;
          data.percentageOfInvites = null;
          data.percentageOfChats = null;
          break;
        }
        case 'Invites': {
          data.percentageOfInvites = null;
          data.percentageOfChats = null;
          break;
        }
        case 'Chats': {
          data.percentageOfChats = null;
          break;
        }
      }
    });
    return funnelData;
  }

  setTrends(trendsData: FunnelTrendsRow[]): FunnelTrendsRow[] {
    if (trendsData.length > 0) {
      this.dynamicColumns = this.getDynamicColumns(trendsData);
    }
    this.displayedTrendsColumns = this.basicTrendsColumns.concat(this.dynamicColumns);
    return trendsData;
  }

  setGraph() {
    if (this.graphType === 'classifications') {
      this.chatsByClassificationLineChart(this.trendsData);
    } else {
      let i;
      const objArray = [];
      const dataCollect = [];
      this.dataLineChart = {};
      this.dataLineChart.options = this.optionsLineChart;
      this.dataLineChart.labels = this.trendsData.map((t) => t.label);
      if (this.graphType === 'chats') {
        i = 0;
        this.trendsData.forEach((t) => {
          dataCollect.push(t.chatsTotal);
        });
      }
      if (this.graphType === 'CR-Chat') {
        i = 0;
        this.trendsData.forEach((t) => {
          dataCollect.push((t.chatsTotal / t.visits) * 100);
        });
      }
      if (this.graphType === 'visitorRating') {
        i = 1;
        this.trendsData.forEach((t) => {
          dataCollect.push(t.visitorRating);
        });
      }
      if (this.graphType === 'visits') {
        i = 2;
        this.trendsData.forEach((t) => {
          dataCollect.push(t.visits);
        });
      }
      const obj = {
        borderColor: this.colorService.getColor(i),
        data: dataCollect,
        fill: false,
        label: this.graphType,
      };
      objArray.push(obj);
      this.dataLineChart.datasets = objArray;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  public setNumberType(type: string) {
    this.numberView = type;
  }

  public setChartType(type: string) {
    this.chatView = type;
    this.chatsByClassificationLineChart(this.trendsData);
  }

  public setGraphType(type: string, name: string) {
    this.graphType = type;
    this.graphName = name;
    this.setGraph();
  }

  public checkGraphType(type: string) {
    return (this.graphType === type);
  }

  public getDate(i: number, element: any): string {
    let date: string = 'n/a';
    if (element.chatsByClassification.data[i] !== 0) {
      switch (this.numberView) {
        case 'percentage' : {
          date = (element.chatsByClassification.data[i] / element.chatsTotal * 100).toFixed(2) + '%';
          break;
        }
        case 'number' : {
          date = '' + element.chatsByClassification.data[i];
          break;
        }
      }
    }
    return date;
  }

  private hasInformation(rows: FunnelDataRow[]): boolean {
    let errorMessage: string;
    if (!rows.some((x) => x.total > 0)) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
    return this.errorMessage == null;
  }

  private getDynamicColumns(funnelTrendsRows: FunnelTrendsRow[]): string[] {
    return funnelTrendsRows[0].chatsByClassification.labels;
  }

  private checkRequiredParameters(rows: string[]) {
    let errorMessage: string;
    if (rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
  }

}

export interface FunnelDataRow {
  label: string;
  total: number;
  percentageOfVisits: number;
  percentageOfVisitors: number;
  percentageOfInvites: number;
  percentageOfChats: number;
}

export interface LabeledDataDto {
  labels: string[];
  data: number[];
}

export interface FunnelTrendsRow {
  label: string;
  chatsByClassification: LabeledDataDto;
  reach: number;
  chatsTotal: number;
  visits: number;
  visitorRating: number;
  clientRating: number;
}

export interface FunnelDataWrapper {
  defaultRows: FunnelDataRow[];
  classificationInviteRows: FunnelDataRow[];
  classificationNoInviteRows: FunnelDataRow[];
  trendRows: FunnelTrendsRow[];
}
