import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OpenReview } from '../../model/support/open-review.model';
import { OpenReviewService } from '../service/open-review.service';

@Component({
  selector: 'app-queue-error',
  templateUrl: './queue-error.component.html',
})
export class QueueErrorComponent implements OnInit {

  queueErrors: Observable<OpenReview[]>;

  constructor(private openReviewService: OpenReviewService) { }

  ngOnInit() {
    this.queueErrors = this.openReviewService.getQueueErrors();
  }

}
