import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { concat, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { FaqAddition } from '../../../../../model/support/review/faq-addition.model';
import { Review } from '../../../../../model/support/review/review.model';
import { UserShort } from '../../../../../model/user-short.model';
import {
  GenericInputPromptDialogComponent
} from '../../../../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import {
  GenericTextDialogComponent
} from '../../../../../shared/components/generic-text-dialog/generic-text-dialog.component';
import { AlertifyService } from '../../../../service/alertify.service';
import { ReviewService } from '../../../../service/review.service';
import { SupportService } from '../../../../service/support.service';

@Component({
  selector: 'app-process-faq',
  templateUrl: './process-faq.component.html',
  styleUrls: ['./process-faq.component.scss']
})
export class ProcessFaqComponent implements OnInit, OnDestroy {

  @Input()
  faqAddition: FaqAddition;
  @Input()
  review: Review;
  @Input()
  disabled: boolean;
  clientReceiver: UserShort;
  @Output()
  savedEvent = new EventEmitter<FaqAddition>();
  @Output()
  deletedEvent = new EventEmitter<void>();
  @Output()
  addCommentEvent = new EventEmitter<string>();
  receiverList: UserShort[] = [];

  componentDestroyed$ = new Subject<void>();

  constructor(private authorizationService: AuthorizationService,
              private reviewService: ReviewService,
              private supportService: SupportService,
              private alertifyService: AlertifyService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.isLCDOrBetter) {
      this.supportService
        .getClientsForAccount(this.review.accountID)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((u: UserShort[]) => {
          this.receiverList = u;
        });
    }
  }

  get reviewClosed(): boolean {
    return this.review.closedAt != null;
  }

  private get isLcd(): boolean {
    return this.authorizationService.isLcd;
  }

  public get isLCDOrBetter(): boolean {
    return this.authorizationService.isInAdminGroup || this.authorizationService.isLcd;
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get stage(): string {
    return this.faqAddition.faqAdditionProcessStage;
  }

  get maySelectClient(): boolean {
    return ['CREATED', 'TO_SUPERVISOR_SUPPORT', 'ACCEPTED_BY_CLIENT', 'REJECTED_BY_CLIENT', 'CANCELED'].some(s => this.stage === s);
  }

  resetReceiver() {
    this.clientReceiver = undefined;
  }

  save() {
    this.reviewService.saveSingleFaqAddition(this.faqAddition, this.review.id).subscribe((response: FaqAddition) => {
      this.savedEvent.emit(response);
    });
  }

  sendToSupervisor() {
    if (!this.faqAddition.qnas.some(qna => qna.question?.length > 0)) {
      this.dialog.open(GenericTextDialogComponent, {
        maxWidth: '400px',
        data: {
          html: `<p>Add at least one question before submitting this FAQ.</p>`,
        }
      });
      return;
    }

    this.reviewService.sendFaqToSupervisor([this.faqAddition], this.review.id).subscribe((response: FaqAddition[]) => {
      this.savedEvent.emit(response[0]);
    });
  }

  sendToClient() {
    if (!this.faqAddition.product) {
      this.dialog.open(GenericTextDialogComponent, {
        maxWidth: '400px',
        data: {
          html: `<p>Select a product before sending this FAQ to a client.</p>`,
        }
      });
      return;
    }

    if (!this.faqAddition.qnas.some(qna => qna.question?.trim().length > 0)) {
      this.dialog.open(GenericTextDialogComponent, {
        maxWidth: '400px',
        data: {
          html: `<p>Add at least one question before sending this FAQ to a client.</p>`,
        }
      });
      return;
    }

    this.reviewService.sendFaqsToClient([this.faqAddition], this.review.id, this.clientReceiver.userId)
      .subscribe((response: FaqAddition[]) => {
        this.savedEvent.emit(response[0]);
      });
  }

  sendFromClient() {
    this.reviewService.returnFromClient([this.faqAddition], this.review.id).subscribe((response: FaqAddition[]) => {
      this.savedEvent.emit(response[0]);
    });
  }

  mayAddToKb(): boolean {
    // any stage by accepted and to_client, must have product and at least 1 qna
    return (!['ACCEPTED', 'TO_CLIENT', 'REJECTED_BY_CLIENT'].some(stage => this.faqAddition.faqAdditionProcessStage === stage)) &&
      this.faqAddition.product &&
      this.faqAddition.qnas.some(qna => qna.question?.trim().length > 0 && qna.answer?.trim().length > 0);
  }

  addToKb() {

    const addFaq$ = concat(
      this.reviewService.saveSingleFaqAddition(this.faqAddition, this.review.id),
      this.reviewService.addFaqToKb(this.faqAddition.id, this.review.id)
        .pipe(tap((response) => this.savedEvent.emit(response)))
    );

    if (this.faqAddition.qnas.some(qna => !(qna.question?.trim().length > 0) || !(qna.answer?.trim().length > 0))) {
      // not all qnas complete
      this.dialog.open(GenericTextDialogComponent, {
        maxWidth: '400px',
        data: {
          html: `<p>Not all languages have a complete Q&A. If you submit this FAQ, it will be marked pending upon submission and will
                    need to be completed in the Knowledge Base.</p>
                            <p>Do you wish to add this FAQ to the KB as is?</p>`,
          prompt: {accept: 'Yes', decline: 'No'}
        }
      }).afterClosed()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
          if (response) {
            addFaq$.pipe(takeUntil(this.componentDestroyed$)).subscribe();
          }
        });
    } else {
      addFaq$.pipe(takeUntil(this.componentDestroyed$)).subscribe();
    }
  }

  cancelFaqAddition() {
    this.dialog.open(GenericTextDialogComponent, {
      maxWidth: '400px',
      data: {
        html: `<p>Canceling this FAQ addition will remove any pending actions any user may have for this FAQ.</p>
                            <p>Do you wish to proceed?</p>`,
        prompt: {accept: 'Yes', decline: 'No'}
      }
    }).afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((response) => {
        if (response) {

          this.dialog.open(GenericInputPromptDialogComponent, {
            hasBackdrop: false,
            data: {
              text: 'Please leave a comment before canceling this FAQ',
              inputPlaceholder: 'Leave a comment',
              overlayOnElement: document.getElementById('review-card')
            }
          }).afterClosed().subscribe((result) => {

            if (result?.message) {
              this.addCommentEvent.emit(result.message);

              this.reviewService.cancelFaqAddition(this.faqAddition.id, this.review.id).subscribe((faq: FaqAddition) => {
                this.savedEvent.emit(faq);
              });
            }
          });
        }
      });

  }

  mayDelete(): boolean {
    return this.faqAddition.faqAdditionProcessStage === 'CANCELED' && this.isLCDOrBetter;
  }

  deleteFaqAddition() {
    this.reviewService.deleteFaqAddition(this.faqAddition.id, this.review.id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.deletedEvent.emit();
      });
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.componentDestroyed$.next();
  }
}
