import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertWithBody } from '../../model/support/alert-with-body.model';
import { AlertifyDto } from '../../model/support/alertify-dto';
import { QualityCheck } from '../../model/support/quality-check.model';
import { BconnectRating } from '../../model/support/review/bconnect-rating.model';
import { ClassificationDto } from '../../model/support/review/classification-dto';
import { ClassificationFieldValue } from '../../model/support/review/classification-field-value.model';
import { ClosedReview } from '../../model/support/review/closed-review.model';
import { Correction } from '../../model/support/review/corrections.model';
import { EscalationProcess } from '../../model/support/review/escalation-process.model';
import { FaqAddition } from '../../model/support/review/faq-addition.model';
import { QualityChecks } from '../../model/support/review/quality-checks.model';
import { Review } from '../../model/support/review/review.model';
import { TopChat } from '../../model/support/review/top-chat.model';
import { SimpleFaqAddition } from '../../model/support/simple-faq-addition.model';
import { AlertifyService } from './alertify.service';
import { SessionChatInfoService } from './session-chat-info.service';
import { SupportService } from './support.service';
import {ReviewProcessClaim} from "../../model/support/review/review-process-claim.model";
import {PendingActions} from "../../model/support/pending-actions.model";
import {SimpleComment} from "../../model/support/simple-comment.model";
import {ReviewMessage} from "../../model/support/review-message.model";

@Injectable()
export class ReviewService {
  review: Review;
  private _conversationUid: string;

  constructor(private http: HttpClient,
              private supportService: SupportService,
              private sessionChatInfoService: SessionChatInfoService,
              private alertifyService: AlertifyService) {
  }

  public set conversationUid(conversationUid: string) {
    if (!conversationUid) {
      this.review = undefined;
    } else if (this._conversationUid !== conversationUid) {
      this.setReviewByConversationUid(conversationUid);
    }
    this._conversationUid = conversationUid;
  }

  getReviewByConversationUid(conversationUid: string): Observable<Review> {
    return this.http.get<Review>(`/api/support/review/byconversationuid?conversationUid=${conversationUid}`);
  }

  updateClientRating(reviewId: number, rating: number, comment: string): Observable<AlertifyDto> {
    return this.http.post<AlertifyDto>(`/api/support/review/${reviewId}/client-rating`, {rating: rating, comment: comment});
  }

  closeReviewActions(reviewId: number): Observable<boolean> {
    return this.http.post<AlertifyDto>('api/support/review/actions/close', reviewId)
      .pipe(map((alert) => {
        this.alertifyService[alert.type](alert.message);
        return true;
    }));
  }

  closeEscalation(reviewId: number): Observable<EscalationProcess> {
    return this.http.post<AlertWithBody<EscalationProcess>>('/api/support/review/' + reviewId + '/closeEscalation', null)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
    }));
  }

  closeReview(reviewId: number): Observable<ClosedReview> {
    return this.http.post<AlertWithBody<ClosedReview>>('/api/support/review/' + reviewId  + '/close', null)
      .pipe(map((response) => {
      const alert = response.alert;
      this.alertifyService[alert.type](alert.message);
      return response.body;
    }));
  }

  getCorrection(reviewId: number): Observable<Correction> {
    return this.http.get<Correction>(`/api/support/review/${reviewId}/correction`);
  }

  createCorrection(reviewId: number): Observable<Correction> {
    return this.http.post<Correction>(`/api/support/review/${reviewId}/correction`, {});
  }

  getClassificationFields(reviewId: number, classification: string): Observable<ClassificationFieldValue[]> {
    const httpParams = new HttpParams().append('classification', classification);
    return this.http.get<ClassificationFieldValue[]>(`/api/support/review/${reviewId}/classificationFieldDetails`,
      {params: httpParams});
  }

  suggestCorrection(correction: Correction, reviewId: number): Observable<Correction> {
    return this.http.post<AlertWithBody<Correction>>('/api/support/review/' + reviewId + '/correction/suggest', correction)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  returnCorrectionSuggestion(correction: Correction, reviewId: number): Observable<Correction> {
    return this.http.post<AlertWithBody<Correction>>('/api/support/review/' + reviewId + '/correction/return', correction)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  approveCorrection(correction: Correction, reviewId: number): Observable<Correction> {
    return this.http.post<AlertWithBody<Correction>>('/api/support/review/' + reviewId + '/correction/approve', correction)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  closeCorrection(correction: Correction, reviewId: number): Observable<Correction> {
    return this.http.post<AlertWithBody<Correction>>('/api/support/review/' + reviewId + '/correction/close', correction)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  saveCorrectionFieldValues(correction: Correction, reviewId: number): Observable<Correction> {
    return this.http.post<AlertWithBody<Correction>>('/api/support/review/' + reviewId + '/correction/save', correction)
      .pipe(map((response) => {
      const alert = response.alert;
      this.alertifyService[alert.type](alert.message);
      return response.body;
    }));
  }

  reopenReview(reviewId: number): Observable<boolean> {
    return this.http.post<AlertWithBody<boolean>>('/api/support/review/' + reviewId + '/reopen', null)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  unsubscribeFromSession(conversationUid: string) {
    this.http.post<AlertifyDto>('/api/support/review/unsubscribe?conversationUid=' + conversationUid, null).subscribe((alertify: AlertifyDto) => {
      this.alertifyService[alertify.type](alertify.message);
    });
  }

  subscribeToSession(conversationUid: string) {
    this.http.post<AlertifyDto>('/api/support/review/subscribe?conversationUid=' + conversationUid, null).subscribe((alertify: AlertifyDto) => {
      this.alertifyService[alertify.type](alertify.message);
    });
  }

  markAsTopChat(reviewId: number, markTopChat: boolean): Observable<TopChat> {
    return this.http.post<AlertWithBody<TopChat>>('/api/support/review/' + reviewId + '/topchat/' + markTopChat, null)
    .pipe(map((response) => {
      const alert = response.alert;
      this.alertifyService[alert.type](alert.message);
      return response.body;
    }));
  }

  unmarkAsTopChat(reviewId: number): Observable<TopChat> {
    return this.http.post<AlertWithBody<TopChat>>('/api/support/review/' + reviewId + '/topchat/unmark', null)
    .pipe(map((response) => {
      const alert = response.alert;
      this.alertifyService[alert.type](alert.message);
      return response.body;
    }));
  }

  submitTopChatRequest(reviewId: number) {
    return this.http.post<AlertWithBody<TopChat>>('/api/support/review/' + reviewId + '/topchat/submit', null)
    .pipe(map((response) => {
      const alert = response.alert;
      this.alertifyService[alert.type](alert.message);
      return response.body;
    }));
  }

  updateOperatorErrors(operatorErrors: string[], reviewId: number): Observable<string[]> {
    return this.http.post<string[]>('/api/support/review/' + reviewId + '/operatorerrors', {errors: operatorErrors});
  }

  updateQualityChecks(qualityChecks: QualityCheck[], reviewId: number): Observable<QualityChecks> {
    return this.http.post<QualityChecks>('/api/support/review/' + reviewId + '/qualitychecks', {checks: qualityChecks});
  }

  updateBconnectRating(bconnectRating: number, reviewId: number): Observable<BconnectRating> {
    return this.http.post<BconnectRating>('/api/support/review/' + reviewId + '/bconnectrating', {rating: bconnectRating});
  }

  createFaqAddition(reviewId: number): Observable<FaqAddition> {
    return this.http.get<FaqAddition>('/api/support/review/' + reviewId + '/faqAddition/create')
      .pipe(tap((response) => {
          this.alertifyService.success('Created new faq addition');
          return response;
        },
        (err) => {
          this.alertifyService.warning('Unable to create new faq addition');
        }));
  }

  saveSingleFaqAddition(faqAddition: FaqAddition, reviewId: number): Observable<FaqAddition> {
    return this.http.post<AlertWithBody<FaqAddition>>('/api/support/review/' + reviewId + '/faqAddition', faqAddition)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  sendFaqToSupervisor(faqAddition: FaqAddition[], reviewId: number): Observable<FaqAddition[]> {
    return this.http.post<AlertWithBody<FaqAddition[]>>('/api/support/review/' + reviewId + '/faq/toSupervisor', faqAddition)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  sendFaqsToClient(faqAdditions: FaqAddition[], reviewId: number, clientId: number): Observable<FaqAddition[]> {
    return this.http.post<AlertWithBody<FaqAddition[]>>('/api/support/review/' + reviewId + '/faq/toClient/' + clientId, faqAdditions)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  returnFromClient(faqAddition: FaqAddition[], reviewId: number, rejected?: boolean): Observable<FaqAddition[]> {
    const url = '/api/support/review/' + reviewId + '/faq/returnFromClient/' + (rejected ? 'reject' : 'accept');
    return this.http.post<AlertWithBody<FaqAddition[]>>(url, faqAddition)
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  addFaqToKb(faqId: number, reviewId: number): Observable<FaqAddition> {
    return this.http.post<AlertWithBody<FaqAddition>>(`/api/support/review/${reviewId}/faq/${faqId}/addToKb`, {})
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  cancelFaqAddition(faqId: number, reviewId: number): Observable<FaqAddition> {
    return this.http.post<AlertWithBody<FaqAddition>>(`/api/support/review/${reviewId}/faq/${faqId}/cancel`, {})
      .pipe(map((response) => {
        const alert = response.alert;
        this.alertifyService[alert.type](alert.message);
        return response.body;
      }));
  }

  deleteFaqAddition(faqId: number, reviewId: number): Observable<void> {
    return this.http.delete<void>(`/api/support/review/${reviewId}/faq/${faqId}`)
      .pipe(tap(() => {
        this.alertifyService.success('Deleted FAQ addition');
      }));
  }

  addCommentToFaqAddition(faqId: number, reviewId: number, comment: string): Observable<FaqAddition> {
    return this.http.post<FaqAddition>(`/api/support/review/${reviewId}/faq/${faqId}/comment`, {comment: comment});
  }

  addCommentToCorrection(correctionId: number, reviewId: number, comment: string): Observable<SimpleComment> {
    return this.http.post<SimpleComment>(`/api/support/review/${reviewId}/correction/${correctionId}/comment`, {comment: comment});
  }

  getSimpleFaqAdditionsByReviewId(reviewId: number): Observable<FaqAddition[]> {
    const httpParams = new HttpParams().append('reviewId', reviewId.toString());
    return this.http.get<FaqAddition[]>(`/api/support/review/${reviewId}/faqs/finalized`, {params: httpParams});
  }

  getClassificationsByReviewId(reviewId: number, includeHybrids: boolean): Observable<ClassificationDto[]> {
    const httpParams = new HttpParams().append('includeHybrids', String(includeHybrids));
    return this.http.get<ClassificationDto[]>(`/api/support/review/${reviewId}/classificationsWithCategories`,
      {params: httpParams});
  }

  getCommentsFromAcceptedCorrections(reviewId: number): Observable<SimpleComment[]> {
    const httpParams = new HttpParams().append('reviewId', reviewId.toString());
    return this.http.get<SimpleComment[]>(`/api/support/review/${reviewId}/correction/comments/accepted`, {params: httpParams});
  }

  getCorrectionComments(reviewId: number): Observable<SimpleComment[]> {
    const httpParams = new HttpParams().append('reviewId', reviewId.toString());
    return this.http.get<SimpleComment[]>(`/api/support/review/${reviewId}/correction/comments`, {params: httpParams});
  }

  getOpenFaqAdditionsForClient(): Observable<FaqAddition[]> {
    return this.http.get<FaqAddition[]>('/api/support/review/client/openfaqadditions');
  }

  assignActionForType(reviewId: number, actionType: string, assignee: number): Observable<ReviewProcessClaim> {
    return this.http.get<ReviewProcessClaim>(`/api/support/review/${reviewId}/action/${actionType}/user/${assignee}/claim`).pipe(
      tap(() => this.alertifyService.success(`Assigned ${actionType} action`)),
    );
  }

  getPendingActionsByUser(reviewId: number): Observable<PendingActions[]> {
    return this.http.get<PendingActions[]>(`/api/support/review/${reviewId}/actions/pending`);
  }

  private setReviewByConversationUid(conversationUid: string) {
    if (conversationUid) {
      this.getReviewByConversationUid(conversationUid).subscribe((r) => {
        this.review = r;
      });
    }
  }

  resolveMessageFlow(resolutionText: string, reviewId: number, messageType: 'FEEDBACK' | 'CLIENTCOMMENT'):
    Observable<AlertWithBody<ReviewMessage>> {
    return this.http.post<AlertWithBody<ReviewMessage>>(
      `/api/support/review/${reviewId}/${messageType}/resolve`, {message: resolutionText});
  }
}
