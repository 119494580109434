import { Component, OnInit } from '@angular/core';
import { AutomatedResponseFunnelData } from '../../model/automated-response-funnel-data.model';
import { GraphFilterService } from '../graph-filter.service';
import { GraphService } from '../graph.service';

@Component({
  selector: 'app-automated-responses',
  templateUrl: './automated-responses.component.html',
  styleUrls: ['./automated-responses.component.scss'],
})
export class AutomatedResponsesComponent implements OnInit {

  subtitle: string;

  data: AutomatedResponseFunnelData;

  constructor(private graphFilterService: GraphFilterService,
              private graphService: GraphService) { }

  ngOnInit() {

    this.graphFilterService.listen().subscribe((params) => {

      this.graphService.getAutomatedResponseFunnel(params).subscribe((data) => {
        this.data = data;
        this.subtitle = this.graphService.getLabel(params);
      });
    });

  }

}
