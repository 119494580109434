import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClassificationFieldValue } from '../../../../../model/support/review/classification-field-value.model';
import { Correction } from '../../../../../model/support/review/corrections.model';

@Component({
  selector: 'app-classification-fields',
  templateUrl: './classification-fields.component.html',
})

export class ClassificationFieldsComponent implements OnInit {

  @Input() correction: Correction;
  form: FormGroup;
  @Output() saveFieldValues = new EventEmitter();
  @Output() validEvent = new EventEmitter<{valid: boolean}>();
  checkboxTouched = false;

  getValidators(cf: ClassificationFieldValue) {
    if (cf.fieldDefinition.required || cf.fieldDefinition.requirementSetting === 'YES') {
      return Validators.required;
    }

    return null;
  }

  ngOnInit() {
    const formGroup = {};
    this.correction.classificationFieldValueDto.forEach((cf) => {
      formGroup[cf.name] = new FormControl(cf.value, this.getValidators(cf));
    });
    this.form = new FormGroup(formGroup);

    this.validEvent.emit({valid: this.form.valid});

    this.form.valueChanges.subscribe(() => {
      this.validEvent.emit({valid: this.form.valid});
    });
  }

  setClassificationFieldValues() {
    this.correction.classificationFieldValueDto.forEach((cf) => {
      cf.value = this.form.controls[cf.name].value;
    });
  }

  requiredFieldPresent(fieldName: string): boolean {
    const value = this.form.controls[fieldName].value;
    return value !== null && value.length > 0;
  }

  requiredBoxChecked(fieldName: string): boolean {
    const value = this.form.controls[fieldName].value;
    return value !== null && (value === 'true' || value);
  }

  save() {
    this.setClassificationFieldValues();
    this.saveFieldValues.emit();
  }

  clear() {
    this.form.reset();
    this.checkboxTouched = false;
  }

  markFormTouched() {
    this.markFormGroupTouched(this.form);
    this.checkboxTouched = true;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
