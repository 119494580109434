import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChatsByClassification } from '../../model/chats-by-classification.model';
import { CoreParameters } from '../../model/core-parameters.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
})
export class ChatsComponent extends CoreParametersConsumer {
  chatForm: FormGroup;
  subtitle: string;
  noOfChats = 0;
  dataLineChart;
  dataPieChart;
  optionsLineChart = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 0,
        },
      }],
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };
  titlePieChart = 'Chats per classificatie';
  optionsPieChart = {
    legend: {
      position: 'left',
    },
    title: {
      text: `${this.titlePieChart}`,
    },
  };
  dataSource$: Observable<ChatsByClassification>;
  errorMessage: string;

  constructor(graphFilterService: GraphFilterService,
              private formBuilder: FormBuilder,
              private graphService: GraphService,
              private colorService: ColorService) {
    super(graphFilterService);
    this.createForm();
  }

  loadData(coreParameters: CoreParameters): void {
    this.chatsByClassificationLineChart(coreParameters);
  }

  chatsByClassificationLineChart(coreParameters: CoreParameters) {
    this.subtitle = this.graphService.getLabel(coreParameters);
    let obj = {};
    const objArray = [];
    const labelArray = [];
    const dataArray = [];
    const colorArray = [];
    this.dataSource$ = this.graphService.getChats(
      coreParameters).pipe(
      tap((chatsByClassification: ChatsByClassification) => {
          this.checkRequiredParameters(chatsByClassification.labels);
          if (chatsByClassification) {
            this.noOfChats = chatsByClassification.totalChats;
            this.dataLineChart = {};
            this.dataLineChart.labels = chatsByClassification.labels;

            chatsByClassification.chatsByClassification.forEach((chats, index) => {
                obj = {
                  borderColor: this.colorService.getColor(index),
                  data: chats.data,
                  fill: false,
                  label: chats.label,
                };
                objArray.push(obj);
                labelArray.push(chats.label);
                dataArray.push(chats.data.reduce((a, b) => a + b, 0));
                colorArray.push(this.colorService.getColor(index));
              },
            );
            this.dataLineChart.datasets = objArray;
            this.dataPieChart = {
              datasets: [
                {
                  backgroundColor: colorArray,
                  data: dataArray,
                },
              ],
              labels: labelArray,
            };
          }
        },
      ),
    );
  }
  private createForm() {
    this.chatForm = this.formBuilder.group({
      type: [''],
    });
  }
  private checkRequiredParameters(rows: string[]) {
    let errorMessage: string;
    if  ( rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
  }
}
