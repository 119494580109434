import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { GenericInputPromptDialogComponent } from './components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import { GenericSelectDialogComponent } from './components/generic-select-dialog/generic-select-dialog.component';
import { GenericTextDialogComponent } from './components/generic-text-dialog/generic-text-dialog.component';
import { SendButtonComponent } from './components/send-button/send-button.component';
import { SmallButtonComponent } from './components/small-button/small-button.component';
import { GenericFunctionFilterPipe } from './generic-function-filter.pipe';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    CapitalizePipe,
    GenericFunctionFilterPipe,
    GenericTextDialogComponent,
    SmallButtonComponent,
    DeleteButtonComponent,
    GenericInputPromptDialogComponent,
    SendButtonComponent,
    GenericSelectDialogComponent
  ],
    exports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        CapitalizePipe,
        MomentModule,
        GenericFunctionFilterPipe,
        GenericTextDialogComponent,
        SmallButtonComponent,
        DeleteButtonComponent
    ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MomentModule,
  ],
})
export class SharedModule {
}
