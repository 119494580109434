import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-text-dialog',
  templateUrl: './generic-text-dialog.component.html',
  styleUrls: ['./generic-text-dialog.component.css']
})
export class GenericTextDialogComponent {

  constructor(public dialogRef: MatDialogRef<GenericTextDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TextDialogData) { }

  accept() {
    this.dialogRef.close(true);
  }

  decline() {
    this.dialogRef.close(false);
  }

}

export interface TextDialogData {
  text?: string;
  html?: string;
  prompt?: {accept: string, decline: string};
}
