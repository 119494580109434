import { Component, Input, OnInit } from '@angular/core';
import { AutomatedChatFlowCount } from '../../../model/automated-chat-flow-count.model';

@Component({
  selector: 'app-automated-response-counts',
  styleUrls: ['./automated-response-counts.component.scss'],
  templateUrl: './automated-response-counts.component.html',
})
export class AutomatedResponseCountsComponent {

  _counts: AutomatedChatFlowCount[];
  @Input()
  chats: number;
  @Input()
  automatedChats: number;

  @Input()
  set counts(counts: AutomatedChatFlowCount[]) {
    this._counts = counts;
  }

}
