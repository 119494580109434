import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FaqAddition } from '../../../model/support/review/faq-addition.model';
import { FlagService } from '../../../shared/flag.service';

@Component({
  selector: 'app-accepted-faqs-dialog',
  templateUrl: './accepted-faqs-dialog.component.html',
  styleUrls: ['accepted-faqs-dialog.component.scss']
})
export class AcceptedFaqsDialogComponent implements OnInit {

  faqs: Observable<FaqAddition[]>;
  conversationUid: string;

  flagService = FlagService;

  sortFn = (a: FaqAddition, b: FaqAddition) => {
    return new Date(b.closedAt).getTime() - new Date(a.closedAt).getTime();
  }

  constructor(public dialogRef: MatDialogRef<AcceptedFaqsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.faqs = this.data.faqs;
    this.conversationUid = this.data.conversationUid;
  }

  close() {
    this.dialogRef.close();
  }

  markAsSeen() {
    this.dialogRef.close(true);
  }

  getQuestion(faqAddition: FaqAddition, index: number) {
    const question = faqAddition.qnas[index].question;
    return faqAddition.expanded ? question : `${question?.substring(0, Math.min(50, question.length))}...` ;

  }

  getAnswer(faqAddition: FaqAddition, index: number) {
    const answer = faqAddition.qnas[index].answer;
    return faqAddition.expanded ? answer : `${answer?.substring(0, Math.min(50, answer.length))}...` ;
  }

  toStageLabel(faqAddition: FaqAddition): string {
    switch (faqAddition.faqAdditionProcessStage) {
      case 'CANCELED': return 'Canceled';
      case 'ACCEPTED': return 'Accepted';
      case 'REJECTED_BY_CLIENT': return 'Rejected';
      default: return 'Processed';
    }
  }

}
