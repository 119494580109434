import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-chat-archive-details',
  templateUrl: './chat-archive-details.component.html',
})
export class ChatArchiveDetailsComponent implements OnChanges {

  @Input()
  conversationUid: string;
  @Output()
  messageEvent = new EventEmitter<number>();
  @Output()
  getNextEvent = new EventEmitter<number>();

  goBackToChatArchiveOverview($event) {
    this.messageEvent.emit($event);
  }

  getNextReview($event) {
    this.getNextEvent.emit($event);
  }

  // tslint:disable-next-line:no-empty
  ngOnChanges(changes: SimpleChanges): void {
  }
}
