import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlagService {

  public static getFlagClass(languageCode: string) {
    let countryCode;

    if (languageCode === 'EN') {
      countryCode = 'gb';
    } else {
      countryCode = languageCode.toLowerCase();
    }
    return `fi fi-${countryCode}`;
  }
}
