import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  constructor(
    private http: HttpClient
  ) { }

  public getConversationChannels(): Observable<string[]> {
    return this.http.get<string[]>('/api/enums/channels');
  }
}
