import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
})
export class UpdatesComponent {

  conversationUid: string;
  constructor(private authorizationService: AuthorizationService) { }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }
}
