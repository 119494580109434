import {Component, ElementRef, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { StartPathStatistics } from '../../model/start-path-statistics.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-path-statistics',
  templateUrl: 'path-statistics.component.html',
})
export class PathStatisticsComponent extends CoreParametersConsumer {
  readonly defaultColumns: string[] = [
    'path',
    'averageVisitorRating',
    'averageClientRating',
    'reachInvites',
    'reachChats',
    'visits',
    'numberOfChats',
    'percentageOfTotalChats',

  ];
  readonly options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            // Workaround for https://github.com/chartjs/Chart.js/issues/2539
            callback: (tickValue) => {
              if (Math.floor(tickValue) === tickValue) {
                return tickValue;
              }
            },
            maxTicksLimit: 5,
          },
        },
      ],
    },
  };
  subtitle: string;
  displayedColumns: string[] = [];
  dynamicColumns: string[] = [];
  selectedAccount: string;
  errorMessage: string;
  numberView: string = 'percentage';
  data$: Observable<StartPathStatistics[]>;
  dataTable: MatTableDataSource<StartPathStatistics>;
  dataChart$: Observable<{ datasets: Array<{ data: number[] }>; labels: string[] }>;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('filter', {static: false}) filter: ElementRef;

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService,
              private authorizationService: AuthorizationService) {
    super(graphFilterService);
  }

  get initialized(): boolean {
    return !!this.data$;
  }

  loadData(coreParameters: CoreParameters): void {
    this.subtitle = this.graphService.getLabel(coreParameters);
    if (coreParameters.accounts && coreParameters.accounts.length === 1) {
      this.selectedAccount = coreParameters.accounts[0].name;
      this.data$ = this.graphService.getStartPath(
        coreParameters).pipe(
        share(),
      );

      this.data$.subscribe((startPathStatistics: StartPathStatistics[]) => {
          const dataSource = new MatTableDataSource(startPathStatistics);
          this.checkRequiredParameters(startPathStatistics);
          if (startPathStatistics.length > 0) {
            this.dynamicColumns = startPathStatistics[startPathStatistics.length - 1].chatsByClassification.labels;
          }
          this.displayedColumns = this.defaultColumns.concat(this.dynamicColumns);

          dataSource.paginator = this.paginator;
          dataSource.sort = this.sort;
          dataSource.sortingDataAccessor = (data: StartPathStatistics, header: string) => {
            const index = this.dynamicColumns.indexOf(header);
            if (index >= 0) {
              switch (this.numberView) {
                case 'percentage': {
                  return Math.floor((data.chatsByClassification.data[index].numberOfChats / data.numberOfChats) * 100);
                }
                default: {
                  return data.chatsByClassification.data[index].numberOfChats;
                }
              }
            } else {
              return data[header];
            }
          };

          this.dataTable = dataSource;
        });

      this.dataChart$ = this.data$.pipe(
        map((startPathStatistics: StartPathStatistics[]) => {
          startPathStatistics.sort((a, b) => a.numberOfChats - b.numberOfChats);
          startPathStatistics.reverse();
          startPathStatistics = startPathStatistics.slice(0, 15);

          return {
            datasets: [
              {
                backgroundColor: this.colorService.getColor(0),
                data: startPathStatistics.map((value) => value.numberOfChats),
              },
            ],
            labels: startPathStatistics.map((value) => value.path),
          };
        }),
      );
    } else {
      this.checkRequiredParametersWithCoreParameters(coreParameters);
      this.selectedAccount = undefined;
      this.data$ = undefined;
    }
  }

  applyFilter(filterValue: string) {
    this.dataTable.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.dataTable.filter = undefined;
    this.filter.nativeElement.value = '';
  }

  getFooterRowAverageValue(fieldName: string): number {
    let sum;
    let avg;
    const elements = this.dataTable.filteredData
      .filter((f) => f[fieldName] !== null)
      .map((f) => f[fieldName]);
    if (elements.length) {
      sum = elements.reduce((a, b) => a + b);
      avg = sum / elements.length;
    }
    return avg;
  }

  getFooterRowSumValue(fieldName: string): number {
    let sum;
    const elements = this.dataTable.filteredData
      .filter((f) => f[fieldName] !== null)
      .map((f) => f[fieldName]);
    if (elements.length) {
      sum = elements.reduce((a, b) => a + b);
    }
    return sum;
  }

  getFooterRowValueForClassification(index: number) {
    let data: string = 'n/a';
    const chatsByClassifications = this.dataTable.filteredData.map((f) => f.chatsByClassification);
    if (chatsByClassifications.length) {
      const classificationCount = chatsByClassifications
        .map((c) => c.data[index].numberOfChats)
        .reduce((a, b) => a + b);
      if (this.numberView === 'percentage') {
        const totalCount = this.dataTable.filteredData
          .map((f) => f.numberOfChats)
          .reduce((a, b) => a + b);
        data = (classificationCount / totalCount * 100.0).toFixed(1) + '%';
      } else {
        data = classificationCount.toString();
      }
    }
    return data;
  }

  private checkRequiredParametersWithCoreParameters(coreParameters: CoreParameters) {
    let errorMessage: string = null;
    if  (!coreParameters.accounts) {
      errorMessage = 'Selecting an account is required';
    } else if (coreParameters.accounts.length > 1) {
      errorMessage = 'Multiple accounts selected, please limit the selected accounts to one for this view.';
    }
    this.errorMessage = errorMessage;
  }

  private checkRequiredParameters(rows: StartPathStatistics[]) {
     let errorMessage: string = null;
     if (rows.length === 0) {
      errorMessage = 'No data found for ' + this.selectedAccount;
     }
     this.errorMessage = errorMessage;
  }

  private setNumberType(type: string) {
    this.numberView = type;
  }

  private getDynamicData(i: number, element: any) {
    let data: string = 'n/a';
    if (element.chatsByClassification.data[i].numberOfChats !== 0) {
      switch (this.numberView) {
        case 'percentage' : {
          const totalChats = element.numberOfChats;
          data = Math.floor((element.chatsByClassification.data[i].numberOfChats / totalChats) * 100) + '%';
          break;
        }
        case 'number' : {
          data = element.chatsByClassification.data[i].numberOfChats;
          break;
        }
      }
    }
    return data;
  }

}
