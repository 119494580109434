import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../../core/authorization.service';
import { ReviewMessage } from '../../../../model/support/review-message.model';
import { Review } from '../../../../model/support/review/review.model';
import { SimplerComment } from '../../../../model/support/simpler-comment.model';
import { AlertifyService } from '../../../service/alertify.service';
import { MessageService } from '../../../service/message.service';
import { ReviewService } from '../../../service/review.service';
import { SupportService } from '../../../service/support.service';

@Component({
  selector: 'app-message-send',
  templateUrl: './message-send.component.html',
  styleUrls: ['message-send.component.scss']
})
export class MessageSendComponent implements OnDestroy, OnChanges {

  @Input()
  staticMessages: SimplerComment[];
  @Input()
  reviewMessages: ReviewMessage[];
  @Input()
  historyOnly: boolean;
  @Input()
  type: 'CLIENTCOMMENT' | 'CORRECTION' | 'ESCALATE' | 'FAQ' | 'FEEDBACK' | 'OPTIMIZATION' | 'TOPCHAT';
  @Input()
  conversationUid: string;
  @Input()
  review: Review;
  _disabled: boolean;
  @Output()
  sendMessage = new EventEmitter<ReviewMessage>();
  @Input()
  placeholder: string;
  message = new FormControl('');
  subscription: Subscription;
  constructor(private reviewService: ReviewService,
              private alertify: AlertifyService,
              private supportService: SupportService,
              private messageService: MessageService,
              private authorizationService: AuthorizationService) {
  }

  get hasText(): boolean {
    return this.message !== null && this.message.value !== null && this.message.value.length > 0;
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get disabled(): boolean {

    return this._disabled || (this.reviewService.review.closedAt != null && !this.authorizationService.isClient);
  }

  unSubscribeAll(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unSubscribeAll();
  }

  ngOnChanges(): void {
    this.message.setValue('');
  }

  hasMessage(): boolean {
    return this.message.value?.length > 0;
  }

  emitReviewMessage() {
    if (this.hasMessage()) {
      let reviewMessage: ReviewMessage;
      reviewMessage = this.messageService.createReviewMessage(
        this.review.id,
        this.conversationUid,
        this.message.value,
        this.type,
        true);
      this.unSubscribeAll();
      this.sendMessage.emit(reviewMessage);
      this.message.reset();
    }
  }
}

interface ButtonStyle {
  accept: { [key: string]: string; };
  deny: { [key: string]: string; };
  send: { [key: string]: string; };
  escalate: { [key: string]: string; };
}
