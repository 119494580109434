import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from '../../../../core/authorization.service';
import { Review } from '../../../../model/support/review/review.model';
import { TopChat } from '../../../../model/support/review/top-chat.model';
import { SimpleComment } from '../../../../model/support/simple-comment.model';
import {
  GenericInputPromptDialogComponent
} from '../../../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import { MessageService } from '../../../service/message.service';
import { ReviewService } from '../../../service/review.service';

@Component({
  selector: 'app-feedback-top-chat',
  templateUrl: './feedback-top-chat.component.html',
})
export class FeedbackTopChatComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  review: Review;
  conversationUid: string;
  infoMessage: string;

  constructor(private authorizationService: AuthorizationService,
              private reviewService: ReviewService,
              private messageService: MessageService,
              private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.setInfoMessage();
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  get isLCDOrBetter(): boolean {
    return this.authorizationService.isLcd || this.authorizationService.isAdmin;
  }

  isReviewForCurrentUser(): boolean {
    return this.authorizationService.currentUser.name === this.review.servedBy;
  }

  addComment($event) {
    const simpleComment: SimpleComment = {id: undefined, comment: $event, createdAt: undefined, commentBy: undefined};
    this.messageService.addTopChatRequestComment(this.review.id, simpleComment).subscribe((t) => {
      if (this.authorizationService.isOperator) {
        this.reviewService.submitTopChatRequest(this.review.id).subscribe((topChat) => {
          this.review.topChat = topChat;
        });
      } else {
        this.review.topChat = t;
      }
    });
  }

  ngOnChanges(): void {
    this.conversationUid = this.review.conversationUid;
  }

  setInfoMessage() {
    if (this.review.topChat) {
      switch (this.review.topChat.processStage) {
        case 'ACCEPTED':
          this.infoMessage = 'This chat is marked as top chat!';
          break;
        case 'UNDER_REVIEW':
          this.infoMessage = 'A top chat review is in progress';
          break;
        case 'SUBMITTED_BY_OPER':
          this.infoMessage = 'A top chat request was submitted by operator';
          break;
      }
    }
  }

  markTopChat(isTopChat: boolean) {
    const text = `Please leave a comment before ${isTopChat ? 'marking' : 'rejecting'} as top chat`;

    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: text,
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed().subscribe((result) => {

      if (result?.message) {

        const simpleComment: SimpleComment = {id: undefined, comment: result.message, createdAt: undefined, commentBy: undefined};
        this.messageService.addTopChatRequestComment(this.review.id, simpleComment).subscribe((t) => {
          this.reviewService.markAsTopChat(this.review.id, isTopChat).subscribe((tc: TopChat) => {
            this.review.topChat = tc;
            this.setInfoMessage();
          });
        });
      }
    });
  }

  unmarkAsTopChat() {
    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: 'Please leave a comment before unmarking as top chat.',
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed().subscribe((result) => {

      if (result?.message) {

        const simpleComment: SimpleComment = {id: undefined, comment: result.message, createdAt: undefined, commentBy: undefined};
        this.messageService.addTopChatRequestComment(this.review.id, simpleComment).subscribe((t) => {

          this.reviewService.unmarkAsTopChat(this.review.id).subscribe((tc: TopChat) => {
            this.review.topChat = tc;
          });

        });
      }
    });

  }

  submitTopChatRequest() {
    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: 'Please leave a comment before submitting this request',
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed().subscribe((result) => {

      if (result?.message) {

        const simpleComment: SimpleComment = {id: undefined, comment: result.message, createdAt: undefined, commentBy: undefined};
        this.messageService.addTopChatRequestComment(this.review.id, simpleComment).subscribe((t) => {

          this.reviewService.submitTopChatRequest(this.review.id).subscribe((tc: TopChat) => {
            this.review.topChat = tc;
            this.setInfoMessage();
          });

        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

}
