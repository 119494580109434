import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionChatInfo } from '../../model/support/session-chat-info.model';

@Injectable()
export class SessionChatInfoService {

  sessionChatInfo: SessionChatInfo;
  conversationUid: string;

  private sessionChatInfoSubject = new ReplaySubject<SessionChatInfo>(1);
  private sessionChatInfo$ = this.sessionChatInfoSubject.asObservable();

  constructor(private http: HttpClient) {}

  listen(): Observable<SessionChatInfo> {
    return this.sessionChatInfo$;
  }

  setConversation(conversationUid: string): Observable<SessionChatInfo> {
    if (this.conversationUid !== conversationUid) {
      this.sessionChatInfoSubject.next(undefined);
    }

    this.conversationUid = conversationUid;
    return this.getConversation(conversationUid).pipe(
      tap((sessionChatInfo: SessionChatInfo) => {
          if (sessionChatInfo) {
            this.sessionChatInfo = sessionChatInfo;
            this.sessionChatInfoSubject.next(sessionChatInfo);
          }
        },
      ),
    );
  }

  public getConversation(conversationUid: string): Observable<SessionChatInfo> {
    return this.http.get<SessionChatInfo>(`/api/chatsession/get?conversationUid=${conversationUid}`);
  }

}
