import { Component, Input, OnInit } from '@angular/core';
import { PageVisit } from '../../../model/support/PageVisit.model';
import { SessionInfo } from '../../../model/support/session-info.model';

@Component({
  selector: 'app-session-clickpath',
  templateUrl: './session-click-path.component.html',
})
export class SessionClickPathComponent {

  @Input()
  clickPath: PageVisit[];
  @Input()
  sessionInfo: SessionInfo;

  public chatStartedOnPath(pageVisit: PageVisit): boolean {
    return this.sessionInfo.startDate.valueOf() > pageVisit.visitStart.valueOf() &&
      this.sessionInfo.startDate.valueOf() < pageVisit.visitEnd.valueOf();
  }
  public inviteOnPath(pagevisit: PageVisit): boolean {
    return pagevisit.inviteTimestamp != null;
  }
}
