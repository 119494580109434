import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { ChartModule } from 'primeng/chart';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MenuComponent } from '../menu/menu.component';
import { PercentConcatPipe } from '../pipes/percent-concat.pipe';
import { SharedModule } from '../shared/shared.module';
import { AccountLeaderboardComponent } from './account-leaderboard/account-leaderboard.component';
import { CeoHomeComponent } from './ceo-home/ceo-home.component';
import { ChartStatisticsComponent } from './chart-statistics/chart-statistics.component';
import { ChatsPeriodDistributionComponent } from './chats-period-distribution/chats-period-distribution.component';
import { ChatsComponent } from './chats/chats.component';
import { ClientHappinessComponent } from './client-happiness/client-happiness.component';
import { DefaultComponent } from './default/default.component';
import { FinanceComponent } from './finance/finance.component';
import { FunnelOperatorComponent } from './funnel-operator/funnel-operator.component';
import { FunnelComponent } from './funnel/funnel.component';
import { GraphFilterService } from './graph-filter.service';
import { ColorService } from './graph-services/color.service';
import { GraphComponent } from './graph.component';
import { GraphService } from './graph.service';
import { OnlineLoggingComponent } from './online-logging/online-logging.component';
import { OperatorHomeComponent } from './operator-home/operator-home.component';
import { OperatorLeaderboardComponent } from './operator-leaderboard/operator-leaderboard.component';
import { PathStatisticsComponent } from './path-statistics/path-statistics.component';
import { PerformanceComponent } from './performance/performance.component';
import { ScoreComponent } from './score/score.component';
import { GraphCoreFilterComponent } from './shared/graph-core-filter.component';
import { TeamleadHomeComponent } from './teamlead-home/teamlead-home.component';
import {
  TeamleadScoresComponent,
} from './teamlead-home/teamlead-scores/teamlead-scores-component/teamlead-scores.component';
import { TriggerAnalyticsComponent } from './trigger-analytics/trigger-analytics.component';
import { VisitorHappinessComponent } from './visitor-happiness/visitor-happiness.component';
import { WordcloudComponent } from './wordcloud/wordcloud.component';
import { AutomatedResponsesComponent } from './automated-responses/automated-responses.component';
import { AutomatedResponseCountsComponent } from './automated-responses/automated-response-counts/automated-response-counts.component';

@NgModule({
  declarations: [
    PathStatisticsComponent,
    ChatsComponent,
    GraphComponent,
    GraphCoreFilterComponent,
    PerformanceComponent,
    WordcloudComponent,
    GraphCoreFilterComponent,
    MenuComponent,
    VisitorHappinessComponent,
    ClientHappinessComponent,
    DefaultComponent,
    ChartStatisticsComponent,
    ChatsPeriodDistributionComponent,
    FunnelComponent,
    PercentConcatPipe,
    ScoreComponent,
    OperatorHomeComponent,
    AccountLeaderboardComponent,
    OperatorLeaderboardComponent,
    FunnelOperatorComponent,
    CeoHomeComponent,
    TeamleadHomeComponent,
    FinanceComponent,
    TeamleadHomeComponent,
    OnlineLoggingComponent,
    TeamleadScoresComponent,
    TriggerAnalyticsComponent,
    AutomatedResponsesComponent,
    AutomatedResponseCountsComponent,
  ],
  exports: [
    GraphComponent,
  ],
    imports: [
        ChartModule,
        RouterModule,
        SharedModule,
        MatMenuModule,
        MatSlideToggleModule,
        FormsModule,
        MatAutocompleteModule,
        NgxMatSelectSearchModule,
        MatToolbarModule,
        TagCloudModule,
        MatGridListModule,
    ],
  providers: [
    GraphService,
    GraphFilterService,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    PercentConcatPipe,
    DatePipe,
    ColorService,
  ],
})
export class GraphModule {
}
