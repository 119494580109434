import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { OnlineLoggingRow } from '../../model/online-logging.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-online-logging',
  templateUrl: './online-logging.component.html',
})
export class OnlineLoggingComponent extends CoreParametersConsumer {

  _this = this;
  subtitle: string;
  selectedYearMonth: string;
  yearMonths: string[] = [];
  rawData: OnlineLoggingRow[];
  filteredData: OnlineLoggingRow[];
  logging: Observable<OnlineLoggingRow[]>;
  dataSource: MatTableDataSource<OnlineLoggingRow>;
  displayedColumns = ['date', 'hoursOnline', 'chats', 'leads', 'service', 'missed', 'wasted'];
  dataHoursPerDay: any;
  optionsHoursPerDay = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 24,
          min: 0,
        },
      }],
    },
  };
  dataPeriodsPerDay: any;
  optionsPeriodPerDay = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: 24,
          min: 0,
        },
        type: 'linear',
      }],
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          const index = tooltipItem.index;
          const datasetIndex = tooltipItem.datasetIndex;
          return data.datasets[datasetIndex].tooltips[index];
        },
      },
    },
  };
  noData = true;
  noDataMessage = 'There is no data available';
  errorMessage: string;
  accountFilter: string;
  loading = false;
  totals: MatTableDataSource<any>;

  constructor(private graphService: GraphService,
              graphFilterService: GraphFilterService,
              private colorService: ColorService,
              private authService: AuthorizationService) {
    super(graphFilterService);
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  loadData(coreParameters: CoreParameters): void {
    if (!coreParameters.accounts || coreParameters.accounts.length === 0) {
      this.errorMessage = 'Selecting one or more accounts is required.';
    } else {
      this.loading = true;
      this.accountFilter = coreParameters.accounts.map((a) => a.name).join(',');
      this.logging = this.graphService.getOnlineLogging(coreParameters).pipe(
        tap((l) => {
          if (l.length > 0) {
            this.noData = false;
            this.selectedYearMonth = l[0].yearMonth;
            const allYearMonths = l.map((d) => d.yearMonth);
            this.yearMonths = Array.from(new Set(allYearMonths));
          }
        }),
        share());
      this.logging.subscribe(
        (l) => {
          this.rawData = l;
          this.dataSource = new MatTableDataSource(l);
          this.selectYearMonth(this.selectedYearMonth);
          this.setBarData();
          this.loading = false;
        });
    }
  }

  setBarData() {
    const labels = this.filteredData.map((r) => new Date(r.date).getDate());

    // hours online per date
    const hours = this.filteredData.map((r) => r.hoursOnline);
    this.dataHoursPerDay = {
      datasets: [].concat({
        backgroundColor: this.colorService.getColor(2),
        data: hours,
        label: 'No of hours online per date',
      }),
      labels,
    };

    // periods online per date
    const datasets = [];
    const periods = this.filteredData.map((r) => r.periodsOfDay);

    // https://www.primefaces.org/primeng/chart/bar

    for (const ps of periods) {
      if (ps[0] != null && ps[0].online) {
        // period starts with online period, add offline period of length 0 at start to force sequence of off/on/off/on...
        const atStartOfDay = new Date(new Date(ps[0].start).setHours(0, 0, 0));
        ps.unshift({online: false, start: atStartOfDay, end: ps[0].start, durationSeconds: 0});
      }
    }

    // each bar must have an equal amount of elements stacked, so take the max length of the periods as starting point
    const iterations = Math.max(...periods.map((ps) => ps.length));
    for (let i = 0; i < iterations; i++) {
      // loop until iterations.length -1 is reached, if no elements exist for i, add an 'empty' record of value 0
      const data = [];
      const tooltips = [];

      periods.forEach((ps) => {
        const atIndex = ps[i];
        if (atIndex != null) {
          data.push((ps[i].durationSeconds / 3600.0).toFixed(1));
          const start = new Date(atIndex.start);
          const end = new Date(atIndex.end);
          tooltips.push(('0' + start.getHours()).substr(-2) + ':' +
            ('0' + start.getMinutes()).substr(-2) +
            ' - ' + ('0' + end.getHours()).substr(-2) + ':' +
            ('0' + end.getMinutes()).substr(-2));
        } else {
          data.push(0);
          tooltips.push('');
        }
      });

      // NOTE: the data should always start with an 'offline' record and alternate between offline and online
      const backgroundColor = (i % 2 === 0) ? 'transparent' : this.colorService.getColor(2);
      datasets.push({
        backgroundColor,
        data,
        tooltips,
        });
    }

    this.dataPeriodsPerDay = {
      datasets,
      labels,
    };
  }

  selectYearMonth(yearMonth: string) {
    this.selectedYearMonth = yearMonth;
    this.dataSource.filter = yearMonth;
    this.filteredData = this.rawData.filter((r) => r.yearMonth === this.selectedYearMonth);

    if (this.isAdmin()) {
      this.totals = new MatTableDataSource([].concat(
        {
          chats: this.filteredData.map((e) => e.chats).reduce((sum, e) => sum + e, 0),
          date: 'TOTAL', // function as label
          hoursOnline: this.filteredData.map((e) => e.hoursOnline).reduce((sum, e) => sum + e, 0),
          leads: this.filteredData.map((e) => e.leads).reduce((sum, e) => sum + e, 0),
          missed: this.filteredData.map((e) => e.missed).reduce((sum, e) => sum + e, 0),
          service: this.filteredData.map((e) => e.service).reduce((sum, e) => sum + e, 0),
          wasted: this.filteredData.map((e) => e.wasted).reduce((sum, e) => sum + e, 0),
        }));
    }
    this.setBarData();
  }
}
