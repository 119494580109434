import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { tap } from 'rxjs/operators';
import { AuthorizationService } from '../../../core/authorization.service';
import { ColorService } from '../../../graph/graph-services/color.service';
import { ChatMessage } from '../../../model/support/chat-message.model';
import { ClassificationInfo } from '../../../model/support/classification-info';
import { SessionChatInfo } from '../../../model/support/session-chat-info.model';
import { SessionChatInfoService } from '../../service/session-chat-info.service';
import { SupportService } from '../../service/support.service';
import { VisitorRemarkViewComponent } from '../visitor-remark-view/visitor-remark-view.component';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
})
export class SessionDetailsComponent implements  OnChanges {
  @ViewChild('chatMessages', {static: false}) chatMessages: ElementRef;
  @Input()
  conversationUid: string;
  @Input()
  list: boolean = true;
  @Output()
  getNextEvent = new EventEmitter<number>();
  @Output()
  unloadConversationDetailsEvent = new EventEmitter<string>();
  @Output()
  loadedSession = new EventEmitter<SessionChatInfo>();

  datePiper: DatePipe;
  fontSize = 12;

  constructor(private supportService: SupportService,
              private colorService: ColorService,
              private datePipe: DatePipe,
              public sessionChatInfoService: SessionChatInfoService,
              public authorizationService: AuthorizationService,
              public dialog: MatDialog) {
    this.datePiper = datePipe;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadChatSession(this.conversationUid);
    if ((changes.conversationUid.previousValue !== changes.conversationUid.currentValue) && !changes.conversationUid.firstChange) {
      this.scrollMessagesToTop();
    }
  }

  loadChatSession(conversationUid: string) {
    this.sessionChatInfoService.setConversation(conversationUid).pipe(
      tap((s: SessionChatInfo) => {
        if (s) {
          this.loadedSession.emit(s);
        }
      }),
    ).subscribe();
  }

  scrollMessagesToTop() {
    if (this.chatMessages) {
      this.chatMessages.nativeElement.scrollTo(0, 0);
    }
  }

  getMessageSender(message: ChatMessage): string {
    let sender = '';
    if (message.sender === 'visitor') {
      sender = 'Visitor';
    } else if (message.sender === 'agent') {
      sender = this.sessionChatInfoService.sessionChatInfo.sessionInfo.servedBy;
    }
    return sender;
  }

  goBackToOverview() {
    this.unloadConversationDetailsEvent.emit(this.conversationUid);
  }

  getNextReview($event) {
    this.getNextEvent.emit($event);
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  getClassificationFieldsAsPairs(): ClassificationInfo[][] {
    const fields: ClassificationInfo[] = this.sessionChatInfoService.sessionChatInfo.sessionInfo.classificationFields;
    const pairs: ClassificationInfo[][] = [];
    if (fields) {
      fields.forEach((field) => {
        if (pairs.length > 0 && pairs[pairs.length - 1].length < 2) {
          pairs[pairs.length - 1].push(field);
        } else {
          pairs.push([field]);
        }
      });
    }
    return pairs;
  }
  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  openVisitorCommentDialog() {
    this.dialog.open(VisitorRemarkViewComponent, {
      data: this.sessionChatInfoService.sessionChatInfo.sessionInfo.visitorComments ?
        this.sessionChatInfoService.sessionChatInfo.sessionInfo.visitorComments : 'No visitor remark available',
    });
  }
}
