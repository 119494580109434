import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Overview } from '../../model/support/overview.model';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
})
export class OverviewComponent implements OnInit {

  dataSourceOperator: MatTableDataSource<Overview>;
  dataSourceTL: MatTableDataSource<Overview>;
  dataSourceLCD: MatTableDataSource<Overview>;
  columnsOperator: string[];
  columnsTL: string[];
  columnsLCD: string[];
  timeUpdated: number;

  constructor(private supportService: SupportService) { }

  ngOnInit() {
    this.supportService.getOverview().subscribe((overview: Overview[]) => {
      this.timeUpdated = Date.now();
      const dataOperator = overview.filter((o) => o.role === 'ROLE_OPER');
      const dataTL = overview.filter((o) => o.role === 'ROLE_TL');
      const dataLCD = overview.filter((o) => o.role === 'ROLE_LCD');
      if (dataOperator.length > 0) {
        this.columnsOperator = this.getNonNullObjectKeys(dataOperator[0]);
        this.dataSourceOperator = new MatTableDataSource(dataOperator);
      }
      if (dataTL.length > 0) {
        this.columnsTL = this.getNonNullObjectKeys(dataTL[0]);
        this.dataSourceTL = new MatTableDataSource(dataTL);
      }
      if (dataLCD.length > 0) {
        this.columnsLCD = this.getNonNullObjectKeys(dataLCD[0]);
        this.dataSourceLCD = new MatTableDataSource(dataLCD);
      }
      },
    );
  }

  getColumnHeader(key: string): string {
    let header = '';
    switch (key) {
      case 'user' : {
        header = 'User';
        break;
      }
      case 'openReviews' : {
        header = 'Open Reviews';
        break;
      }
      case 'openFeedback' : {
        header = 'Open Feedback';
        break;
      }
      case 'openFeedbackFrom' : {
        header = 'Open Feedback (Team)';
        break;
      }
      case 'openEscalations' : {
        header = 'Open Escalations';
        break;
      }
      case 'openTopChatApprovals' : {
        header = 'Open Top Chat Approvals';
        break;
      }
      case 'openHqReview' : {
        header = 'Open HQ Reviews';
        break;
      }
      case 'openAddFaq' : {
        header = 'Open FAQ Additions';
        break;
      }
      case 'openCorrections' : {
        header = 'Open Corrections';
        break;
      }
      case 'hqReviewUpdates' : {
        header = 'HQ Review Updates';
        break;
      }
      case 'acceptedCorrections' : {
        header = 'Accepted Corrections';
        break;
      }
      case 'acceptedTopChats' : {
        header = 'Accepted Top Chats';
        break;
      }
      case 'declinedFaqs' : {
        header = 'Declined FAQs';
        break;
      }
      case 'declinedCorrections' : {
        header = 'Declined Corrections';
        break;
      }
    }
    return header;
  }

  getNonNullObjectKeys(object: object): string[] {
    const keys: string[] = [];
    Object.keys(object).forEach((k) => {
      if (object[k] !== undefined && k !== 'role') {
        keys.push(k);
      }
    });
    return keys;
  }
}
