import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SimpleComment } from '../../../model/support/simple-comment.model';

@Component({
  selector: 'app-accepted-corrections-dialog',
  templateUrl: './accepted-corrections-dialog.component.html',
})
export class AcceptedCorrectionsDialogComponent implements OnInit {
  comments: Observable<SimpleComment[]>;
  conversationUid: string;

  constructor(public dialogRef: MatDialogRef<AcceptedCorrectionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.comments = this.data.correctionComments;
    this.conversationUid = this.data.conversationUid;
    }

  close() {
    this.dialogRef.close();
  }

  markAsSeen() {
    this.dialogRef.close(true);
  }

}
