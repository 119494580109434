import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { FinanceData } from '../../model/finance-data.model';
import { LabeledNumericData } from '../../model/labeled-numeric-data';
import { GraphFilterService } from '../graph-filter.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-finance',
  styles: [],
  templateUrl: './finance.component.html',
})
export class FinanceComponent extends CoreParametersConsumer {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  errorMessage: string;
  displayedColumns: string[];
  subtitle: string;
  csvTitle: string;
  renderedData: FinanceData[];
  dataSource: MatTableDataSource<FinanceData>;
  classificationCountColumns: string[];
  pricePerClassificationColumns: string[];
  revenuePerClassificationColumns: string[];
  trendGrouping: string;
  totalsIndex: number;
  activeCoreParameters: CoreParameters;

  constructor(graphFilterService: GraphFilterService,
              private authorizationService: AuthorizationService,
              private graphService: GraphService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters): void {
    this.activeCoreParameters = coreParameters;
    this.setSubtitle(coreParameters);
    this.trendGrouping = coreParameters.summarizeBy === 'month' ? 'month' : 'week';

    this.graphService.getFinanceData(coreParameters).subscribe((data: FinanceData[]) => {
      if (data.length > 0) {
        data.forEach((f) => f.revenuePerClassification.labels = f.revenuePerClassification.labels.map((l) => 'Σ ' + l));
        data.forEach((f) => f.pricePerClassification.labels = f.pricePerClassification.labels.map((l) => '€ ' + l));
        this.errorMessage = null;
        this.classificationCountColumns = data[0].classificationCount.labels;
        this.pricePerClassificationColumns = data[0].pricePerClassification.labels;
        this.revenuePerClassificationColumns = data[0].revenuePerClassification.labels;

        this.displayedColumns =
          ['account']
            .concat(this.classificationCountColumns)
            .concat(this.pricePerClassificationColumns)
            .concat(this.revenuePerClassificationColumns)
            .concat([
              'totalRevenue',
              'revenueTrendPercentage',
            ]).concat(['download']);

        this.totalsIndex = data.findIndex((f) => f.account === 'TOTAL');
      } else {
        this.errorMessage = 'There is no data available';
      }
      const dataSource = new MatTableDataSource(data);
      this.enableSorting(dataSource);
      this.dataSource = dataSource;
      this.dataSource.connect().subscribe((d) => this.renderedData = d);
    });
  }

  enableSorting(dataSource: MatTableDataSource<FinanceData>) {
    dataSource.sort = this.sort;
    dataSource.sortingDataAccessor = (row: FinanceData, header: string) => {
      let val;
      if (this.classificationCountColumns.indexOf(header) >= 0) {
        val = row.classificationCount.data[this.classificationCountColumns.indexOf(header)];
      } else if (this.pricePerClassificationColumns.indexOf(header) >= 0) {
        val = row.pricePerClassification.data[this.pricePerClassificationColumns.indexOf(header)];
      } else if (this.revenuePerClassificationColumns.indexOf(header) >= 0) {
        val = row.revenuePerClassification.data[this.revenuePerClassificationColumns.indexOf(header)];
      } else {
        val = row[header];
      }
      if (typeof val === 'string') {
        val = val.toLowerCase();
      }
      return val;
    };
    // https://github.com/crisbeto/material2/commit/118970631d049f1ebb0bb748d605e3578a8880da
    // is required because without fix zero values are not sorted properly
    dataSource.sortData = (d: FinanceData[], sort: MatSort): FinanceData[] => {
      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction === '') {
        return d;
      }
      const totals = d.splice(this.totalsIndex, this.totalsIndex)[0];
      d = d.sort((a, b) => {
        const valueA = dataSource.sortingDataAccessor(a, active);
        const valueB = dataSource.sortingDataAccessor(b, active);

        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        } else if (valueA != null) {
          comparatorResult = 1;
        } else if (valueB != null) {
          comparatorResult = -1;
        }
        return comparatorResult * (direction === 'asc' ? 1 : -1);
      });
      d.push(totals);
      return d;
    };
    return dataSource;
  }

  getClassificationCountData(index: number, element: FinanceData) {
    return element.classificationCount.data[index];
  }

  getPricePerClassificationData(index: number, element: FinanceData) {
    return element.pricePerClassification.data[index];
  }

  getRevenuePerClassificationData(index: number, element: FinanceData) {
    return element.revenuePerClassification.data[index];
  }

  isTotalsRow(row: number): boolean {
    return row === this.totalsIndex;
  }

  private setSubtitle(coreParameters: CoreParameters) {
    this.subtitle = this.graphService.getLabel(coreParameters);
  }

  private getTrendColor(val: number): string {
    if (val >= 0.0) {
      return 'green';
    } else {
      return 'red';
    }
  }

  private exportAsCSV() {
    this.graphService.getFinanceDataCsv(this.activeCoreParameters);
  }

}
