import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {KeyValuePair} from '../../../model/key-value-pair';

@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
})
export class ReviewDialogComponent implements OnInit {
  done: boolean = false;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<KeyValuePair<number, boolean>>) {
  }

  ngOnInit(): void {
    const toDo: number = this.data.filter((kv) => kv.value !== true).length;
    if (toDo === 0) {
      this.done = true;
      this.message = 'You\'ve completed ' + this.data.length + ' review' + (this.data.length > 1 ? 's' : '') + '! Good job!';
    } else {
      this.message = 'You still need to review ' + toDo + ' session' + (toDo > 1 ? 's' : '') + ', do you want to exit anyway?';
    }
  }

  close(exit: boolean) {
    this.dialogRef.close(exit);
  }
}
