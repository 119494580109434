import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthorizationService } from '../../../../core/authorization.service';
import { QualityCheck } from '../../../../model/support/quality-check.model';
import { QualityChecks } from '../../../../model/support/review/quality-checks.model';
import { Review } from '../../../../model/support/review/review.model';
import { ReviewService } from '../../../service/review.service';

@Component({
  selector: 'app-feedback-quality',
  templateUrl: './feedback-quality.component.html',
})
export class FeedbackQualityComponent implements OnInit {

  @Input()
  conversationUid: string;
  @Input()
  review: Review;
  displayedColumns: string[] = ['buttonLeft', 'buttonCenter', 'buttonRight'];
  tableDataSource: MatTableDataSource<{ rowLeft: QualityCheck, rowCenter: QualityCheck, rowRight: QualityCheck }>;
  toggleSubject = new Subject<QualityCheck>();
  saving = false;

  constructor(private reviewService: ReviewService, private authorizationService: AuthorizationService) {}

  get disabled() {
    return this.review.closedAt != null || this.authorizationService.isOperator || this.isReviewForCurrentUser();
  }

  isReviewForCurrentUser(): boolean {
    return this.authorizationService.currentUser.name === this.review.servedBy;
  }

  ngOnInit(): void {
    const qualityChecks = JSON.parse(JSON.stringify(this.review.qualityChecks.checks));
    const qualityCheckSplitter: Array<{ rowLeft: QualityCheck, rowCenter: QualityCheck, rowRight: QualityCheck }> = [];
    for (let i = 0; i < qualityChecks.length; i += 3) {
      const split: { rowLeft: QualityCheck, rowCenter: QualityCheck, rowRight: QualityCheck } = {
        rowCenter: null,
        rowLeft: null,
        rowRight: null,
      };
      split.rowLeft = qualityChecks[i];
      if (i + 1 < qualityChecks.length) {
        split.rowCenter = qualityChecks[i + 1];
      }
      if (i + 2 < qualityChecks.length) {
        split.rowRight = qualityChecks[i + 2];
      }
      qualityCheckSplitter.push(split);
    }
    this.tableDataSource = new MatTableDataSource(qualityCheckSplitter);

    this.toggleSubject.pipe(
      debounceTime(1500),
    ).subscribe(() => {
      this.saving = true;
      const selectedChecks = qualityChecks.filter((q) => q.correct !== null);
      this.reviewService.updateQualityChecks(selectedChecks, this.review.id).subscribe((r: QualityChecks) => {
        this.review.qualityChecks.checks = [].concat(r.checks);
        this.saving = false;
      });
    });
  }

  toggleQuality(quality: QualityCheck) {
    if (quality.correct) {
      quality.correct = false;
    } else if (quality.correct === false) {
      quality.correct = null;
    } else {
      quality.correct = true;
    }
    this.toggleSubject.next(quality);
  }
  getQualityValues(quality: QualityCheck): string {
    if (quality) {
      if (quality.correct === true) {
        return 'true';
      } else if (quality.correct === false) {
        return 'false';
      } else {
        return 'none';
      }
    }
  }
}
