import { UserShort } from '../user-short.model';

export class ReviewMessage {

  id: number;
  reviewId: number;
  conversationUid: string;
  responseRequested: boolean;
  message: string;
  messageType: 'CLIENTCOMMENT' | 'CORRECTION' | 'ESCALATE' | 'FAQ' | 'FEEDBACK' | 'OPTIMIZATION' | 'TOPCHAT';
  sentBy?: UserShort;
  visibleToServedBy?: boolean;
  sentAt: Date;
}
