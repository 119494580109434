import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import moment from 'moment';
import { ReplaySubject ,  Subscription } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { Account } from '../../model/account.model';
import { DatePreset } from '../../model/date-preset.enum';
import { DateRange } from '../../model/date-range.model';
import { Domain } from '../../model/domain.model';
import { Roles } from '../../model/roles.enum';
import { TeamDetails } from '../../model/team-details.model';
import { TeamMembership } from '../../model/team-member.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { EnumService } from "../../src/main/typescript/shared/enum.service";

@Component({
  selector: 'app-graph-core-filter',
  templateUrl: './graph-core-filter.component.html',
})
export class GraphCoreFilterComponent implements OnInit, OnDestroy {

  @Input()
  checked: boolean;

  accountSearchControl = new FormControl();

  private static computeLastYear(now: Moment): DateRange {
    const lastYear: Moment = moment(now).subtract(1, 'years');
    const firstDay: Moment = moment(lastYear).startOf('year');
    const lastDay: Moment = moment(lastYear).endOf('year');

    return {start: firstDay, end: lastDay};
  }

  private static computeLastWeek(now: Moment): DateRange {
    const lastWeek: Moment = moment(now).subtract(1, 'weeks');
    const lastMonday: Moment = moment(lastWeek).startOf('week');
    const lastSunday: Moment = moment(lastWeek).endOf('week');

    return {start: lastMonday, end: lastSunday};
  }

  private static computeLastMonth(now: Moment): DateRange {
    const lastMonth: Moment = moment(now).subtract(1, 'months');
    const firstDay: Moment = moment(lastMonth).startOf('month');
    const lastDay: Moment = moment(lastMonth).endOf('month');

    return {start: firstDay, end: lastDay};
  }

  private static computeThisWeek(now: Moment): DateRange {
    const lastMonday: Moment = moment(now).startOf('week');
    const lastSunday: Moment = moment(now).endOf('week');

    return {start: lastMonday, end: lastSunday};
  }

  private static computeThisMonth(now: Moment): DateRange {
    const firstDay: Moment = moment(now).startOf('month');
    const lastDay: Moment = moment(now).endOf('month');

    return {start: firstDay, end: lastDay};
  }

  private static computeThisYear(now: Moment): DateRange {
    const firstDay: Moment = moment(now).startOf('year');
    const lastDay: Moment = moment(now).subtract(1, 'days');

    return {start: firstDay, end: lastDay};
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get isChatRocketPlatformOnly(): boolean {
    return !this.accounts.some((a) => a.accountType !== 'ChatRocket Platform');
  }

  get isLCDOrBetter(): boolean {
    return this.authorizationService.isInAdminGroup;
  }

  get selectedTeam(): TeamDetails {
    return this.coreFilterForm.get('team').value;
  }

  private static compareAlphabetically(a: TeamMembership, b: TeamMembership): number {
    if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
      return -1;
    } else if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }
  channels: string[];

  coreFilterForm: FormGroup;
  accountPlaceholder = 'Account';
  accountTypePlaceholder = 'Account type';
  domainPlaceholder = 'Domain';
  datePresetPlaceholder = 'Period select';
  startDatePlaceholder = 'Start date';
  endDatePlaceholder = 'End date';
  sourceSystemPlaceholder = 'Channel';
  summarizeBy = 'day';
  availableAccounts: Account[];
  teams: TeamDetails[];
  chosenTeam: TeamDetails;
  operators: TeamMembership[] = [];
  accounts: Account[];
  filteredAccounts: ReplaySubject<Account[]> = new ReplaySubject<Account[]>(1);
  allAccounts: Account[];
  accountTypes: string[];
  domains: Domain[];
  accountList: Account[] = [];
  selectedOperators: TeamMembership[] = [];
  completeOperatorList: TeamMembership[] = [];
  readonly datePresets = [
    {
      description: 'Last week',
      id: DatePreset.LastWeek,
    },
    {
      description: 'Last month',
      id: DatePreset.LastMonth,
    },
    {
      description: 'Last year',
      id: DatePreset.LastYear,
    },
    {
      description: 'Week-to-date',
      id: DatePreset.WeekToDate,
    },
    {
      description: 'Month-to-date',
      id: DatePreset.MonthToDate,
    },
    {
      description: 'Year-to-date',
      id: DatePreset.YearToDate,
    },
    {
      description: 'Custom',
      id: DatePreset.Custom,
    },
  ];

  private dateFieldsChanges: Subscription;
  private datePresetChanges: Subscription;
  private accountTypeChanges: Subscription;
  private accountChanges: Subscription;
  private teamChanges: Subscription;
  private operatorChanges: Subscription;

  @ViewChild('accountSelect', {static: false}) accountSelect: MatSelect;
  @ViewChild('allAccountsCheckbox', {static: false}) private allAccountsCheckbox: MatCheckbox;
  @ViewChild('operatorSelect', {static: false}) operatorSelect: MatSelect;
  @ViewChild('allOperatorsCheckbox', {static: false}) private allOperatorsCheckbox: MatCheckbox;

  constructor(
    private formBuilder: FormBuilder,
    private graphService: GraphService,
    private graphFilterService: GraphFilterService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private datePipe: DatePipe,
    private colorService: ColorService,
    private enumService: EnumService,
  ) { }

  ngOnInit(): void {
    this.enumService.getConversationChannels().subscribe(
      (channels: string[]) => {
        const filterValues = ['SMARTSUPP', 'GIOSG'];

        this.channels = channels
          .filter(channel => !filterValues.includes(channel))
          .map(channel => channel.charAt(0).toUpperCase() + channel.slice(1).toLowerCase());
      });

    this.accountSearchControl.valueChanges
      .subscribe(() => {
        this.filterDropdownAccounts();
      });

    this.createForm();
    this.onChanges();
    this.initializeDatePreset();
    this.graphService.getAccounts('DB').subscribe((acc) => {
      this.accounts = acc;
      this.availableAccounts = acc;
      this.filteredAccounts.next(acc.slice());
      this.allAccounts = acc;
      this.accountTypes = Array.from(new Set(this.allAccounts.map((value) => value.accountType).filter((value) => value != null)));
      if (this.accounts.length === 1) {
        this.coreFilterForm.get('accounts').setValue([this.accounts[0]]);
      } else if (this.authorizationService.isClient) {
        this.coreFilterForm.get('accounts').setValue(acc);
      }

      const showHybrids = (this.isChatRocketPlatformOnly && this.isClient); // clients with only CR platform access are true bij default
      this.colorService.toggleColorSet(!showHybrids);
      this.coreFilterForm.get('showHybrids').setValue(showHybrids);

    });
    this.graphService.getTeams().subscribe((teams: TeamDetails[]) => {
      this.teams = teams;
      if (this.teams.length === 1) {
        this.coreFilterForm.get('team').setValue(teams[0]);
      }
      this.graphService.getAllMemberships().subscribe((teamMembers: TeamMembership[]) => {
        this.completeOperatorList = JSON.parse(JSON.stringify(teamMembers));
        this.operators = this.membershipsDistinctByUser(teamMembers);
      });
    });
  }

  filterDropdownAccounts() {
    const search = this.accountSearchControl.value;
    if (!search) {
      this.filteredAccounts.next(this.availableAccounts.slice());
    } else {
      this.filteredAccounts.next(
        this.availableAccounts.filter((a) => a.name.toLowerCase().indexOf(search.toLowerCase()) > -1),
      );
    }
  }

  filterAccounts(accountType: string[]) {
    if (accountType.length > 0) {
      this.filteredAccounts.next(this.allAccounts.filter((account) => accountType.includes(account.accountType)));
    } else {
      this.filteredAccounts.next(this.allAccounts);
    }
  }

  resetTeam(): void {
    this.resetOperator();
    this.coreFilterForm.get('team').reset();
  }

  membershipsDistinctByUser(memberships: TeamMembership[]) {
    const inList = [];
    return memberships.filter((m) => {
      const isInList = inList.indexOf(m.userName) >= 0;
      if (!isInList) {
        inList.push(m.userName);
      }
      return !isInList;
    });
  }

  ngOnDestroy(): void {
    this.accountChanges.unsubscribe();
    this.datePresetChanges.unsubscribe();
    this.dateFieldsChanges.unsubscribe();
    this.teamChanges.unsubscribe();
  }

  onSubmit(): void {
    if (this.router.isActive('dashboard', true)) {
      this.router.navigate(['dashboard/default']);
    }
    if (this.coreFilterForm.valid) {
      const form = this.coreFilterForm.value;
      this.graphFilterService.generate({
        accounts: form.accounts,
        domain: form.domain,
        endDate: form.dateRange.end,
        operators: form.operators,
        showHybrids: form.showHybrids,
        sourceSystem: form.sourceSystem,
        startDate: form.dateRange.start,
        summarizeBy: form.summarizeBy,
        team: form.team,
      });
    }
  }

  resetDomain(): void {
    this.coreFilterForm.get('domain').reset();
  }

  resetSourceSystem(): void {
    this.coreFilterForm.get('sourceSystem').reset();
  }

  operatorSelectable(teamMember: TeamMembership): boolean {
    return !this.selectedTeam || this.isActiveInPeriod(teamMember);
  }

  getMembershipsForUser(userName: string, teamId: number): TeamMembership[] {
    if (this.teams) {
      return this.teams.find((t) => t.id === teamId)
      .teamMemberships
      .filter((c) => c.userName === userName)
      .sort((c) => c.joinDate.valueOf());
    } else {
      return [];
    }
  }

  operatorDatesToolTip(operator: TeamMembership): string {
    if (this.teams) {
      const memberships = this.getMembershipsForUser(operator.userName, operator.teamId);
      let response = '';
      let i = 1;
      for (const m of memberships) {
        if (m.joinDate != null) {
          response = response.concat('joined: ').concat(this.datePipe.transform(m.joinDate, 'dd-MM-yyyy'));
          if (m.leaveDate) {
            response = response.concat(' left: ').concat(this.datePipe.transform(m.leaveDate, 'dd-MM-yyyy'));
          }
        }
        if (i < memberships.length) {
          response = response.concat(' | ');
        }
        i++;
      }
      return response;
    }
  }

  accountToolTip(): string {
    return (this.accountList && this.accountList.length > 0) ?
      this.accountList.map((a: Account) => a.name).join(', ') : '';
  }

  operatorToolTip(): string {
    return (this.selectedOperators.length > 0 ) ?
      this.selectedOperators.map((t: TeamMembership) => t.userName).join(', ') : '';
  }

  clearAccount(event): void {
    this.resetAccount();
    this.allAccountsCheckbox.checked = false;
    this.accountList = [];
    event.stopPropagation();
  }

  clearOperator(event): void {
    this.resetOperator();
    this.selectedOperators = [];
    event.stopPropagation();
  }

  toggleAllAccounts() {
    if (this.allAccountsCheckbox.checked) {
      this.accountSelect.options.forEach((item: MatOption) => {
        if (item.value) {
          item.select();
        }
      });
    } else {
      this.accountSelect.options.forEach((item: MatOption) => {
        if (item.value) {
          item.deselect();
        }
      });
    }
  }

  toggleAllOperators() {
    if (this.allOperatorsCheckbox.checked) {
      this.operatorSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.operatorSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  private createForm(): void {
    this.coreFilterForm = this.formBuilder.group({
      accountType: [''],
      accounts: [''],
      datePreset: [''],
      dateRange: this.formBuilder.group({
        end: ['', Validators.required],
        start: ['', Validators.required],
      }),
      domain: [''],
      operators: [{value: '', disabled: true}],
      showHybrids: [false],
      sourceSystem: [''],
      summarizeBy: ['day', Validators.required],
      team: [{value: undefined, disabled: true}],
    });

    this.authorizationService.hasRole(Roles.TeamLead).subscribe((result: boolean) => {
      if (result) {
        this.coreFilterForm.get('operators').enable();
        this.coreFilterForm.get('team').enable();
      }
    });
  }

  private onChanges(): void {
    this.accountChanges = this.coreFilterForm.get('accounts').valueChanges.subscribe(
      (accounts: Account[]) => {
        if (accounts && accounts.length === 1) {
          this.graphService.getDomains(accounts[0].id).subscribe((dom) => {
            this.domains = dom;
            if (this.domains.length === 1) {
              this.coreFilterForm.get('domain').setValue(this.domains[0]);
            }
          });
          this.resetDomain();
        }
        if (accounts && accounts.length > 1) {
          this.domains = [];
          this.coreFilterForm.get('domain').setValue(null);
          this.resetDomain();
        }
        if (accounts && accounts.length === 0 && this.coreFilterForm.get('domain').value) {
          this.domains = [];
          this.coreFilterForm.get('domain').setValue(null);
          this.resetDomain();
        }

        if (accounts) {
          this.accountList = accounts;
        }

        if (this.allAccountsCheckbox) {
          let filterLength;
          this.filteredAccounts.subscribe((v) => filterLength = v.length);
          this.allAccountsCheckbox.checked = this.accountList.length > 0 && filterLength === this.accountList.length;
        }
      },
    );
    this.datePresetChanges = this.coreFilterForm.get('datePreset').valueChanges.subscribe(
      (datePreset: DatePreset) => {
        this.onDatePresetChange(datePreset);
      },
    );
    this.dateFieldsChanges = this.coreFilterForm.get('dateRange').statusChanges.subscribe(
      () => {
        this.onDateChanges();
      },
    );
    this.coreFilterForm.get('showHybrids').valueChanges.subscribe((value) => {
      this.colorService.toggleColorSet(!value);
    });

    this.teamChanges = this.coreFilterForm.get('team').valueChanges.subscribe(
      (team: TeamDetails) => {
        if (team) {
          this.operators = this.membershipsDistinctByUser(team.teamMemberships);
          this.coreFilterForm.get('operators').reset();
          this.setAccountListByTeam(team);
          this.sortOperatorsByActive();
        } else {
          this.resetAccount();
          this.operators = JSON.parse(JSON.stringify(this.completeOperatorList));
        }
        this.chosenTeam = team;
      },
    );
    this.operatorChanges = this.coreFilterForm.get('operators').valueChanges.subscribe(
      (oper: TeamMembership[]) => {
        if (oper) {
          this.selectedOperators = oper;
          if (oper.length === 1) {
            this.getOperatorChatsFor(oper[0].userId);
          } else {
            this.setAccountListByTeam(this.chosenTeam);
          }

          if (this.allOperatorsCheckbox) {
            this.allOperatorsCheckbox.checked = this.selectedOperators.length > 0 &&
              this.selectedOperators.length === this.operators.length;
          }

        } else {
          this.setAccountListByTeam(this.chosenTeam);
        }
      },
    );
    this.accountTypeChanges = this.coreFilterForm.get('accountType').valueChanges.subscribe(
      (accountType: string[]) => {
        this.filterAccounts(accountType);
        this.coreFilterForm.get('accounts').reset();
      },
    );
  }
  private initializeDatePreset() {
    this.onDatePresetChange(DatePreset.MonthToDate);
    this.coreFilterForm.get('datePreset').setValue(DatePreset.MonthToDate);
  }

  private resetAccount(): void {
    this.resetDomain();
    this.coreFilterForm.get('accounts').reset();
    this.accounts = this.allAccounts;
    this.accountList = [];
  }

  private resetOperator(): void {
    this.coreFilterForm.get('operators').reset();
  }

  private onDateChanges(): void {
    const dateRangeGroup: AbstractControl = this.coreFilterForm.get('dateRange');

    if (dateRangeGroup.dirty) {
      const datePresetField = this.coreFilterForm.get('datePreset');
      datePresetField.reset(DatePreset.Custom);
    }
    this.sortOperatorsByActive();
  }

  private sortOperatorsByActive() {
    if (this.operators) {
      const active = [];
      const inActive = [];
      this.operators.forEach((o) => {
        if (this.operatorSelectable(o)) {
          active.push(o);
        } else {
          inActive.push(o);
        }
      });
      active.sort((a, b) => GraphCoreFilterComponent.compareAlphabetically(a, b));
      inActive.sort((a, b) => GraphCoreFilterComponent.compareAlphabetically(a, b));
      this.operators = active.concat(inActive);
    }
  }

  private isActiveInPeriod(membership: TeamMembership): boolean {
    const dateRangeGroup: AbstractControl = this.coreFilterForm.get('dateRange');
    const start = Date.parse(dateRangeGroup.get('start').value);
    const end = Date.parse(dateRangeGroup.get('end').value);
    const memberships = this.getMembershipsForUser(membership.userName, membership.teamId);
    return memberships.find((m) => {
      const joinedBeforeEnd = new Date(m.joinDate).valueOf() < end;
      const notLeftBeforeStart = (m.leaveDate === null || new Date(m.leaveDate).valueOf() > start);
      return joinedBeforeEnd && notLeftBeforeStart;
    }) !== undefined;
  }

  private onDatePresetChange(datePreset: DatePreset): void {
    const now: Moment = moment();

    switch (datePreset) {
      case DatePreset.LastWeek:
        this.setDateFields(GraphCoreFilterComponent.computeLastWeek(now));
        break;
      case DatePreset.LastMonth:
        this.setDateFields(GraphCoreFilterComponent.computeLastMonth(now));
        break;
      case DatePreset.LastYear:
        this.setDateFields(GraphCoreFilterComponent.computeLastYear(now));
        break;
      case DatePreset.WeekToDate:
        this.setDateFields(GraphCoreFilterComponent.computeThisWeek(now));
        break;
      case DatePreset.MonthToDate:
        this.setDateFields(GraphCoreFilterComponent.computeThisMonth(now));
        break;
      case DatePreset.YearToDate:
        this.setDateFields(GraphCoreFilterComponent.computeThisYear(now));
        break;
      case DatePreset.Custom:
        break;
    }
    this.sortOperatorsByActive();
  }

  private setDateFields(dateRange: DateRange): void {
    const dateRangeGroup: AbstractControl = this.coreFilterForm.get('dateRange');

    dateRangeGroup.reset({
      end: dateRange.end.toISOString(true),
      start: dateRange.start.toISOString(true),
    }, {
      emitEvent: false,
    });
  }

  private setAccountListByTeam(team: TeamDetails): void {
    this.resetAccount();
    let foundAccount: Account;
    const returnAccounts: Account[] = [];
    if (!this.allAccounts || !team) {
      return;
    }
    team.accounts.forEach((a) => {
      foundAccount = this.allAccounts.find((acc) => acc.name === a);
      if (foundAccount) {
        returnAccounts.push({
          accountType: foundAccount.accountType,
          hasHybridChats: foundAccount.hasHybridChats,
          id: foundAccount.id,
          name: foundAccount.name,
        });
      }
    });
    this.accounts = returnAccounts;
  }

  private getOperatorChatsFor(userId: number): void {
    this.graphService.getChatsForList(userId).subscribe((acc: Account[]) => {
      this.accounts = acc;
      if (this.accounts.length === 1) {
        this.coreFilterForm.get('accounts').setValue([this.accounts[0]]);
      }
    });
  }

  get showHybridsToggle(): boolean {
    return this.allAccounts?.some((a) => a.hasHybridChats);
  }

}
