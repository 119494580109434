import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { GraphFilterService } from '../../graph/graph-filter.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { FilterParameters } from '../../model/support/filter-parameters.model';
import { SessionChatInfo } from '../../model/support/session-chat-info.model';
import { SessionInfo } from '../../model/support/session-info.model';
import { AlertifyService } from '../service/alertify.service';
import { ReviewService } from '../service/review.service';
import { SupportService } from '../service/support.service';
import { SupportParametersConsumer } from '../support-core-filter/support-core-front-filter/support-parameters-consumer';
import { SupportFilterService } from '../support-core-filter/support-filter.service';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-chat-archive',
  templateUrl: './chat-archive.component.html',
})

export class ChatArchiveComponent extends SupportParametersConsumer {

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  loading: boolean = false;
  sessionInfoDisplayed: SessionInfo[] = [];
  filterParameters: FilterParameters;
  allIds: string[];
  conversationUid: string;
  reviewCurrent: boolean;
  feedbackCurrent: boolean;
  seeCurrent: boolean;
  viewCurrent: boolean;
  canApply: boolean;
  visitorRating: number;
  sessionsReviewed: string[];
  initialized: boolean = false;
  selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  tableDataLength: number = 0;
  tableDataSource: MatTableDataSource<SessionInfo>;
  displayedColumns: string[] = ['selectBox', 'startDate', 'conversationUid', 'servedBy', 'account', 'classificationCategory',
    'department', 'visitorRating', 'clientRating', 'bconnectRating', 'clientComments', 'markedTopChat',
    'lastClientActivity', 'reviewClosedAt'];
  search: string;
  searchClassifications: string;
  sessionChatInfo: SessionChatInfo;
  datePipe: DatePipe;
  selectedSessionInfo: SessionInfo;

  constructor(supportFilterService: SupportFilterService,
              graphFilterService: GraphFilterService,
              private supportService: SupportService,
              authorizationService: AuthorizationService,
              private alertify: AlertifyService,
              private reviewService: ReviewService,
              datePipe: DatePipe) {
    super(graphFilterService, supportFilterService, authorizationService);
    this.datePipe = datePipe;
  }

  loadData(coreParameters: CoreParameters) {
    if (this.authorizationService.isClient) {
      this.displayedColumns = ['selectBox', 'startDate', 'conversationUid', 'servedBy', 'account', 'classificationCategory',
        'department', 'visitorRating', 'clientRating', 'lastClientActivity'];
    }
    this.loading = true;
    let sessionInfo: Observable<SessionInfo[]>;
    if (this.graphFilterService.showHybridChats) {
      sessionInfo = this.supportService.getHybridChatArchive(coreParameters);
    } else {
      sessionInfo = this.supportService.getChatArchive(coreParameters);
    }
    sessionInfo.subscribe((sessions: SessionInfo[]) => {
      this.tableDataSource = new MatTableDataSource(sessions);
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (sortHeaderId === 'clientComments') {
          return (((data.clientComments != null) && data.clientComments.length > 0) ||
          (data.clientFeedback != null && data.clientFeedback) ||
          data.clientRating > 0) ? 'yes' : 'no';
        }
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
        return data[sortHeaderId];
      };
      this.tableDataSource.paginator = this.paginator;
      this.tableDataLength = sessions.length;
      this.tableDataSource.connect().subscribe((tb) => this.sessionInfoDisplayed = tb);
      this.tableDataSource.filterPredicate = this.filterParameterFilter;
      this.allIds = this.tableDataSource.filteredData.map((x) => x.conversationUid);
      this.initialized = sessions.length > 0;
      this.supportFilterService.coreParameterReturnedData = this.initialized;
      if (this.filterParameters !== undefined) {
        this.filterData(this.filterParameters);
        this.tableDataLength = this.tableDataSource.filteredData.length;
      }
    },
      () => this.loading = false,
      () => {
      this.loading = false;
      if (this.filterParameters !== undefined) {
        this.filterData(this.filterParameters);
      }
    });
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  get hasReview(): boolean {
    return this.reviewService.review != undefined;
  }

  filterData(filterParameters: FilterParameters): void {
    this.unsetConversationId();
    this.filterParameters = filterParameters;
    this.filterParameters.search = this.search;
    this.filterParameters.searchClassifications = this.searchClassifications;
    if (this.tableDataSource !== undefined) {
      if (filterParameters.closedReviews) {
        if (!this.displayedColumns.includes('reviewClosedAt')) {
          this.displayedColumns.push('reviewClosedAt');
        }
      } else {
        this.displayedColumns = this.displayedColumns.filter((col) => col !== 'reviewClosedAt');
      }
      this.tableDataSource.filter = JSON.stringify(filterParameters);
    }
  }

  private filter(): void {
    this.filterData(this.filterParameters);
  }

  applySearchFilter(filterValue: string) {
    this.search = filterValue.trim().toLowerCase();
    this.filter();
  }

  applyClassificationSearchFilter(filterValue: string): void {
    this.searchClassifications = filterValue;
    this.filter();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get singleAccountSelected(): boolean {
    return this.supportFilterService.singleAccountSelected;
  }

  selectable(sessionInfo: SessionInfo): boolean {
    return !sessionInfo.markedTopChat ||
      (sessionInfo.servedBy === this.authorizationService.currentUser.name) ||
      this.authorizationService.isInAdminGroup ||
      this.authorizationService.isTeamLead;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableDataSource.filteredData
        .filter((row) => this.selectable(row))
        .forEach((row) => this.selection.select(row.conversationUid));
  }

  setConversationUid(sessionInfo: SessionInfo) {
    this.selectedSessionInfo = sessionInfo;
    this.conversationUid = sessionInfo.conversationUid;
    this.canApply = !sessionInfo.reviewProcessStates.find((r) => r.key === 'Topchat') && sessionInfo.visitorRating === 10;
    this.viewCurrent = true;
    this.setSessionRelatedData(this.conversationUid);
  }

  unsetConversationId() {
    this.selectedSessionInfo = undefined;
    this.conversationUid = undefined;
    this.setSessionRelatedData(this.conversationUid);
    this.viewCurrent = false;
  }

  setSessionRelatedData(conversationUid: string) {
    this.reviewService.conversationUid = conversationUid;
    if (conversationUid !== undefined) {
      const sessionInfo: SessionInfo = this.tableDataSource.data.find((s) => s.conversationUid === conversationUid);
      const servedByUser: boolean = sessionInfo.servedBy === this.authorizationService.currentUser.name;
      const canFeedback: boolean = this.authorizationService.currentUser.roles.some((r) => r.value >= 30);
      this.reviewCurrent = servedByUser;
      this.feedbackCurrent = !servedByUser && canFeedback;
      this.seeCurrent = !servedByUser && !canFeedback;
    } else {
      this.feedbackCurrent = false;
    }
  }

  /** Checks if the selected session is part of the page in view first, then sorts according to viewed ordering */
  submitForReview() {
    const conversations: string[] = this.selection.selected;
    const page = this.tableDataSource._pageData(this.tableDataSource._orderData(this.tableDataSource.filteredData));
    const conversationsToReview: string[] = page
      .filter((s) => conversations.findIndex((ss) => ss === s.conversationUid) > -1)
      .map((s) => s.conversationUid);
    this.selection.clear();
    conversationsToReview.forEach((s) => this.selection.select(s));

    if (conversationsToReview.length > 0) {
      this.sessionsReviewed = [];
      this.conversationUid = conversationsToReview[0];
      this.sessionsReviewed.push(conversationsToReview[0]);
      this.setSessionRelatedData(this.conversationUid);
    }
  }

  getNextReview($event) {
    if (this.sessionsReviewed.length < this.selection.selected.length) {
      const selectedSessions = this.selection.selected;
      const index = selectedSessions.findIndex((s) => s === this.conversationUid);
      let conversationsForReview: string;
      if (index === 0 && $event === -1) {
        conversationsForReview = selectedSessions[selectedSessions.length - 1];
      } else if (index === (selectedSessions.length - 1) && $event === 1) {
        conversationsForReview = selectedSessions[0];
      } else {
        conversationsForReview = selectedSessions[index + $event];
      }
      if (!this.sessionsReviewed.includes(conversationsForReview)) {
        this.sessionsReviewed.push(conversationsForReview);
      }
      this.conversationUid = conversationsForReview;
      this.setSessionRelatedData(this.conversationUid);
    } else {
      this.unsetConversationUidForReview();
      this.alertify.success('All selected Sessions seen, returning to Archive');
    }
  }

  unsetConversationUidForReview() {
    this.sessionsReviewed.forEach((s) => this.selection.deselect(s));
    this.sessionsReviewed = [];
    this.conversationUid = undefined;
    this.viewCurrent = false;
    this.setSessionRelatedData(this.conversationUid);
  }

  setLoadedSession($event) {
    this.sessionChatInfo = $event;
    this.reviewService.conversationUid = this.sessionChatInfo.sessionInfo.conversationUid;
  }

  getReview() {
    return this.reviewService.review;
  }
}
