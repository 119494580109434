import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { AccountInformation } from '../model/account-information.model';
import { AuthenticationService } from './authentication.service';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  private static extractAccessToken(next: ActivatedRouteSnapshot) {
    const queryParametersToken = next.queryParams.token;
    const pathVariableToken = next.children[0].paramMap.get('token');
    let accessToken;
    if (queryParametersToken != null) {
      accessToken = next.queryParams.token;
    } else if (pathVariableToken != null) {
      accessToken = pathVariableToken;
    }
    return accessToken;
  }

  private static extractRedirectUrl(state: RouterStateSnapshot) {
    const url = state.url.split('?')[0];
    return url.split('/token')[0];
  }

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private router: Router,
              private authorizationService: AuthorizationService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    const accessToken = AuthenticationGuard.extractAccessToken(next);

    if (this.authenticationService.authenticated) {
      return of(true);
    } else if (accessToken != null) {
      const redirectUrl = AuthenticationGuard.extractRedirectUrl(state);
      return this.authenticationService.tokenAuthenticate(accessToken).pipe(
        mapTo(true),
        tap(() => {
          this.router.navigate([redirectUrl], {
            queryParams: {
              token: null,
            },
            queryParamsHandling: 'merge',
          });
        }),
        catchError(() => this.handleAuthenticationFailure(state)),
      );
    } else {
      return this.http.get<AccountInformation>('/api/sessions/info').pipe(
        tap((accountInformation: AccountInformation) => {
          this.authenticationService.accountInformation = accountInformation;
        }),
        catchError((error) => {
          return this.handleAuthenticationFailure(state);
        }),
        mapTo(true),
      );
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> {
    if (this.authorizationService.currentUser && this.authorizationService.currentUser.name === null) {
      this.router.navigate(['/login']);
      return of(false);
    }
    return this.redirectAndReturn(state.url);
  }

  redirectAndReturn(url: string): Observable<boolean> {
    const operator: boolean = this.authorizationService.isOperatorGroup;
    const client: boolean = this.authorizationService.isClient;
    const admin: boolean = this.authorizationService.isAdmin;
    const teamLead: boolean = this.authorizationService.isTeamLead;
    const lcd: boolean = this.authorizationService.isLcd;

    let newUrl: string = url;

    const operatorAccessForbidden = ['/dashboard/default',
      '/dashboard/chats-period-distribution',
      '/dashboard/funnel',
      '/dashboard/path-statistics',
      '/dashboard/chats-period-distribution',
      '/dashboard/ceo-home',
    ];
    const clientAccessForbidden = ['/dashboard/funnel-operator',
      '/dashboard/account-leaderboard',
      '/dashboard/operator-leaderboard',
      '/dashboard/ceo-home',
      '/dashboard/home',
      '/support/overview',
    ];

    if (operator && operatorAccessForbidden.some((found) => found === url)) {
      newUrl = '/dashboard/operator-home';
    }

    if (client && clientAccessForbidden.some((found) => found === url)) {
      newUrl = '/dashboard/default';
    }

    if (client && url === '/support/overview') {
      newUrl = '/support/chatarchive';
    }

    if (operator && !teamLead && url === '/support/overview') {
      newUrl = '/support/review';
    }

    if (admin && '/dashboard/default' === url) {
      newUrl = '/dashboard/ceo-home';
    }

    if (teamLead && '/dashboard/default' === url) {
      newUrl = '/dashboard/teamlead-home';
    }

    if (lcd && '/dashboard/default' === url) {
      newUrl = '/dashboard/lcd-home';
    }
    // Navigate to the newUrl when newUrl != url
    if (newUrl !== url) {
      this.router.navigate([newUrl]);
    }
    return of(true);
  }

  private handleAuthenticationFailure(state: RouterStateSnapshot): Observable<boolean> {
    this.authenticationService.redirectUrl = state.url;
    this.router.navigate(
      ['/login'], {queryParams: {redirect: state.url} });
    return of(false);
  }
}
