import { Component, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { FunnelTrendsRow } from '../funnel/funnel.component';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-funnel-operator',
  styles: [],
  templateUrl: './funnel-operator.component.html',
})
export class FunnelOperatorComponent extends CoreParametersConsumer implements OnDestroy {

  readonly displayedColumns: string[] = [
    'label',
    'total',
    'percentageOfTotalChats',
  ];

  basicTrendsColumns: string[] = this.isOperatorOrTeamLead() ? [
    'label',
    'visitorRating',
    'clientRating',
    'chatsTotal',
  ] : [
    'label',
    'visitorRating',
    'clientRating',
    'visits',
    'chatsTotal',
    'reach',
    'chatsVisitsPerc',
  ];

  numberView: string = 'percentage';
  dataSubscription: Subscription;
  dynamicColumns: string[] = [];
  displayedTrendsColumns: string[];
  dataSource: OperatorFunnelDataRow[];
  trendsDataSource: MatTableDataSource<FunnelTrendsRow>;
  classificationInviteDataSource: OperatorFunnelDataRow[];
  classificationNoInviteDataSource: OperatorFunnelDataRow[];
  errorMessage: string;
  subtitle: string;
  trendsData: FunnelTrendsRow[];
  dataLineChart;
  graphName: string = 'Categories';
  graphType: string = 'classifications';
  chatView: string = 'percentage';

  optionsLineChart = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 0,
        },
      }],
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService,
              private authorizationService: AuthorizationService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters): void {
    if (this.hasRequiredParameters()) {
      this.subtitle = this.graphService.getLabel(coreParameters);
      this.dataSubscription = this.graphService.getOperatorFunnelData(
        coreParameters)
      .subscribe((ds: OperatorFunnelDataWrapper) => {
        if (this.hasInformation(ds.defaultRows)) {
          this.dataSource = ds.defaultRows;
          this.classificationInviteDataSource = ds.classificationInviteRows;
          this.classificationNoInviteDataSource = ds.classificationNoInviteRows;
          this.trendsData = ds.trendRows;
          this.trendsDataSource = new MatTableDataSource<FunnelTrendsRow>(this.setTrends(JSON.parse(JSON.stringify(ds.trendRows))));
          this.setGraph();
        }
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  public setGraphType(type: string, name: string) {
    this.graphType = type;
    this.graphName = name;
    this.setGraph();
  }

  setGraph() {
    if (this.graphType === 'classifications') {
      this.chatsByClassificationLineChart(this.trendsData);
    } else {
      let i;
      const objArray = [];
      const dataCollect = [];
      this.dataLineChart = {};
      this.dataLineChart.options = this.optionsLineChart;
      this.dataLineChart.labels = this.trendsData.map((t) => t.label);
      if (this.graphType === 'chats') {
        i = 0;
        this.trendsData.forEach((t) => {
          dataCollect.push(t.chatsTotal);
        });
      }
      if (this.graphType === 'visitorRating') {
        i = 1;
        this.trendsData.forEach((t) => {
          dataCollect.push(t.visitorRating);
        });
      }
      if (this.graphType === 'visits') {
        i = 2;
        this.trendsData.forEach((t) => {
          dataCollect.push(t.visits);
        });
      }
      const obj = {
        borderColor: this.colorService.getColor(i),
        data: dataCollect,
        fill: false,
        label: this.graphType,
      };
      objArray.push(obj);
      this.dataLineChart.datasets = objArray;
    }
  }

  public setChartType(type: string) {
    this.chatView = type;
    this.chatsByClassificationLineChart(this.trendsData);
  }

  public checkGraphType(type: string) {
    return (this.graphType === type);
  }

  public setNumberType(type: string) {
    this.numberView = type;
  }

  chatsByClassificationLineChart(trendsData: FunnelTrendsRow[]) {
    const objArray = [];
    const classifications = trendsData.length > 0 ? trendsData[0].chatsByClassification.labels : [];
    for (const c of classifications) {
      const ind = classifications.indexOf(c);
      const dataCollect = [];
      trendsData.forEach((t) => {
        let value = t.chatsByClassification.data[ind];
        if (this.chatView === 'percentage') {
          value = (value / t.chatsTotal * 100);
        }
        dataCollect.push(value);
      });
      const obj = {
        borderColor: this.colorService.getColor(ind),
        data: dataCollect,
        fill: false,
        label: c,
      };
      objArray.push(obj);
    }
    this.dataLineChart = {};
    this.dataLineChart.labels = trendsData.map((t) => t.label);
    this.dataLineChart.datasets = objArray;
    this.dataLineChart.options = this.optionsLineChart;
  }

  public getDate(i: number, element: any): string {
    let date: string = 'n/a';
    if (element.chatsByClassification.data[i] !== 0) {
      switch (this.numberView) {
        case 'percentage' : {
          date = (element.chatsByClassification.data[i] / element.chatsTotal * 100).toFixed(2) + '%';
          break;
        }
        case 'number' : {
          date = '' + element.chatsByClassification.data[i];
          break;
        }
      }
    }
    return date;
  }

  isOperatorOrTeamLead() {
    return this.authorizationService.isOperator || this.authorizationService.isTeamLead;
  }

  setTrends(trendsData: FunnelTrendsRow[]): FunnelTrendsRow[] {
    if (trendsData.length > 0) {
      this.dynamicColumns = this.getDynamicColumns(trendsData);
    }
    this.displayedTrendsColumns = this.basicTrendsColumns.concat(this.dynamicColumns);
    return trendsData;
  }

  private getDynamicColumns(funnelTrendsRows: FunnelTrendsRow[]): string[] {
    return funnelTrendsRows[0].chatsByClassification.labels;
  }

  private hasRequiredParameters(): boolean {
    return this.errorMessage == null;
  }

  private hasInformation(rows: OperatorFunnelDataRow[]): boolean {
    let errorMessage: string;
    if (rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
    return this.errorMessage == null;
  }
}

export interface OperatorFunnelDataRow {
  label: string;
  total: number;
  percentageOfTotalChats: number;
}

export interface OperatorFunnelDataWrapper {
  defaultRows: OperatorFunnelDataRow[];
  classificationInviteRows: OperatorFunnelDataRow[];
  classificationNoInviteRows: OperatorFunnelDataRow[];
  trendRows: FunnelTrendsRow[];
}
