import { Component, Input } from '@angular/core';
import { AuthorizationService } from '../core/authorization.service';
import { Roles } from '../model/roles.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent {
  @Input() buttonsDisabled: boolean;

  constructor(public authService: AuthorizationService) {
  }

}
