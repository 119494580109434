import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewMessage } from '../../../model/support/review-message.model';
import { SimplerComment } from '../../../model/support/simpler-comment.model';
import { ReviewService } from '../../service/review.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',

})
export class MessagingComponent {

  @Input()
  type: 'CLIENTCOMMENT' | 'CORRECTION' | 'ESCALATE' | 'FAQ' | 'FEEDBACK' | 'OPTIMIZATION' | 'TOPCHAT';
  @Input()
  conversationUid: string;
  @Input()
  placeholder: string = 'Leave a message';
  @Input()
  disabled: boolean;
  /**
   * Create a new subject in the parent component, share it with this component and call .next() on it to trigger a reload
   * of the messages in the history view.
   */

  @Input()
  historyOnly = false;
  @Output()
  emitMessage = new EventEmitter<ReviewMessage>();
  @Input()
  staticMessages: SimplerComment[];
  @Input()
  reviewMessages: ReviewMessage[];

  constructor(public reviewService: ReviewService) {}

  sendReviewMessage(reviewMessage: ReviewMessage) {
    this.emitMessage.emit(reviewMessage);
  }

}
