import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OpenReview } from '../../model/support/open-review.model';
import { OpenReviewService } from '../service/open-review.service';

@Component({
  selector: 'app-hq-review',
  templateUrl: './hq-review.component.html',
})
export class HqReviewComponent implements OnInit {

  openHqReviews: Observable<OpenReview[]>;

  constructor(private openReviewService: OpenReviewService) { }

  ngOnInit() {
    this.openHqReviews = this.openReviewService.getOpenHqReviews();
  }

}
