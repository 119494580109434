import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SimpleComment } from '../../../model/support/simple-comment.model';

@Component({
  selector: 'app-simple-messaging',
  templateUrl: './simple-messaging.component.html',
  styleUrls: ['./simple-messaging.component.scss']
})
export class SimpleMessagingComponent implements OnDestroy {

  /**
   * The default text seen in the message box
   * @type {string}
   */
  @Input()
  buttonText: string = 'Add remark';
  @Input()
  placeholder: string = 'Add remark';
  @Input()
  messages: SimpleComment[] = [];
  @Output()
  addMessage = new EventEmitter<string>();
  @Input()
  scrollable = true;
  @Input()
  id = uuidv4();
  message = new FormControl('');
  subscription: Subscription;
  @Input()
  disabled: boolean;

  get hasText(): boolean {
    return this.message.value !== null && this.message.value !== '';
  }

  get placeholderText(): string {
    return this.disabled ? `${this.placeholder} (disabled)` : this.placeholder;
  }

  sendComment() {
    this.addMessage.emit(this.message.value);
    this.message.reset();
  }

  hasMessage(): boolean {
    return this.message.value?.length > 0;
  }

  unSubscribeAll(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unSubscribeAll();
  }
}
