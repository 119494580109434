import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamlead-scores',
  templateUrl: './teamlead-scores-component.html',
})
export class TeamleadScoresComponent {
  @Input() title: string;
  @Input() teamScore: number;
  @Input() orgScore: number;
  @Input() prefix: string;
  @Input() postfix: string;
  @Input() decimalFormatter: string;
  @Input() cardClass: string;
  @Input() colorClass: string;
  @Input() noOfCards: number;
}
