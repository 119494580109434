import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/authentication.guard';
import { AccountLeaderboardComponent } from '../graph/account-leaderboard/account-leaderboard.component';
import { AutomatedResponsesComponent } from '../graph/automated-responses/automated-responses.component';
import { CeoHomeComponent } from '../graph/ceo-home/ceo-home.component';
import { ChatsPeriodDistributionComponent } from '../graph/chats-period-distribution/chats-period-distribution.component';
import { ChatsComponent } from '../graph/chats/chats.component';
import { ClientHappinessComponent } from '../graph/client-happiness/client-happiness.component';
import { DefaultComponent } from '../graph/default/default.component';
import { FinanceComponent } from '../graph/finance/finance.component';
import { FunnelOperatorComponent } from '../graph/funnel-operator/funnel-operator.component';
import { FunnelComponent } from '../graph/funnel/funnel.component';
import { GraphComponent } from '../graph/graph.component';
import { OnlineLoggingComponent } from '../graph/online-logging/online-logging.component';
import { OperatorHomeComponent } from '../graph/operator-home/operator-home.component';
import { OperatorLeaderboardComponent } from '../graph/operator-leaderboard/operator-leaderboard.component';
import { PathStatisticsComponent } from '../graph/path-statistics/path-statistics.component';
import { PerformanceComponent } from '../graph/performance/performance.component';
import { TeamleadHomeComponent } from '../graph/teamlead-home/teamlead-home.component';
import { TriggerAnalyticsComponent } from '../graph/trigger-analytics/trigger-analytics.component';
import { VisitorHappinessComponent } from '../graph/visitor-happiness/visitor-happiness.component';
import { WordcloudComponent } from '../graph/wordcloud/wordcloud.component';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        children: [
          {
            component: DefaultComponent,
            path: 'default',
          },
          {
            component: PathStatisticsComponent,
            path: 'path-statistics',
          },
          {
            component: ChatsComponent,
            path: 'chats-byclassification',
          },
          {
            component: WordcloudComponent,
            path: 'word-cloud',
          },
          {
            component: VisitorHappinessComponent,
            path: 'visitor-happiness',
          },
          {
            component: ClientHappinessComponent,
            path: 'client-happiness',
          },
          {
            component: PerformanceComponent,
            path: 'performance',
          },
          {
            component: TriggerAnalyticsComponent,
            path: 'trigger-analytics',
          },
          {
            component: ChatsPeriodDistributionComponent,
            path: 'chats-period-distribution',
          },
          {
            component: FunnelComponent,
            path: 'funnel',
          },
          {
            component: OperatorHomeComponent,
            path: 'operator-home',
          },
          {
            component: CeoHomeComponent,
            path: 'ceo-home',
          },
          {
            component: AccountLeaderboardComponent,
            path: 'account-leaderboard',
          },
          {
            component: TeamleadHomeComponent,
            path: 'teamlead-home',
          },
          {
            component: TeamleadHomeComponent,
            path: 'lcd-home',
          },
          {
            component: OperatorLeaderboardComponent,
            path: 'operator-leaderboard',
          },
          {
            component: FunnelOperatorComponent,
            path: 'funnel-operator',
          },
          {
            component: FinanceComponent,
            path: 'finance',
          },
          {
            component: OnlineLoggingComponent,
            path: 'online-logging',
          },
          {
            component: AutomatedResponsesComponent,
            path: 'automated-responses',
          },
        ],
        component: GraphComponent,
        path: '',
      },
    ],
    component: DashboardComponent,
    path: 'dashboard',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class DashboardRoutingModule {
}
