import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../../core/authorization.service';
import { ReviewActionType } from '../../../../enums/support/review-action-type.enum';
import { ColorService } from '../../../../graph/graph-services/color.service';
import { PendingActions } from '../../../../model/support/pending-actions.model';
import { QualityCheck } from '../../../../model/support/quality-check.model';
import { ReviewMessage } from '../../../../model/support/review-message.model';
import { Review } from '../../../../model/support/review/review.model';
import {
  GenericInputPromptDialogComponent
} from '../../../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import { AlertifyService } from '../../../service/alertify.service';
import { MessageService } from '../../../service/message.service';
import { ReviewService } from '../../../service/review.service';
import { SupportService } from '../../../service/support.service';

@Component({
  selector: 'app-feedback-bconnect-rating',
  templateUrl: './feedback-bconnect-rating.component.html',
})
export class FeedbackBconnectRatingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  review: Review;
  @Input()
  openOnTab: ReviewActionType;
  @Output()
  reviewMessageEvent = new EventEmitter<ReviewMessage>();
  @Input()
  conversationUid: string;
  @Output()
  bconnectRatingEvent = new EventEmitter<number>();

  @Input()
  tabsWithUpdate: string[] = [];
  @Input()
  pendingActions: PendingActions[] = [];
  @Output()
  markAsSeenEvent = new EventEmitter<string>();
  @Input()
  operatorErrorOptions: string[];
  subscriptions: Subscription[] = [];
  qualityChecks: QualityCheck[] = [];
  reviewMessages: ReviewMessage[];

  @Output()
  updateActionsEvent = new EventEmitter<number>();

  @ViewChild('tabGroup', {static: false})
  tabGroup: MatTabGroup;

  constructor(public reviewService: ReviewService,
              private supportService: SupportService,
              private colorService: ColorService,
              private messageService: MessageService,
              public authorizationService: AuthorizationService,
              private alertifyService: AlertifyService,
              private dialog: MatDialog) { }

  get disabled() {
    return this.review.closedAt != null || this.isOperator || this.isReviewForCurrentUser;
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get hideFullReview(): boolean {
    return !this.isReviewed() && (this.isOperator || this.isReviewForCurrentUser);
  }

  get isReviewForCurrentUser(): boolean {
    return this.authorizationService.currentUser.name === this.review.servedBy;
  }

  isSupervisorForReview(): boolean {
    return !this.isReviewForCurrentUser &&
      (this.review.supervisorSet?.teamLead?.userId === this.authorizationService.currentUser.id ||
        (this.authorizationService.isInAdminGroup && this.review.currentUserSubscribed));
  }

  get topChatAvailable(): boolean {
    const checks = this.review.qualityChecks.checks !== null ?
      this.review.qualityChecks.checks.filter((q) => q.correct !== null) : [];
    const rating = this.review.bconnectRating;
    const errors = this.review.operatorErrors;
    return this.review.topChat !== null || ((checks != null &&
      !checks.some((q) => !q.correct) &&
      checks.some((q) => q.correct)) &&
      (rating != null && rating.rating === 10) &&
      (errors != null && errors.errors.length === 0)) ||
      this.isReviewForCurrentUser;
  }

  ngOnInit() {
    this.messageService.getMessagesByConversationUid(this.conversationUid, 'Feedback').subscribe((messages) => {
      this.reviewMessages = messages;
    });
  }

  ngAfterViewInit() {
    // find tab index of optional openOnTab value
    const position = this.tabGroup._tabs.find(t => t.textLabel === this.openOnTab)?.position;
    if (position) {
      this.tabGroup.selectedIndex = position;
    }
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  sendReviewMessage(reviewMessage: ReviewMessage) {
    this.messageService.sendReviewMessage(reviewMessage).subscribe((result) => {
      this.reviewMessages.unshift(result);
      this.reviewMessageEvent.emit(result);
    });
  }

  resolveFeedback() {
    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: 'Please leave a comment before resolving',
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed()
      .subscribe((result) => {

        if (result?.message) {
          this.reviewService.resolveMessageFlow(result.message, this.review.id, 'FEEDBACK').subscribe((response) => {
            this.alertifyService[response.alert.type](response.alert.message);
            this.review.feedbackFlowResolvedAt = response.body.sentAt;
            this.reviewMessages.unshift(response.body);
            this.updateActionsEvent.emit(this.review.id);
            const pa = this.pendingActions.find(p => p.userId === this.authorizationService.currentUser.id);
            pa.pendingActions = pa.pendingActions.filter(p => p !== 'FEEDBACK');
          });
        }

    });
  }

  markAsSeen(actionType: string) {
    this.supportService.clearActionsByType(actionType, this.review.id).subscribe((alert) => {
      this.alertifyService[alert.type](alert.message);
      this.updateActionsEvent.emit(this.review.id);
    });

  }

  isReviewed(): boolean {
    return this.review.reviewed;
  }

  hasUpdate(section: string): boolean {
    return this.tabsWithUpdate.some((t) => t === section);
  }

  hasAction(section: string): boolean {
    return this.pendingActions?.find(pa =>
      pa.userId === this.authorizationService.currentUser.id)?.pendingActions.some(pa => pa === section);
  }

  public setBconnectRating(rating: number) {
    this.review.bconnectRating.rating = rating;
    this.subscriptions.push(this.reviewService.updateBconnectRating(rating, this.review.id).subscribe(() => {
        this.bconnectRatingEvent.emit(rating);
      }
    ));
  }

  public happinessColor(n: number): string {
    if (this.review.bconnectRating.rating === n) {
      return this.colorService.getColorByGrade(n);
    } else {
      return 'gray';
    }
  }
}
