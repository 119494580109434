import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { FaqAdditionQna } from '../../../../../model/support/review/faq-addition-qna.model';
import { FaqAdditionTag } from '../../../../../model/support/review/faq-addition-tag.model';
import { FaqAddition } from '../../../../../model/support/review/faq-addition.model';
import { Review } from '../../../../../model/support/review/review.model';
import { ReviewService } from '../../../../service/review.service';
import { FeedbackService } from '../../../feedback.service';

@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.scss']
})

export class FaqDialogComponent implements OnInit, OnDestroy {

  _faqAddition: FaqAddition;
  @Input()
  canEdit: boolean;
  pristine: boolean = true;
  tags: string[] = [];
  title = 'FAQ editor';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  @Input()
  products: Observable<string[]>;
  @Input()
  review: Review;

  productSearchControl = new FormControl();
  pipeForcedAt: Date;

  productFilterFn = (product: string) => {
    const searchTerm: string = this.productSearchControl.value;
    return !searchTerm || searchTerm.length === 0 || product?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1;
  }

  @Output()
  closedEvent = new EventEmitter<{action: string, faq?: FaqAddition}>();

  componentDestroyed$ = new Subject<void>();

  feedbackService = FeedbackService;

  constructor(private authorizationService: AuthorizationService,
              private reviewService: ReviewService) { }

  @Input()
  set faqAddition(faqAddition: FaqAddition) {
    if (!!faqAddition) {
      this._faqAddition = JSON.parse(JSON.stringify(faqAddition));
      if (faqAddition.tags != null) {
        this.tags = faqAddition.tags.split(',');
      }

      if (faqAddition.faqAdditionProcessStage === 'TO_CLIENT') {
        // disabling editing by default in stage TO_CLIENT, is overridable
        this.canEdit = false;
      }
    } else {
      this._faqAddition = undefined;
    }
  }

  ngOnInit() {
    this.productSearchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe(t => {
        this.pipeForcedAt = new Date();
      });
  }

  mayEnableEditing(): boolean {
    return this.isLCDorHigher() && !this.canEdit && this._faqAddition.faqAdditionProcessStage !== 'ACCEPTED';
  }

  enableEditing() {
    if (this._faqAddition.faqAdditionProcessStage !== 'ACCEPTED') {
      this.canEdit = true;
    }
  }

  onChange() {
    this.pristine = false;
  }

  saved($event) {
    this.closedEvent.emit({faq: $event, action: 'saved'});
  }

  deleted() {
    this.closedEvent.emit({action: 'deleted'});
  }

  close() {
    this.closedEvent.emit({action: 'closed'});
  }

  addComment(comment: string) {
    this.reviewService.addCommentToFaqAddition(this._faqAddition.id, this.review.id, comment)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(response => {
        this._faqAddition.comments = response.comments;
        this._faqAddition.comments = response.comments; // update comments in source faqAddition since they are already saved
    });
  }

  add(event: MatChipInputEvent, qna: FaqAdditionQna): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {

      if (!qna.tags) {
        qna.tags = [];
      }

      qna.tags.push({id: undefined, tag: value});
    }

    if (input) {
      input.value = '';
    }
  }

  remove(tag: FaqAdditionTag, qna: FaqAdditionQna): void {
    qna.tags.splice(qna.tags.indexOf(tag), 1);
  }

  isLCDorHigher() {
    return this.authorizationService.isInAdminGroup;
  }

  get isClient() {
    return this.authorizationService.isClient;
  }

  get isOperator() {
    return this.authorizationService.isOperator;
  }

  get stageText(): string {
    let text = '';

    switch (this._faqAddition.faqAdditionProcessStage) {
      case 'CREATED' : {
        text = 'This faq addition is new';
        break;
      }
      case 'TO_SUPERVISOR_SUPPORT' : {
        text = 'This faq addition has been submitted for review';
        break;
      }
      case 'TO_CLIENT' : {
        const recipient = this.isClient ? 'you' : 'the client';
        text = `This faq addition has been sent to ${recipient} for review.`;
        break;
      }
      case 'ACCEPTED_BY_CLIENT' : {
        const recipient = this.isClient ? 'you' : 'the client';
        text = `This faq addition has been accepted by ${recipient}.`;
        break;
      }
      case 'REJECTED_BY_CLIENT' : {
        const recipient = this.isClient ? 'you' : 'the client';
        text = `This faq addition has been rejected by ${recipient}.`;
        break;
      }
      case 'ACCEPTED' : {
        text = 'This faq addition has been accepted';
        break;
      }
      case 'CANCELED' : {
        text = 'This faq addition has been canceled';
        break;
      }
    }
    return text;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

}
