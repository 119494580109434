import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable ,  Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '../../core/authorization.service';
import { KeyValuePair } from '../../model/key-value-pair';
import { Review } from '../../model/support/review/review.model';
import { SessionInfo } from '../../model/support/session-info.model';
import { AlertifyService } from '../service/alertify.service';
import { ReviewService } from '../service/review.service';
import { SupportService } from '../service/support.service';
import { ReviewDialogComponent } from './review-dialog/review-dialog.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
})
export class ReviewComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(ReviewComponent, {static: false}) vcRef: ViewContainerRef;

  tableDataLength: number;
  displayedColumns: string[] = ['startDate',
    'visitorRating', 'clientRating', 'conversationUid', 'account', 'classificationCategory'];
  tableDataSource: MatTableDataSource<SessionInfo>;
  conversationUid: string;
  subscriptionList: Subscription[] = [];
  remainingReviewsFor: Array<KeyValuePair<number, boolean>>;

  constructor(private reviewService: ReviewService, private alertify: AlertifyService,
              private supportService: SupportService,
              private authorizationService: AuthorizationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.updateReviewTable();
  }

  updateReviewTable() {
    this.getReviews();
    if (this.isTeamLead) {
      this.displayedColumns.push('servedBy');
    }
  }

  getReviews() {
    this.subscriptionList.push(this.supportService.getReviewQueue().subscribe((sessionInfo: SessionInfo[]) => {
      this.tableDataSource = new MatTableDataSource(sessionInfo);
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
        return data[sortHeaderId];
      };
      this.tableDataSource.paginator = this.paginator;
      this.tableDataLength = sessionInfo.length;
      this.remainingReviewsFor = this.tableDataSource.data.map((s) => {
        const kv = new KeyValuePair<number, boolean>();
        kv.key = s.conversationUid;
        kv.value = false;
        return kv;
      });
    }));
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  setConversationUid(conversationUid: string) {
    this.conversationUid = conversationUid;
    this.reviewService.conversationUid = conversationUid;
  }

  unsetConversationUid() {
    this.openExitDialog().subscribe((exit) => {
      if (exit) {
       this.tableDataSource.data = this.tableDataSource.data.filter((t) =>
         this.remainingReviewsFor.some((r) => r.key === t.conversationUid && !r.value));
       this.reviewService.conversationUid = undefined;
      }
    });
  }

  getNextReview($event) {
    if (this.tableDataSource.data.length > this.remainingReviewsFor.filter((r) => r.value === true).length) {
      const data = this.tableDataSource.data;
      const index = data.findIndex((s) => s.conversationUid === this.conversationUid);
      if (index === 0 && $event === -1) {
        this.setConversationUid(data[data.length - 1].conversationUid);
      } else if (index === (data.length - 1) && $event === 1) {
        this.setConversationUid(data[0].conversationUid);
      } else {
        this.setConversationUid(this.tableDataSource.data[index + $event].conversationUid);
      }
    } else {
      this.unsetConversationUid();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((s) => s.unsubscribe());
  }

  openExitDialog(): Observable<boolean> {
    return this.dialog.open(ReviewDialogComponent, {
      data: this.remainingReviewsFor,
    }).afterClosed().pipe(
      map((exit) => {
        if (!exit) {
          return false;
        } else {
          this.conversationUid = undefined;
          return exit;
        }
      }),
    );
  }

  get hasData(): boolean {
    return this.tableDataLength != null && this.tableDataLength > 0;
  }

  getReview(): Review {
    return this.reviewService.review;
  }

  processReviewMessageEvent() {
    this.remainingReviewsFor.map((r) => {
      if (r.key === this.conversationUid) {
        r.value = true;
      }
    });
  }
}
