import { Component, Input, OnInit } from '@angular/core';
import { SimplerComment } from '../../../model/support/simpler-comment.model';

@Component({
  selector: 'app-open-review-messaging',
  templateUrl: './open-review-messaging.component.html',
})
export class OpenReviewMessagingComponent implements OnInit {

  @Input()
  staticMessages: SimplerComment[];

  constructor() { }

  ngOnInit() {
  }

}
