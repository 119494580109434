import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-small-button',
  templateUrl: './small-button.component.html',
  styleUrls: ['./small-button.component.scss']
})
export class SmallButtonComponent {

  _color: string = '#000066';
  @Input()
  backgroundColor: string = '#000066';
  disabledColor: string = 'rgba(168,168,168,0.62)';
  @Input()
  label: string;
  @Input()
  title: string;
  @Input()
  icon: string;
  @Output()
  onClick = new EventEmitter();
  @Input()
  disabled: boolean = false;
  @Input()
  bold = false;
  border: string = '1px solid #000066';

  @Input()
  set color(color: string) {
    this._color = color;
    this.border = '1px solid ' + color;
  }

  click($event) {
    if (!this.disabled) {
      this.onClick.emit($event);
    }
  }

}
