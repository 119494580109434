import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject } from 'rxjs';
import { AuthorizationService } from '../core/authorization.service';
import { CoreParameters } from '../model/core-parameters.model';
import { GraphService } from './graph.service';

/**
 * Created by hmderek on 7-3-18.
 */

@Injectable()
export class GraphFilterService {
  coreParameters$: Observable<CoreParameters>;
  showHybridChats = false;

  private readonly replayBufferSize = 1;
  private coreParametersSource = new ReplaySubject<CoreParameters>(this.replayBufferSize);

  constructor(private graphService: GraphService,
              private authorizationService: AuthorizationService) {
    this.coreParameters$ = this.coreParametersSource.asObservable();
  }

  listen(): Observable<CoreParameters> {
    return this.coreParameters$;
  }

  generate(coreParameters: CoreParameters) {
    if (this.authorizationService.isClient &&
      (coreParameters.accounts === null || coreParameters.accounts.length === 0)) {
      this.graphService.getAccounts('DB').subscribe((a) => {
        coreParameters.accounts = a;
        this.coreParametersSource.next(coreParameters);
      });
    } else {
      this.coreParametersSource.next(coreParameters);
    }
  }
}
