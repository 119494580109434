import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AuthorizationService } from '../../core/authorization.service';
import { ColorMeaning } from '../../model/color-meaning.enum';
import { CoreParameters } from '../../model/core-parameters.model';
import { LeaderBoard } from '../../model/leader-board.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-account-leaderboard',
  templateUrl: './account-leaderboard.component.html',
})
export class AccountLeaderboardComponent extends CoreParametersConsumer {
  readonly defaultColumns1: string[] = [
    'account',
    'totalScore',
    'chats',
    'visits',
    'percentageOfVisits',
    'percentageOfInvites',
    'percentageOfChats'
  ];

  barOptions = {
    gridLines: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: false,
          callback: (value, index, values) => {
            return value.length > 12 ? value.substring(0, 5) + '..' + value.substring(value.length - 5) : value;
          },
        },
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          suggestedMax: 0,
          suggestedMin: 0,
        },
      }],
    },
    tooltips: {
      callbacks: {
        title: (tooltipItems, data) => {
          return data.labels[tooltipItems[0].index];
        },
      },
      enabled: true,
      mode: 'label',
    },
  };

  clientScoreDataTop: GraphDataSet;
  visitorDataTop: GraphDataSet;
  clientScoreDataBottom: GraphDataSet;
  visitorDataBottom: GraphDataSet;
  dataBarChartTop: GraphDataSet;
  dataBarChartBottom: GraphDataSet;
  numberChatsDataTop: GraphDataSet;
  numberChatsDataBottom: GraphDataSet;

  leaderBoards: LeaderBoard[];
  chatDistribution: Array<{ label: string, data: LeaderBoardWrapper }>;
  chatDistributionDataTop: any;
  chatDistributionDataBottom: any;
  selectedDistributionGraph: string;

  displayedColumns: string[] = [];
  dynamicColumns: string[] = [];
  errorMessage: string;
  numberView: string = 'percentage';
  selectedGraph: string = 'chatDistribution';
  data$: Observable<LeaderBoard[]>;
  dataTable$: Observable<MatTableDataSource<LeaderBoard>>;

  subtitle: string;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private authorizationService: AuthorizationService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  get initialized(): boolean {
    return !!this.data$;
  }

  showTotalPercentage(index: number, element: LeaderBoard): boolean {
    return this.numberView === 'percentage' && element.classifications.data[index].second != null &&
      this.leaderBoards.length > 1;
  }

  getTotalPercentage(index: number, element: LeaderBoard): number {
    return ((element.classifications.data[index].second / element.chatsTotalUnfiltered) * 100);
  }

  getTotalPercentageIconClass(index: number, element: LeaderBoard): string {
    const firstPerc: number = element.classifications.dataPercentage[index];
    const secondPerc: number = this.getTotalPercentage(index, element);
    const difference = firstPerc - secondPerc;
    if (difference < -15) {
      return 'fas fa-angle-double-down red';
    } else if (difference < 0) {
      return 'fas fa-angle-down red';
    } else if (difference === 0) {
      return 'fas fa-minus';
    } else if (difference > 15) {
      return 'fas fa-angle-double-up green';
    } else {
      return 'fas fa-angle-up green';
    }
  }

  generateBarChartData(data: Array<{ data: number, accounts: string }>, label: string,
                       color: ColorMeaning): GraphDataSet {
    if (data != null) {
      return {
        datasets: [{
          backgroundColor: this.colorService.getColorByMeaning(color),
          data: data.map((x) => x.data),
          label,
        }],
        labels: data.map((x) => x.accounts),
      };
    } else {
      return null;
    }
  }

  loadData(coreParameters: CoreParameters): void {
    this.subtitle = this.graphService.getLabel(coreParameters);

    this.data$ = this.graphService.getAccountLeaderBoardData(
      coreParameters).pipe(
      share(),
    );
    this.dataTable$ = this.data$.pipe(
      map((leaderBoard: LeaderBoard[]) => {
          this.leaderBoards = leaderBoard;

          this.checkRequiredParameters(leaderBoard);
          if (!this.errorMessage) {
            this.generateDataPercentage(leaderBoard);
            let graphData: LeaderBoardWrapper;
            graphData = this.generateTopAndBottomTen(leaderBoard, 'visitorScore');
            this.visitorDataTop = this.generateBarChartData(graphData.top, 'Visitor Scores', ColorMeaning.GOOD);
            this.visitorDataBottom = this.generateBarChartData(graphData.bottom,
              'Visitor Scores', ColorMeaning.NEUTRAL);

            graphData = this.generateTopAndBottomTen(leaderBoard, 'chats');
            this.numberChatsDataTop = this.generateBarChartData(graphData.top, 'Chats', ColorMeaning.GOOD);
            this.numberChatsDataBottom = this.generateBarChartData(graphData.bottom,
              'Chats', ColorMeaning.NEUTRAL);

            graphData = this.generateTopAndBottomTen(leaderBoard, 'clientScore');
            this.clientScoreDataTop = this.generateBarChartData(graphData.top, 'Client Scores', ColorMeaning.GOOD);
            this.clientScoreDataBottom = this.generateBarChartData(graphData.bottom,
              'Client Scores', ColorMeaning.NEUTRAL);

            const dataSource = new MatTableDataSource(leaderBoard);
            if (leaderBoard.length > 0) {
              this.dynamicColumns = leaderBoard[leaderBoard.length - 1].classifications.labels;
            }
            this.displayedColumns = this.defaultColumns1.concat(this.dynamicColumns);
            this.chatDistribution = this.generateClassificationData(leaderBoard);

            this.setBarGraph(this.selectedGraph, this.selectedDistributionGraph);

            dataSource.sort = this.sort;

            dataSource.sortingDataAccessor = (data: LeaderBoard, header: string): string | number => {
              const index = this.dynamicColumns.indexOf(header);
              if (index >= 0) {
                if (this.numberView === 'percentage') {
                  {
                    return data.classifications.dataPercentage[index];
                  }
                } else {
                  {
                    return data.classifications.data[index].first;
                  }
                }
              } else {
                let date = data[header];
                if (typeof date === 'string') {
                  date = date.toLowerCase();
                }
                return date;
              }
            };
            return dataSource;
          } else {
            return null;
          }
        },
      ),
    );
  }

  public setNumberType(type: string) {
    this.numberView = type;
  }

  public getDynamicData(i: number, element: any): string {
    let data: string = 'n/a';
    if (element.classifications.data[i] !== 0) {
      switch (this.numberView) {
        case 'percentage' : {
          data = element.classifications.dataPercentage[i].toFixed(2) + '%';
          break;
        }
        case 'number' : {
          data = element.classifications.data[i].first;
          break;
        }
      }
    }
    return data;
  }

  public getPercentageIconClass(first: number, second: number) {
    const difference = first - second;
    if (difference < -15) {
      return 'fas fa-angle-double-down red';
    } else if (difference < 0) {
      return 'fas fa-angle-down red';
    } else if (difference === 0) {
      return 'fas fa-minus';
    } else if (difference > 15) {
      return 'fas fa-angle-double-up green';
    } else {
      return 'fas fa-angle-up green';
    }
  }

  private checkRequiredParameters(rows: LeaderBoard[]) {
    let errorMessage: string;
    if (rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
  }

  private setBarGraph(graph: string, subGraph: string) {
    this.selectedGraph = graph;
    switch (graph) {
      case 'visitorScore': {
        this.barOptions.scales.yAxes[0].ticks.suggestedMax = 10;
        this.dataBarChartTop = this.visitorDataTop;
        this.dataBarChartBottom = this.visitorDataBottom;
        break;
      }
      case 'clientScore': {
        this.barOptions.scales.yAxes[0].ticks.suggestedMax = 10;
        this.dataBarChartTop = this.clientScoreDataTop;
        this.dataBarChartBottom = this.clientScoreDataBottom;
        break;
      }
      case 'chats': {
        if (!!this.numberChatsDataBottom) {
          this.barOptions.scales.yAxes[0].ticks.suggestedMax = this.numberChatsDataBottom.datasets[0].data[0] * 2;
        } else {
          this.barOptions.scales.yAxes[0].ticks.suggestedMax = 0;
        }
        this.dataBarChartTop = this.numberChatsDataTop;
        this.dataBarChartBottom = this.numberChatsDataBottom;
        break;
      }
      case 'chatDistribution': {
        this.barOptions.scales.yAxes[0].ticks.suggestedMax = 100;
        this.setChatDistributionGraph(subGraph);
        this.dataBarChartTop = this.chatDistributionDataTop;
        this.dataBarChartBottom = this.chatDistributionDataBottom;
        break;
      }
    }
  }

  private generateDataPercentage(leaderBoards: LeaderBoard[]) {
    leaderBoards.forEach((leaderBoard) => {
      const dataPercentage: number[] = [];
      const total = leaderBoard.chats;
      leaderBoard.classifications.data.forEach((data) => {
          dataPercentage.push((data.first / total) * 100);
        },
      );
      leaderBoard.classifications.dataPercentage = dataPercentage;
    });
  }

  private generateTopAndBottomTen(leaderBoards: LeaderBoard[], info: string): LeaderBoardWrapper {
    let dataLeast: Array<{ data: number, accounts: string }>;
    let dataMost: Array<{ data: number, accounts: string }>;
    dataMost = [];
    dataLeast = [];

    leaderBoards.forEach((leaderbord) => {
      if (leaderbord[info] !== 0) {
        dataLeast.push({data: leaderbord[info], accounts: leaderbord.account});
      }
    });
    dataLeast.sort((a, b) => b.data - a.data);
    if (dataLeast.length > 20) {
      dataMost = dataLeast.slice();
      dataLeast.splice(0, dataLeast.length - 10);
      dataMost.splice(10);
    } else {
      dataMost = dataLeast;
      dataLeast = null;
    }
    return {top: dataMost, bottom: dataLeast};
  }

  private setChatDistributionGraph(header: string) {
    if (header === undefined) {
      header = 'Lead';
    }
    this.selectedDistributionGraph = header;
    let data: LeaderBoardWrapper;
    data = this.chatDistribution.find((wrapper) => wrapper.label === header).data;
    this.chatDistributionDataTop = this.generateBarChartData(data.top
      , 'Percent ' + header, ColorMeaning.GOOD);
    this.chatDistributionDataBottom = this.generateBarChartData(data.bottom,
      'Percent ' + header, ColorMeaning.NEUTRAL);
  }

  private generateClassificationData(leaderBoards: LeaderBoard[]): Array<{ label: string, data: LeaderBoardWrapper }> {
    let labeledData: Array<{ label: string, data: LeaderBoardWrapper }>;
    labeledData = [];
    let dataTop10: Array<{ data: number, accounts: string }> = [];
    this.dynamicColumns.forEach((label) => {
      let data: Array<{ data: number; accounts: string }> = [];
      leaderBoards.forEach((leaderboard) => {
        const index = leaderboard.classifications.labels.indexOf(label);
        let date: { data: number, accounts: string };
        date = {data: leaderboard.classifications.dataPercentage[index], accounts: leaderboard.account};
        if (date.data !== 0) {
          data.push(date);
        }
      });
      if (label === 'Lead' || label === 'Service') {
        data.sort((a, b) => b.data - a.data);
      } else {
        data.sort((a, b) => a.data - b.data);
      }
      if (data.length > 20) {
        dataTop10 = data.slice();
        data.splice(0, data.length - 10);
        dataTop10.splice(10);
      } else {
        dataTop10 = data;
        data = null;
      }
      labeledData.push({data: {top: dataTop10, bottom: data}, label});
    });
    return labeledData;
  }
}

export interface GraphDataSet {
  datasets: Array<{ backgroundColor: string, data: number[], label: string }>;
  labels: string[];
}

export interface LeaderBoardWrapper {
  top: Array<{ data: number, accounts: string }>;
  bottom: Array<{ data: number, accounts: string }>;
}
