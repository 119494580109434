import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import initHelpHero from 'helphero';
import { AuthenticationService } from '../core/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  public constructor(
    private titleService: Title,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Analytics');
    const hlp = initHelpHero('zu6az4qMtMs');
    hlp.identify(this.authenticationService.accountInformation.id);
    hlp.startTour('iK6qACm870N', { skipIfAlreadySeen: true });
  }
}
