import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { OperatorScoreRowsWrapper } from '../../model/operator-score-rows-wrapper-model';
import { OperatorScoreRow } from '../../model/operator-score-rows.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-operator-leaderboard',
  templateUrl: './operator-leaderboard.component.html',
  styleUrls: ['./operator-leaderboard.component.scss'],
})
export class OperatorLeaderboardComponent extends CoreParametersConsumer implements OnDestroy {
  subtitle: string;

  readonly defaultColumns1: string[] = [
    'name',
    'combinedRating',
    'visitorRating',
    'clientRating',
    'sessions',
  ];
  readonly defaultColumns2: string[] = [
    'averagePickupTime',
    'averageResponseTime',
    'duration',
    'euroPerHour',
    'chatsPerHour',
    'workLoad',
  ];

  options = {
    legend: {
      position: 'left',
    },

  };

  barOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 0,
        },
      }],
    },
  };

  displayedColumns: string[] = [];
  dynamicColumns: string[] = [];
  dataSubscription: Subscription;
  operatorDataSource: MatTableDataSource<OperatorScoreRow>;
  teamLeadDataSource: MatTableDataSource<OperatorScoreRow>;
  teamDataSource: MatTableDataSource<OperatorScoreRow>;
  organizationDataSource: MatTableDataSource<OperatorScoreRow>;
  lcdDataSource: MatTableDataSource<OperatorScoreRow>;
  selectedGraph: string = 'leads';
  radarSource: any;
  barSource: any;
  leadSource: any;
  ratingSource: any;
  timeSource: any;
  teamFilter: string;
  errorMessage: string;
  numberView: string = 'percentage';

  @ViewChild('teamSort', {static: false}) teamSort: MatSort;
  @ViewChild('lcdSort', {static: false}) lcdSort: MatSort;
  @ViewChild('operatorSort', {static: false}) operatorSort: MatSort;
  @ViewChild('teamleadSort', {static: false}) teamleadSort: MatSort;

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private authorizationService: AuthorizationService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  setGraphType(graph: string): void {
    this.selectedGraph = graph;
    switch (graph) {
      case 'leads' : {
        this.barSource = this.leadSource;
        this.subtitle = 'Leads per operator';
        break;
      }
      case 'rating' : {
        this.barSource = this.ratingSource;
        this.subtitle = 'Average combined rating per operator';
        break;
      }
      case 'time' : {
        this.barSource = this.timeSource;
        this.subtitle = 'Average times per operator';
        break;
      }
    }
  }

  loadData(coreParameters: CoreParameters): void {
    this.teamFilter = this.graphService.getLabel(coreParameters);
    // if (this.hasRequiredParameters(coreParameters)) {
    this.dataSubscription = this.graphService.getOperatorLeaderBoardData(coreParameters)
      .subscribe((operatorScoreRows: OperatorScoreRowsWrapper) => {
        if (
          this.hasInformation(operatorScoreRows.operators) ||
          this.hasInformation(operatorScoreRows.teamLeads) ||
          this.hasInformation(operatorScoreRows.teams) ||
          this.hasInformation(operatorScoreRows.lcd) ||
          this.hasInformation(operatorScoreRows.organization)
        ) {
          const combinedRows: string[] = this.getDynamicColumns(operatorScoreRows.operators);
          this.generateDataPercentage(operatorScoreRows.operators);
          this.operatorDataSource = new MatTableDataSource(operatorScoreRows.operators);
          this.operatorDataSource.sort = this.operatorSort;
          this.operatorDataSource.sortData = (data: OperatorScoreRow[], sort: MatSort) => {
            return data.sort((a: OperatorScoreRow, b: OperatorScoreRow) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              } else {
                return 0;
              }
            });
          };
          if (operatorScoreRows.operators.length > 0) {
            this.dynamicColumns = combinedRows;
          }

          if (this.hasInformation(operatorScoreRows.teamLeads)) {
            this.generateDataPercentage(operatorScoreRows.teamLeads);
            this.teamLeadDataSource = new MatTableDataSource(operatorScoreRows.teamLeads);
            this.teamLeadDataSource.sort = this.teamleadSort;
          } else {
            this.teamLeadDataSource = undefined;
          }

          if (this.hasInformation(operatorScoreRows.teams)) {
            this.generateDataPercentage(operatorScoreRows.teams);
            this.teamDataSource = new MatTableDataSource(operatorScoreRows.teams);
            this.teamDataSource.sort = this.teamSort;
          } else {
            this.teamDataSource = undefined;
          }

          if (this.hasInformation(operatorScoreRows.lcd)) {
            this.generateDataPercentage(operatorScoreRows.lcd);
            this.lcdDataSource = new MatTableDataSource(operatorScoreRows.lcd);
            this.lcdDataSource.sort = this.lcdSort;
          } else {
            this.lcdDataSource = undefined;
          }

          if (this.hasInformation(operatorScoreRows.organization)) {
            this.generateDataPercentage(operatorScoreRows.organization);
            this.organizationDataSource = new MatTableDataSource(operatorScoreRows.organization);
          } else {
            this.organizationDataSource = undefined;
          }

          this.displayedColumns = this.defaultColumns1.concat(this.dynamicColumns).concat(this.defaultColumns2);
          this.operatorDataSource.sortingDataAccessor = (data: OperatorScoreRow, header: string): string | number => {
            switch (this.numberView) {
              case 'percentage': {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.classifications.data[this.dynamicColumns.indexOf(header)] / data.sessions;
                } else {
                  return data[header];
                }
              }
              default: {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.classifications.data[this.dynamicColumns.indexOf(header)];
                } else {
                  return data[header];
                }
              }
            }
          };

          this.teamDataSource.sortingDataAccessor = (data: OperatorScoreRow, header: string): string | number => {
            switch (this.numberView) {
              case 'percentage': {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.classifications.data[this.dynamicColumns.indexOf(header)] / data.sessions;
                } else {
                  return data[header];
                }
              }
              default: {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.classifications.data[this.dynamicColumns.indexOf(header)];
                } else {
                  return data[header];
                }
              }
            }
          };

          this.organizationDataSource.sortingDataAccessor = (data: OperatorScoreRow, header: string): string | number => {
            switch (this.numberView) {
              case 'percentage': {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.classifications.data[this.dynamicColumns.indexOf(header)] / data.sessions;
                } else {
                  return data[header];
                }
              }
              default: {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.classifications.data[this.dynamicColumns.indexOf(header)];
                } else {
                  return data[header];
                }
              }
            }
          };

          const leadData = [];
          const rating = [];
          const labels = [];
          // const leads = [];
          const avgDuration = [];
          const visitorRating = [];
          const averageResponseTime = [];
          const averagePickupTime = [];
          operatorScoreRows.operators.sort((a, b) => a.name.localeCompare(b.name));
          operatorScoreRows.operators.forEach((operatorScoreRow: OperatorScoreRow) => {
            // leads.push(operatorScoreRow.classifications)
            labels.push(operatorScoreRow.name);
            rating.push(operatorScoreRow.combinedRating);
            avgDuration.push(operatorScoreRow.duration);
            leadData.push(operatorScoreRow.classifications.data[operatorScoreRow.classifications.labels.indexOf('Lead')]);
            visitorRating.push(operatorScoreRow.visitorRating);
            averagePickupTime.push(operatorScoreRow.averagePickupTime);
            averageResponseTime.push(operatorScoreRow.averageResponseTime);
          });
          this.leadSource = undefined;
          this.ratingSource = undefined;

          if (labels.length > 1) {
            this.leadSource = this.generateBarSource(labels, leadData, 'Leads');
            this.ratingSource = this.generateBarSource(labels, rating, 'Rating');
            this.timeSource = {
              datasets: [
                {
                  backgroundColor: this.colorService.getColor(1),
                  data: avgDuration,
                  label: 'Average chat duration',
                },
                {
                  backgroundColor: this.colorService.getColor(3),
                  data: averagePickupTime,
                  label: 'Average pickup time',
                },
                {
                  backgroundColor: this.colorService.getColor(5),
                  data: averageResponseTime,
                  label: 'Average response time',
                },
              ],
              labels,
            };
        }
          if (operatorScoreRows.operators.length > 3) {
            this.radarSource = {
              datasets: [
                {
                  backgroundColor: 'rgba(144,202,249,0.2)',
                  borderColor: 'rgba(144,202,249,1)',
                  data: visitorRating,
                  label: 'Vistor rating',
                  pointBackgroundColor: 'rgba(144,202,249,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(144,202,249,1)',
                },
                {
                  backgroundColor: 'rgba(13,71,161,0.2)',
                  borderColor: 'rgba(13,71,161,1)',
                  data: leadData,
                  label: 'Leads',
                  pointBackgroundColor: 'rgba(13,71,161,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(13,71,161,1)',
                },
              ],
              labels,
            };
          } else {
            this.radarSource = undefined;
          }
        }

        this.setGraphType(this.selectedGraph);
      });
    // }
  }

  isAdmin() {
    return this.authorizationService.isAdmin;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  isLCD() {
    return this.authorizationService.isLcd;
  }

  public setNumberType(type: string) {
    this.numberView = type;
  }

  public getData(key: string, element: any): string {

    const i = element.classifications.labels.indexOf(key);

    let date: string = 'n/a';
    if (element.classifications.data[i] !== 0) {
      switch (this.numberView) {
        case 'percentage' : {
          date = (Math.round(element.classifications.dataPercentage[i] * 100) / 100).toFixed(2) + '%';
          break;
        }
        case 'number' : {
          date = element.classifications.data[i];
          break;
        }
      }
    }
    return date;
  }

  public getWorkLoadColor(workLoad: number): string {
    if (workLoad == null) {
      return 'black';
    } else if (workLoad >= 100) {
      return 'green';
    } else if (workLoad < 100) {
      return 'red';
    } else {
      return 'black';
    }
  }

  private hasInformation(rows: OperatorScoreRow[]): boolean {
    let errorMessage: string;
    if (rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
    return this.errorMessage == null;
  }

  private generateBarSource(labels: any[], leadData: number[], label: string): any {
    return {
      datasets: [
        {
          backgroundColor: this.colorService.getColor(0),
          data: leadData,
          label,
        },
      ],
      labels,
    };
  }

  private generateDataPercentage(operatorScoreRows: OperatorScoreRow[]) {
    operatorScoreRows.forEach((operatorScoreRow) => {
      const dataPercentage: number[] = [];
      const total = operatorScoreRow.sessions;
      operatorScoreRow.classifications.data.forEach((data) => {
        dataPercentage.push((data / total) * 100);
      });
      operatorScoreRow.classifications.dataPercentage = dataPercentage;
    });
  }

  private getDynamicColumns(operatorScoreRows: OperatorScoreRow[]): string[] {
    const columns = [];

    operatorScoreRows.forEach(r => {
      r.classifications.labels.forEach((label) => {
        if (columns.indexOf(label) < 0) {
          columns.push(label);
        }
      });
    });

    return columns;
  }
}
