import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-generic-select-dialog',
  templateUrl: './generic-select-dialog.component.html',
  styleUrls: ['./generic-select-dialog.component.scss']
})
export class GenericSelectDialogComponent implements OnInit {

  options: any[];
  labelKey: string;

  constructor(public dialogRef: MatDialogRef<GenericSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SelectDialogData) { }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  select(option: any) {
    this.dialogRef.close(option);
  }

}

export interface SelectDialogData {
  text?: string;
  placeholder?: string;
  options: any[] | Observable<any[]>;
  labelKey: string;
}
