import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

import { AccountInformation } from '../model/account-information.model';
import { LoginUser } from '../model/LoginUser';

@Injectable()
export class AuthenticationService {
  accountInformation: AccountInformation;
  redirectUrl: string;

  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute) {
  }

  get authenticated(): boolean {
    return !!this.accountInformation;
  }

  authenticate(loginUser: LoginUser): Observable<AccountInformation> {
    const body = new FormData();
    body.append('username', loginUser.username);
    body.append('password', loginUser.password);

    return this.http
      .post<AccountInformation>('/api/sessions/authenticate', body)
      .pipe(
        tap((accountInformation: AccountInformation) => {
          this.accountInformation = accountInformation;
        }),
      );
  }

  tokenAuthenticate(token: string): Observable<AccountInformation> {
    const httpParams: HttpParams = new HttpParams();
    httpParams.append('token', token);

    return this.http.post(`/api/authenticate/token/${token}`, null)
      .pipe(
        tap((accountInformation: AccountInformation) => {
          this.accountInformation = accountInformation;
        }),
      );
  }

  redirect() {
    let redirect = this.route.snapshot.queryParams.redirect;
    if (!redirect) {

      const hostname = window.location.hostname;

      if (hostname === 'analytics.bconnectnederland.nl') {
        redirect = 'dashboard';
      } else if (hostname === 'support.bconnectnederland.nl') {
        redirect = 'support/overview';
      } else {
        redirect = '';
      }

      this.router.navigate([redirect]);
    } else {
      this.router.navigate([redirect]);
    }
  }

  invalidate(): Observable<boolean> {
    return this.http.get('/api/sessions/invalidate')
      .pipe(
        tap(() => this.accountInformation = undefined),
        mapTo(true),
      );
  }

}
