import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatMessage } from '../../model/support/chat-message.model';
import { OpenReview } from '../../model/support/open-review.model';
import { Category } from '../../model/support/review/category.model';
import { ClassificationDto } from '../../model/support/review/classification-dto';
import {OpenReviewAssignment} from "../../model/support/open-review-assignment.model";

@Injectable()
export class OpenReviewService {

  constructor(private http: HttpClient) { }

  getOpenHqReviews(): Observable<OpenReview[]> {
    return this.http.get<OpenReview[]>('/api/support/openreview/hq');
  }

  getOpenLanguageReviews(): Observable<OpenReview[]> {
    return this.http.get<OpenReview[]>('/api/support/openreview/lang');
  }

  getQueueErrors(): Observable<OpenReview[]> {
    return this.http.get<OpenReview[]>('/api/support/openreview/queueErrors');
  }

  getMessages(conversationUid: string): Observable<ChatMessage[]> {
    return this.http.get<ChatMessage[]>(`/api/support/openreview/messages?conversationUid=${conversationUid}`);
  }

  updateMessages(messages: ChatMessage[]): Observable<ChatMessage[]> {
    return this.http.post<ChatMessage[]>('/api/support/openreview/messages', messages);
  }

  getChatClassification(conversationUid: string): Observable<ClassificationDto> {
    return this.http.get<ClassificationDto>(`/api/support/openreview/conversation/${conversationUid}/chatclassification`);
  }

  updateChatClassification(chatClassification: ClassificationDto, reviewType: string): Observable<ClassificationDto> {
    return this.http.post<ClassificationDto>(`/api/support/openreview/${reviewType}/chatclassification/save`, chatClassification);
  }

  resolveChatClassification(resolutionText: string,
                            chatClassification: ClassificationDto,
                            reviewType: string): Observable<ClassificationDto> {
    return this.http.post<ClassificationDto>(`/api/support/openreview/${reviewType}/chatclassification/resolve`,
      {resolutionText: resolutionText, chatClassification: chatClassification});
  }

  getDepartments(account: string): Observable<string[]> {
    return this.http.get<string[]>(`/api/support/openreview/account/departments?account=${account}`);
  }

  getCategories(parentDomain: string): Observable<Category[]> {
    const httpParams = new HttpParams().append('parentDomain', parentDomain);
    return this.http.get<Category[]>(`/api/support/openreview/categories`, {params: httpParams});
  }

  assignTo(uid: string, openReviewType: string, assignTo: number): Observable<OpenReviewAssignment> {
    return this.http.post<OpenReviewAssignment>(`/api/support/openreview/${uid}/assign`,
      {assignTo: assignTo, openReviewType: openReviewType});
  }

  getAssignments(uids: string[], reviewType: string) {
    return this.http.post<OpenReviewAssignment[]>(`/api/support/openreview/assignments`,
      {conversationUids: uids, openReviewType: reviewType});
  }

}
