import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/authentication.service';
import { FaqAddition } from '../../../../model/support/review/faq-addition.model';
import { SessionChatInfo } from '../../../../model/support/session-chat-info.model';
import {
  GenericTextDialogComponent
} from '../../../../shared/components/generic-text-dialog/generic-text-dialog.component';
import { ReviewService } from '../../../service/review.service';
import { SessionChatInfoService } from '../../../service/session-chat-info.service';
import { FeedbackService } from '../../feedback.service';
import { SessionDetailsDialogComponent } from '../session-details-dialog/session-details-dialog.component';

@Component({
  selector: 'app-client-faq-dialog',
  templateUrl: './client-faq-dialog.component.html',
  styleUrls: ['./client-faq-dialog.component.scss']
})
export class ClientFaqDialogComponent implements OnInit {

  faqAddition: FaqAddition;
  title = 'FAQ editor';

  commentsActive = false;
  questionsEditable = false;

  messagingComponentId: string;

  showMessage = false;
  message: string;

  componentDestroyed$ = new Subject<void>();

  feedbackService = FeedbackService;

  sessionChatInfo?: SessionChatInfo;

  constructor(public dialogRef: MatDialogRef<ClientFaqDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private authenticationService: AuthenticationService,
              private reviewService: ReviewService,
              private sessionChatInfoService: SessionChatInfoService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.faqAddition = JSON.parse(JSON.stringify(this.data.faqAddition));
    this.messagingComponentId = `faqMessagingComponent-${this.faqAddition.id}`;
    this.questionsEditable = this.data.canEdit;
  }

  loadRelatedChat() {
    this.dialog.open(SessionDetailsDialogComponent,
      {
        width: '75vw',
        data: {
          conversationUid: this.faqAddition.conversationUid
        }
      });
  }

  concept() {
    this.dialogRef.close({faqAddition: this.faqAddition, action: 'concept'});
  }

  mayReject(): boolean {
    // the current user has at least one comment
    return this.faqAddition.comments.some(c => c.commentBy.userName === this.authenticationService.accountInformation.name);
  }

  reject() {
    if (!this.mayReject()) {
      this.enableCommenting();
      this.showMessage = true;
      this.message = 'Please leave a comment before rejecting this FAQ.';
      document.getElementById(this.messagingComponentId).focus();
      return;
    }

    this.dialogRef.close({faqAddition: this.faqAddition, action: 'reject'});
  }

  get mayDelete(): boolean {
    return this.faqAddition.createdByClient &&
      ['CANCELED', 'CREATED'].some(s => s === this.faqAddition.faqAdditionProcessStage);
  }

  delete() {
    this.reviewService.deleteFaqAddition(this.faqAddition.id, this.faqAddition.reviewId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.dialogRef.close({action: 'deleted'});
      });
  }

  get maySubmit(): boolean {
    // at least 1 qna with a question should have an answer
    // stage should be TO_CLIENT or it was created by a client and stage is CREATED or CANCELED
    return ('TO_CLIENT' === this.faqAddition.faqAdditionProcessStage ||
      (['CREATED', 'CANCELED'].some(s => s === this.faqAddition.faqAdditionProcessStage) && this.faqAddition.createdByClient))
      && this.faqAddition.qnas.filter(qna => qna.question?.trim().length > 0)
      .some(qna => qna.answer?.trim().length > 0);
  }

  get maySaveAsConcept(): boolean {
    return ['CREATED', 'TO_CLIENT'].some(s => s === this.faqAddition.faqAdditionProcessStage);
  }

  get mayCancel(): boolean {
    return this.faqAddition.createdByClient &&
      !['ACCEPTED', 'CANCELED'].some(s => s === this.faqAddition.faqAdditionProcessStage);
  }

  cancelFaqAddition() {
    this.dialog.open(GenericTextDialogComponent, {
      maxWidth: '400px',
      data: {
        html: `<p>This will cancel this FAQ addition, allowing you to delete it.</p>
                            <p>Do you wish to proceed?</p>`,
        prompt: {accept: 'Yes', decline: 'No'}
      }
    }).afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((response) => {
        if (response) {
          this.dialogRef.close({faqAddition: this.faqAddition, action: 'cancel'});
        }
      });
  }

  submit() {
    if (this.faqAddition.qnas.some(qna => !(qna.question?.trim().length > 0) || !(qna.answer?.trim().length > 0))) {
      // some question or answer is empty
      this.dialog.open(GenericTextDialogComponent, {
        maxWidth: '400px',
        data: {
          html: `<p>Not all languages have a complete Q&A. If you submit this FAQ, any missing questions and/or
                   answers will be completed by Bconnect.</p>
                            <p>Do you wish to submit this FAQ to Bconnect as is?</p>`,
          prompt: {accept: 'Yes', decline: 'No'}
        }
      }).afterClosed()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {

          if (response) {
            this.dialogRef.close({faqAddition: this.faqAddition, action: 'submit'});
          }

        });
    } else {
      this.dialogRef.close({faqAddition: this.faqAddition, action: 'submit'});
    }

  }

  enableCommenting() {
    this.commentsActive = true;
  }

  editQuestions() {
    this.questionsEditable = true;
  }

  addComment(comment: string) {
    this.reviewService.addCommentToFaqAddition(this.faqAddition.id, this.faqAddition.reviewId, comment)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(response => {
        this.faqAddition.comments = response.comments;
        this.data.faqAddition.comments = response.comments; // update comments in source faqAddition since they are already saved
      });
  }

}
