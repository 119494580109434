import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreParameters } from '../../model/core-parameters.model';
import { LabeledLabelNumericData } from '../../model/labeled-label-numeric-data';
import { TrendPipe } from '../../pipes/trend.pipe';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-ceo-home',
  templateUrl: './ceo-home.component.html',
})
export class CeoHomeComponent extends CoreParametersConsumer {
  options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          min: 0,
        },
      }],
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };
  averageScores: {
    chatsHour: number, euroPerChat: number,
    euroTotal: number, clientScore: number,
    visitorScore: number, totalScore: number,
  };
  trendPipe = new TrendPipe();
  dataEuroPer$: Observable<LabeledLabelNumericData>;
  dataCategories$: Observable<LabeledLabelNumericData>;
  dataHappiness$: Observable<LabeledLabelNumericData>;

  period: string = 'Month';
  currentGraph: string = 'Total';
  currentGraphSelector: string = 'categoryGraphs';
  selectedEuroGraph: string = 'Hour';
  selectedHappinessGraph: string = 'Total';
  selectedDistributionGraph: string = 'Total';
  dataSource: LineChartData;
  subtitle: string;
  errorMessage: string =  'There is no data available';

  dataCategories;
  dataEuroPerHour;
  dataEuro;
  dataEuroPerChat;
  dataLeads;
  dataMissedChats;
  dataGeenDialoog;
  dataService;
  dataWasted;
  dataTotalChats;
  dataClientRating;
  dataVisitorRating;
  dataTotalRating;

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  get isLineChartInitialized(): boolean {
    return this.dataSource !== undefined
      && this.dataSource.datasets.length > 0
      && this.dataSource.datasets[0].data.length > 0;
  }

  loadData(coreParameters: CoreParameters): void {
    this.period = coreParameters.summarizeBy.charAt(0).toUpperCase() + coreParameters.summarizeBy.substr(1);
    this.averageScores = {chatsHour: 0, euroPerChat: 0, euroTotal: 0, clientScore: 0, visitorScore: 0, totalScore: 0};
    this.dataSource = undefined;
    this.dataCategories = undefined;
    this.dataEuroPerHour = undefined;
    this.dataEuro = undefined;
    this.dataEuroPerChat = undefined;
    this.dataLeads = undefined;
    this.dataMissedChats = undefined;
    this.dataGeenDialoog = undefined;
    this.dataService = undefined;
    this.dataWasted = undefined;
    this.dataTotalChats = undefined;
    this.dataClientRating = undefined;
    this.dataVisitorRating = undefined;
    this.dataTotalRating = undefined;

    this.dataCategories$ = this.graphService.getAdminHomeData(coreParameters);
    this.dataHappiness$ = this.graphService.getHappinessScores(coreParameters);
    this.dataEuroPer$ = this.graphService.getEuroPerChatAndHour(coreParameters);

    this.setGraphType(this.currentGraph);
    if (this.currentGraphSelector !== 'categoryGraphs') {
      this.generateCategories(null);
    }
    if (this.currentGraphSelector !== 'happinessGraphs') {
      this.generateHappiness(null);
    }
    if (this.currentGraphSelector !== 'euroGraphs' ) {
      this.generatePerEuro(null); }
  }

  setGraphType(graph: string): void {
      this.currentGraph = graph;
      switch (graph) {
        case 'Leads' : {
          this.currentGraphSelector = 'categoryGraphs';
          if (!this.dataLeads) {
            this.generateCategories('dataLeads');
          }
          this.selectedDistributionGraph = 'Leads';
          this.dataSource = this.dataLeads;
          this.subtitle = 'Leads per hour';
          break;
        }
        case 'Wasted' : {
          this.currentGraphSelector = 'categoryGraphs';
          if (!this.dataWasted) {
            this.generateCategories('dataWasted');
          }
          this.selectedDistributionGraph = 'Wasted';
          this.dataSource = this.dataWasted;
          this.subtitle = 'Wasted per hour';
          break;
        }
        case 'Service' : {
          this.currentGraphSelector = 'categoryGraphs';
          this.selectedDistributionGraph = 'Service';
          if (!this.dataService) {
            this.generateCategories('dataService');
          }
          this.dataSource = this.dataService;
          this.subtitle = 'Service per hour';
          break;
        }
        case 'Geen Dialoog' : {
          this.currentGraphSelector = 'categoryGraphs';
          this.selectedDistributionGraph = 'Geen Dialoog';
          if (!this.dataGeenDialoog) {
            this.generateCategories('dataGeenDialoog');
          }
          this.dataSource = this.dataGeenDialoog;
          this.subtitle = 'Geen dialoog per hour';
          break;
        }
        case 'Missed' : {
          this.currentGraphSelector = 'categoryGraphs';
          this.selectedDistributionGraph = 'Missed';
          if (!this.dataMissedChats) {
            this.generateCategories('dataMissedChats');
          }
          this.dataSource = this.dataMissedChats;
          this.subtitle = 'Missed Chats per hour';
          break;
        }
        case 'Total' : {
          this.currentGraphSelector = 'categoryGraphs';
          this.selectedDistributionGraph = 'Total';
          if (!this.dataTotalChats) {
            this.generateCategories('dataTotalChats');
          }
          this.dataSource = this.dataTotalChats;
          this.subtitle = 'Total Chats per hour';
          break;
        }
        case 'Visitors' : {
          this.currentGraphSelector = 'happinessGraphs';
          this.selectedHappinessGraph = 'Visitors';
          if (!this.dataVisitorRating) {
            this.generateHappiness('dataVisitorRating');
          }
          this.dataSource = this.dataVisitorRating;
          this.subtitle = 'Visitor Happiness';
          break;
        }
        case 'Clients' : {
          this.currentGraphSelector = 'happinessGraphs';
          this.selectedHappinessGraph = 'Clients';
          if (!this.dataClientRating) {
            this.generateHappiness('dataClientRating');
          }
          this.dataSource = this.dataClientRating;
          this.subtitle = 'Clients Happiness';
          break;
        }
        case 'TotalHappiness' : {
          this.currentGraphSelector = 'happinessGraphs';
          this.selectedHappinessGraph = 'Total';
          if (!this.dataTotalRating) {
            this.generateHappiness('dataTotalRating');
          }
          this.dataSource = this.dataTotalRating;
          this.subtitle = 'Total Happiness';
          break;
        }
        case 'euro per chat' : {
          this.selectedEuroGraph = 'Chat';
          this.currentGraphSelector = 'euroGraphs';
          if (!this.dataEuroPerChat) {
            this.generatePerEuro('dataEuroPerChat');
            } else {
            this.dataSource = this.dataEuroPerChat;
          }
          this.subtitle = '€ per chat';
          break;
        }
        case 'euro per hour' : {
          this.selectedEuroGraph = 'Hour';
          this.currentGraphSelector = 'euroGraphs';
          if (!this.dataEuroPerHour) {
            this.generatePerEuro('dataEuroPerHour');
          } else {
            this.dataSource = this.dataEuroPerHour;
          }
          this.subtitle = '€ per hour';
          break;
        }
        case 'euro' : {
          this.selectedEuroGraph = this.period;
          this.currentGraphSelector = 'euroGraphs';
          if (!this.dataEuroPerHour) {
            this.generatePerEuro('dataEuro');
          } else {
            this.dataSource = this.dataEuro;
          }
          this.subtitle = '€';
          break;
        }

      }
      this.currentGraph = graph;
  }

  generateLineChart(label: string, dataLabelName: string, data: LabeledLabelNumericData): LineChartData {
    const dataObject = data.data.find((x) => x.label === dataLabelName);
    if (dataObject) {
    return {
      datasets: [{
        borderColor: this.colorService.getColor(0),
        data: dataObject.data,
        fill: false,
        label,
      },
        this.generateTrendLine(dataObject, this.colorService.getTrendLineColor(), false),
      ],
      labels: data.labels,
    };
    } else {
      return null;
    }
  }

  private generateHappiness(category: string) {
    if (this.dataHappiness$ !== undefined) {
      this.dataHappiness$.subscribe((data) => {
        this.dataTotalRating = this.generateLineChart('Total Rating', 'Total Score', data);
        this.dataClientRating = this.generateLineChart('Client Rating', 'Client Score', data);
        this.dataVisitorRating = this.generateLineChart('Visitor Rating', 'Visitor Score', data);
        if (category !== null) {
          this.dataSource = this[category];
        }
        this.averageScores.clientScore = +data.keyValuePairs.find((x) => (x.key === 'Client Score')).value;
        this.averageScores.visitorScore = +data.keyValuePairs.find((x) => (x.key === 'Visitor Score')).value;
        this.averageScores.totalScore = +data.keyValuePairs.find((x) => (x.key === 'Total Score')).value;
      });
    }
  }

  private generatePerEuro(category: string) {
    if (this.dataEuroPer$ !== undefined) {
      this.dataEuroPer$.subscribe((data) => {
        this.dataEuro = this.generateLineChart('Euro Total', 'Euro Total', data);
        this.dataEuroPerHour = this.generateLineChart('Euro per Hour', 'Euro per Hour', data);
        this.dataEuroPerChat = this.generateLineChart('Euro per chat', 'Euro per Chat', data);
        if (category !== null) {
          this.dataSource = this[category];
        }
        this.averageScores.euroTotal = +data.keyValuePairs.find((x) => (x.key === 'Euro Total')).value;
        this.averageScores.euroPerChat =  +data.keyValuePairs.find((x) => (x.key === 'Euro per Chat')).value;

      });
    }
  }

  private generateCategories(category: string) {
    if (this.dataCategories$ !== undefined) {
      this.dataCategories$.subscribe((data) => {
        this.dataTotalChats = this.generateLineChart('Total Chats', 'Total', data);
        this.dataWasted = this.generateLineChart('Wasted', 'Wasted', data);
        this.dataLeads = this.generateLineChart('Leads', 'Lead', data);
        this.dataMissedChats = this.generateLineChart('Missed', 'Missed', data);
        this.dataService = this.generateLineChart('Service', 'Service', data);
        this.dataGeenDialoog = this.generateLineChart('Geen Dialoog', 'Geen Dialoog', data);
        if (category !== null) {
          this.dataSource = this[category];
        }
        this.averageScores.chatsHour = +data.keyValuePairs.find((x) => (x.key === 'average')).value;
      });
    }
  }

  private generateTrendLine(result: { label: string, data: number[] }, color: string, hide: boolean): any {
    return {
      borderColor: color,
      borderDash: [10, 15],
      data: this.trendPipe.transform(result.data),
      fill: false,
      hidden: hide,
      label: 'Trend line' + ' ' + result.label,
      pointBorderWidth: 0,
      radius: 0,
    };
  }

  private getColorClass(score: number) {
    return this.colorService.getHappinessColorClass(score);
  }

}

export interface LineChartData {
  labels: string[];
  datasets: Array<{ label: string, data: number[], fill?: boolean, borderColor?: string }>;

}
