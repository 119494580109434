import { Component, Input} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-chart-statistics',
  templateUrl: './chart-statistics.component.html',
})

export class ChartStatisticsComponent {

  statisticsDataSource: MatTableDataSource<Statistics[]>;
  readonly displayedColumns: string[] = [
    'key',
    'value1',
    'value2',
  ];
  @Input()
  set stats(statistics) {
    this.statisticsDataSource = new MatTableDataSource(statistics);
  }
}

export interface Statistics {
  key: string;
  value1: string;
  value2: string;
  color: string;
}
