import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../core/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  title = 'Please sign in';
  loginForm: FormGroup;
  message = '';

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService) {
    this.createForm();
  }

  onSubmit(): void {
    const formModel = this.loginForm.value;
    const credentials = {
      password: formModel.password,
      username: formModel.username,
    };

    this.login(credentials);
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      username: ['', Validators.required],
    });
  }

  private login(credentials): void {
    this.message = 'Logging in...';
    this.authenticationService.authenticate(credentials).subscribe(
      () => {
        this.authenticationService.redirect();
      },
      (err) => {
        this.message = 'Invalid credentials.';
      },
    );
  }
}
