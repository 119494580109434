import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertifyService } from '../../support/service/alertify.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {

  constructor(private alertifyService: AlertifyService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            this.alertifyService.error(`Server responded with error: ${error.status}`);
          }
          return throwError(error);
        })
      );
  }
}
