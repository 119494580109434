import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable ,  Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CoreParameters } from '../../model/core-parameters.model';
import { Happiness } from '../../model/happiness.model';
import { LabeledLabelNumericData } from '../../model/labeled-label-numeric-data';
import { RatingsByClassification } from '../../model/ratings-by-classification';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';
import { HappinessWrapper } from '../visitor-happiness/visitor-happiness.component';

@Component({
  selector: 'app-client-happiness',
  templateUrl: './client-happiness.component.html',
})
export class ClientHappinessComponent extends CoreParametersConsumer {
  ratingsByClassification: MatTableDataSource<RatingsByClassification>;
  totalColumns: string[];
  dynamicColumns: string[];
  numberView = 'number';
  data;
  total: HappinessWrapper;
  averageRating;
  goodRatings;
  neutralRatings;
  badRatings;
  subtitle: string;
  options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 4,
        },
      }],
    },
    title: {display: true},
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };
  dataSource$: Observable<Happiness>;
  errorMessage: string;
  ratingSubscription: Subscription;
  classificationSource: MatTableDataSource<any>;
  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  getColorClassByRating(n: number): string {
    return this.colorService.getHappinessColorClass(Number(n));
  }

  loadData(coreParameters: CoreParameters): void {
    this.subtitle = this.graphService.getLabel(coreParameters);
    this.ratingSubscription = this.graphService
      .getClientHappinessCategory(coreParameters).subscribe((happinessDto: LabeledLabelNumericData) => {
        this.totalColumns = ['happiness'];
        this.dynamicColumns = [];
        const dynamicCategories: HappinessWrapper[] = [];
        let good: HappinessWrapper ;
        let neutral: HappinessWrapper ;
        let bad: HappinessWrapper ;
        let grades: HappinessWrapper ;
        good = {label: 'good', dataNumber: [], dataPercentage: [], img: 'assets/img/good.png'};
        neutral = {label: 'neutral', dataNumber: [], dataPercentage: [], img: 'assets/img/neutral.png'};
        bad = {label: 'bad', dataNumber: [], dataPercentage: [], img: 'assets/img/bad.png'};
        grades = {label: 'grade', dataNumber: [], dataPercentage: [], img: ''};
        happinessDto.data.forEach((data) => {
          this.dynamicColumns.push(data.label);
          good.dataNumber.push(data.data[0]);
          neutral.dataNumber.push(data.data[1]);
          bad.dataNumber.push(data.data[2]);
          const divisor = (data.data[0] + data.data[1] + data.data[2]) / 100;
          good.dataPercentage.push(data.data[0] / divisor);
          neutral.dataPercentage.push(data.data[1] / divisor);
          bad.dataPercentage.push(data.data[2] / divisor);
          const grade  = this.calculateGrade(data.data[0], data.data[1], data.data[2]);
          grades.dataPercentage.push(grade);
          grades.dataNumber.push(grade);
        });
        dynamicCategories.push(good, neutral, bad, grades);
        this.generateTotal(dynamicCategories);
        this.classificationSource = new MatTableDataSource(dynamicCategories);
        this.totalColumns = this.totalColumns.concat(this.dynamicColumns);
      });

    this.dataSource$ = this.graphService.getClientHappiness(
      coreParameters).pipe(
      tap((clientHappiness: Happiness) => {
        if (clientHappiness) {
          this.checkRequiredParameters(clientHappiness.ratingsByClassification);
          this.averageRating = clientHappiness.averageRating;
          this.badRatings = clientHappiness.ratingsByType.badRatings;
          this.neutralRatings = clientHappiness.ratingsByType.neutralRatings;
          this.goodRatings = clientHappiness.ratingsByType.goodRatings;
          this.data = {};
          this.data.labels = clientHappiness.ratingsWithLabels.labels;
          this.data.datasets = [{
            borderColor: this.colorService.getColor(0),
            data: clientHappiness.ratingsWithLabels.data,
            fill: false,
            label: 'Client Happiness',
          }];
          this.processRatingsByClassification(clientHappiness);
        }
      }),
    );
  }
  calculateGrade(good: number, neutral: number, bad: number): number {
    return ((bad * 2) + (good * 10) + (neutral * 6)) / (neutral + good + bad);
  }
  getData(element: HappinessWrapper, n: number, header: string): string {
    let answer = '';
    if (element.label === 'grade') {
      answer += element.dataNumber[n].toFixed(2);
    } else if (this.numberView === 'percentage') {
      answer += ((element.dataPercentage[n] * 100) / 100).toFixed(2) + '%';
    } else {
      answer +=  element.dataNumber[n];
    }
    return answer;
  }
  public setNumberType(type: string) {
    this.numberView = type;
  }
  processRatingsByClassification(clientHappiness: Happiness): void {
    clientHappiness.ratingsByClassification.sort((a, b) =>
      a.numberOfChats - b.numberOfChats);
    clientHappiness.ratingsByClassification.reverse();
    this.ratingsByClassification =
      new MatTableDataSource(clientHappiness.ratingsByClassification);
  }

  private checkRequiredParameters(rows: RatingsByClassification[]) {
    let errorMessage: string;
    if  ( rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
  }
  private generateTotal(dynamicCategories: HappinessWrapper[]) {
    this.dynamicColumns.splice(0, 0, 'Total');
    dynamicCategories.forEach((happinessWrapper) => {
      if (happinessWrapper.label !== 'grade') {
        if (happinessWrapper.dataNumber.length > 0) {
        happinessWrapper.dataNumber.splice(0, 0, happinessWrapper.dataNumber.reduce((a, b) => a + b));
        happinessWrapper.dataPercentage.splice(0, 0, happinessWrapper.dataNumber[0]); }
      }
    });
    const totals: number[] = dynamicCategories.filter((x) => x.label !== 'grade').map((x) => x.dataNumber[0]);
    const grade: HappinessWrapper = dynamicCategories.find((x) => x.label === 'grade');
    const totalGrade: number = this.calculateGrade(totals[0], totals[1], totals[2]);
    grade.dataPercentage.splice(0, 0, totalGrade);
    grade.dataNumber.splice(0, 0, totalGrade);
    const sumTotals: number = totals[0] + totals[1] + totals[2];
    dynamicCategories.forEach((happinessWrapper) => {
      if (happinessWrapper.label !== 'grade') {
        happinessWrapper.dataPercentage[0] = happinessWrapper.dataNumber[0] / sumTotals * 100 ;
      }
    });
  }
}
