import { Component, Input } from '@angular/core';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SimpleComment } from '../../../../model/support/simple-comment.model';

@Component({
  selector: 'app-simple-messaging-history',
  templateUrl: './simple-messaging-history.component.html',
  styleUrls: ['./simple-messaging-history.component.scss']
})
export class SimpleMessagingHistoryComponent {

  @Input()
  messages: SimpleComment[];
  @Input()
  scrollable: boolean;
  currentUser: string;

  constructor(private authorizationService: AuthorizationService) {
    this.currentUser = this.authorizationService.currentUser.name;
  }

  sortFn = (a: SimpleComment, b: SimpleComment) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  getMessageClass(userName: string) {
    let returnClass: string;
    if (userName === undefined || this.currentUser === userName) {
      returnClass = 'left';
    } else if (userName === 'System') {
      returnClass = 'system';
    } else {
      returnClass = 'right';
    }
    return returnClass;
  }

  getMessageLines(message: SimpleComment): string[] {
    return message.comment.split('\n');
  }

}
