import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-send-button',
  templateUrl: './send-button.component.html',
  styleUrls: ['./send-button.component.scss']
})
export class SendButtonComponent implements OnInit {

  @Output()
  onClick = new EventEmitter();
  @Input()
  disabled: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
