import { NgModule } from '@angular/core';
import { GraphModule } from '../graph/graph.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  exports: [
    DashboardComponent,
  ],
  imports: [
    DashboardRoutingModule,
    GraphModule,
    SharedModule,
  ],
})
export class DashboardModule {
}
