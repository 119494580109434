import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from '../core/authentication.guard';
import {ChatArchiveComponent} from './chat-archive/chat-archive.component';
import {HqReviewComponent} from './hq-review/hq-review.component';
import {LangReviewComponent} from './lang-review/lang-review.component';
import {OverviewComponent} from './overview/overview.component';
import {QueueErrorComponent} from './queue-error/queue-error.component';
import {ReviewComponent} from './review/review.component';
import {ReviewCanDeactivateGuard,} from './service/review-can-deactivate-guard.service';
import {SupportComponent} from './support.component';
import {FeedbackClientFaqsComponent} from './tickets/feedback-client-faqs/feedback-client-faqs.component';
import {TicketsComponent} from './tickets/tickets.component';
import {UpdatesComponent} from './updates/updates.component';
import {ReviewActionType} from "../enums/support/review-action-type.enum";

const routes: Routes = [
  {
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        component: OverviewComponent,
        path: 'overview',
      },
      {
        component: ChatArchiveComponent,
        path: 'chatarchive',
      },
      {
        canDeactivate: [ReviewCanDeactivateGuard],
        component: ReviewComponent,
        path: 'review',
      },
      {
        component: TicketsComponent,
        path: 'tickets/feedback',
        data: {
          type: ReviewActionType.FEEDBACK
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/feedback/token/:token',
        data: {
          type: ReviewActionType.FEEDBACK
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/topchat',
        data: {
          type: ReviewActionType.TOPCHAT
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/faqs',
        data: {
          type: ReviewActionType.FAQ
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/corrections',
        data: {
          type: ReviewActionType.CORRECTION
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/optimization',
        data: {
          type: ReviewActionType.OPTIMIZATION
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/escalate',
        data: {
          type: ReviewActionType.ESCALATE
        }
      },
      {
        component: TicketsComponent,
        path: 'tickets/client-feedback',
        data: {
          type: ReviewActionType.CLIENTCOMMENT
        }
      },
      {
        component: FeedbackClientFaqsComponent,
        path: 'feedback/faqs',
      },
      {
        component: FeedbackClientFaqsComponent,
        path: 'feedback/faqs/token/:token',
      },
      {
        component: UpdatesComponent,
        path: 'updates',
      },
      {
        component: HqReviewComponent,
        path: 'hqreview',
      },
      {
        component: LangReviewComponent,
        path: 'langreview',
      },
      {
        component: QueueErrorComponent,
        path: 'queueErrors',
      },
    ],
    component: SupportComponent,
    path: 'support',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  providers: [ReviewCanDeactivateGuard],
})

export class SupportRoutingModule {
}
