import { Injectable } from '@angular/core';
import { FaqAdditionQna } from '../../model/support/review/faq-addition-qna.model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor() { }

  public static qnaSortFn = (a: FaqAdditionQna, b: FaqAdditionQna) => {
    // sort qnas with a question first, then by lang code alphabetically, special case for NL
    if (a.question && !b.question) {
      return -1;
    } else if (b.question && !a.question) {
      return 1;
    } else if (a.languageCode === 'NL') {
      return -1;
    } else if (b.languageCode === 'NL') {
      return 1;
    } else {
      return a.languageCode.charCodeAt(0) - b.languageCode.charCodeAt(0);
    }
  }
}
