import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
})
export class LogoutButtonComponent {
  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  get authenticated(): boolean {
    return this.authenticationService.authenticated;
  }

  logout(): void {
    this.authenticationService.invalidate().subscribe(
      () => this.router.navigate(['/login']),
    );
  }

  get getTitle(): string {
    let title: string = '';
    if (this.authenticationService.accountInformation != null) {
      if (this.authenticationService.accountInformation.name === 'Rutger') {
        title = 'CEO';
      } else {
        title = this.authenticationService.accountInformation.roles[0].title;
      }
    }
    return title;
  }
}
