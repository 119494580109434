import { Component, OnDestroy } from '@angular/core';

import { zip ,  Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Account } from '../../model/account.model';
import { CoreParameters } from '../../model/core-parameters.model';
import { KeyValuePair } from '../../model/key-value-pair';
import { LabeledNumericData } from '../../model/labeled-numeric-data';
import { Statistics } from '../chart-statistics/chart-statistics.component';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-chats-period-distribution',
  templateUrl: './chats-period-distribution.component.html',
})
export class ChatsPeriodDistributionComponent extends CoreParametersConsumer implements  OnDestroy {
  accountFilter: string;
  dataHours;
  dataDays;
  statistics: Statistics[];
  hoursDaysSubscription: Subscription;
  options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,

        },
      }],
    },
  };
  errorMessage: string;
  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters): void {
    if (coreParameters.accounts) {
      this.accountFilter = coreParameters.accounts.map((a: Account) => a.name).join(',');
    } else {
      this.accountFilter = 'all accounts';
    }
    let labelsHours: string[];
    let labelsDays: string[];
    let stats: Array<KeyValuePair<string, number>> = [];
    this.hoursDaysSubscription = zip(
      this.graphService.getChatsPerHour(
        coreParameters).pipe(
        tap((r: LabeledNumericData<number>) => {
          labelsHours = r.labels;
          this.checkRequiredParameters(r.data);
          stats = stats.concat(r.keyValuePairs);
        }),
        map((r: LabeledNumericData<number>) => {
            return {
              backgroundColor: this.colorService.getColor(0),
              data: r.data,
              label: 'Average chats per hour',
            };
          },
        )),
      this.graphService.getChatsPerDay(
        coreParameters).pipe(
        tap((r: LabeledNumericData<number>) => {
          labelsDays = r.labels;
          stats = stats.concat(r.keyValuePairs);
        }),
        map((r: LabeledNumericData<number>) => {
            return {
              backgroundColor: this.colorService.getColor(2),
              data: r.data,
              label: 'Average chats per day',
            };
          },
        )),
      (hours: {}, days: {}) => ({hours, days}))
    .subscribe((data) => {
      this.dataHours = {};
      this.dataDays = {};
      this.dataHours.labels = labelsHours;
      this.dataHours.datasets = [data.hours];
      this.dataDays.labels = labelsDays;
      this.dataDays.datasets = [data.days];
      this.addStats(stats);
    });
  }

  addStats(keyValuePairs: Array<KeyValuePair<string, number>>): void {
    if (keyValuePairs.length > 0) {
      const stats = [];
      let index = 0;
      for (const kv of keyValuePairs) {
        stats[index] = ({
          color: '',
          key: kv.key,
          value1: kv.value,
          value2: '',
        });
        index++;
      }
      this.statistics = stats;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.hoursDaysSubscription.unsubscribe();
  }

  private checkRequiredParameters(data: number[]) {
    let errorMessage: string;
    if (data.reduce((acc, val) => acc + val) === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
  }
}
