import { Injectable } from '@angular/core';
import { ColorMeaning } from '../../model/color-meaning.enum';

@Injectable()
export class ColorService {

  private primary = [
    '#0D47A1',
    '#141464',
    '#6292cc',
    '#42A5F5',
    '#64B5F6',
    '#9fd9f9',
    '#b7e7f9',
    '#e2f1fb',
    '#e2e5e5',
    '#b8bbbb',
    '#8d8f8f',
    '#4e4f4f',
    '#103974',
  ];

  private secondary = [
    '#ba3b3b',
    '#af4937',
    '#E57D66',
    '#e2bfa4',
    '#ddc994',
    '#d6e09d',
    '#c9cb5c',
    '#a5c955',
    '#848c2c',
    '#7d5227',
    '#442b16',
  ];

  private colorSet = 'primary';

  private happinessColorClasses = { bad: 'happiness-bad',
                                    default: 'happiness-default',
                                    good: 'happiness-good',
                                    neutral: 'happiness-neutral',
                                  };

  toggleColorSet(primary: boolean) {
    if (primary) {
      this.colorSet = 'primary';
    } else {
      this.colorSet = 'secondary';
    }
  }

  getColor(index: number): string {
    const ind = index % this[this.colorSet].length;
    return this[this.colorSet][ind];
  }

   hexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getTrendLineColor(): string {
    return '#b8bbbb';
  }

  getColorByMeaning(colorMeaning: ColorMeaning): string {
    let colorClass: string = '';
    if (colorMeaning === ColorMeaning.BAD) {
      colorClass = '#F44336';
    } else if (colorMeaning === ColorMeaning.NEUTRAL) {
      colorClass = '#FFC107';
    } else if (colorMeaning === ColorMeaning.GOOD) {
      colorClass = '#4CAF50';
    }
    return colorClass;
  }
  getColorByGrade(colorMeaning: number): string {
    let colorClass: string = '';
    if (colorMeaning === 2) {
      colorClass = this.getColorByMeaning(ColorMeaning.BAD);
    } else if (colorMeaning === 6) {
      colorClass = this.getColorByMeaning(ColorMeaning.NEUTRAL);
    } else if (colorMeaning === 10) {
      colorClass = this.getColorByMeaning(ColorMeaning.GOOD);
    }
    return colorClass;
  }

  getHappinessColorClass(rating: number): string {
    let colorClass: string = '';
    if (rating === 0) { colorClass = this.happinessColorClasses.default; } else
    if (rating < 7 && rating > 0) { colorClass = this.happinessColorClasses.bad; } else
    if (rating >= 7 && rating <= 8) { colorClass = this.happinessColorClasses.neutral; } else
    if (rating > 8) { colorClass = this.happinessColorClasses.good; }

    return colorClass;
  }
}
