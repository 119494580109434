import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizationService } from '../../../core/authorization.service';
import { ReviewActionType } from '../../../enums/support/review-action-type.enum';
import { FeedBackRow } from '../../../model/support/feedback-row.model';
import { Review } from '../../../model/support/review/review.model';
import { SessionChatInfo } from '../../../model/support/session-chat-info.model';
import { UserShort } from '../../../model/user-short.model';
import { ReviewService } from '../../service/review.service';
import { SupportService } from '../../service/support.service';

@Component({
  selector: 'app-standard-feedback-table',
  templateUrl: './standard-feedback-table.component.html',
})
export class StandardFeedbackTableComponent implements OnInit, OnDestroy {

  @Input()
  type: ReviewActionType;

  tableDataLength: number;
  displayedColumns: string[] = ['sessionDate', 'conversationUid', 'account',
    'visitorRating', 'clientRating', 'bconnectRating', 'openReviewActions'];
  displayedColumnsForClient: string[] = ['sessionDate', 'conversationUid', 'account',
    'visitorRating', 'clientRating', 'openReviewActions'];
  ungroupedColumns: string[] = ['actionDate', 'triggeredBy'];
  tableDataSource: MatTableDataSource<FeedBackRow>;
  @ViewChild('table', {static: false}) table: MatTable<any>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  conversationUid: string;
  @Output()
  detailscreen = new EventEmitter<string>();
  @Input()
  titleText: string;
  @Input()
  tickets: Observable<FeedBackRow[]>;

  sessionInfoPlayList: FeedBackRow[];
  currentPlayListIndex: number;
  sessionChatInfo: SessionChatInfo;
  ungroupedSessionData: FeedBackRow[];

  componentDestroyed$: Subject<void> = new Subject<void>();

  // selectedFeedbackRow: FeedBackRow;

  constructor(private supportService: SupportService,
              private authorizationService: AuthorizationService,
              private reviewService: ReviewService) {
  }

  ngOnInit() {
    if (this.isClient) {
      this.displayedColumns = this.displayedColumnsForClient;
    }
    this.getTableDataSource();
  }

  getTableDataSource() {

    this.tickets
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: FeedBackRow[]) => {
      this.ungroupedSessionData = data;
      this.toggleData();
    });
  }

  toggleData() {
    this.tableDataSource = new MatTableDataSource(this.ungroupedSessionData);
    this.ungroupedColumns.forEach((u) => {
      if (!this.displayedColumns.some((c) => u === c)) {
        this.displayedColumns.push(u);
      }
    });

    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
    this.tableDataSource.paginator = this.paginator;
    this.tableDataLength = this.tableDataSource.data.length;
  }

  getReview(): Review {
    return this.reviewService.review;
  }

  setLoadedSession($event) {
    this.sessionChatInfo = $event;
    this.reviewService.conversationUid = this.sessionChatInfo.sessionInfo.conversationUid;
  }

  get isData(): boolean {
    return this.tableDataLength != null && this.tableDataLength > 0;
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead;
  }

  getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  setConversationUid(feedbackRow: FeedBackRow) {
    const index = this.tableDataSource.filteredData.indexOf(feedbackRow);
    this.currentPlayListIndex = index;
    this.sessionInfoPlayList = this.tableDataSource._orderData(this.tableDataSource.filteredData);
    this.conversationUid = feedbackRow.conversationUid;
  }

  unsetConversationUid() {
    if (this.getReview()) {
      const oldReviewId = this.getReview().id;
      this.updateActionsInTable(oldReviewId);
      this.conversationUid = undefined;
      this.reviewService.conversationUid = undefined;
    }
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  getNextReview($event) {
    const oldReviewId = this.reviewService.review.id;
    if (this.sessionInfoPlayList.length === 1) {
      this.sessionInfoPlayList = [];
      this.unsetConversationUid();
    } else {
      if ($event === -1) {
        this.sessionPlayListGoBack(this.currentPlayListIndex);
      } else {
        this.sessionPlayListGoNext(this.currentPlayListIndex);
      }
      this.updateActionsInTable(oldReviewId);
    }
  }

  updateActionsInTable(reviewId: number) {
    this.supportService.getTicketsByReviewAndActionType(reviewId, this.type).subscribe((actions) => {

      this.sessionInfoPlayList = this.sessionInfoPlayList
        .filter(s => s.reviewId !== reviewId || actions.some(a => s.actionId === a.actionId));
      this.ungroupedSessionData = this.ungroupedSessionData
        .filter(s => s.reviewId !== reviewId || actions.some(a => s.actionId === a.actionId));

      this.tableDataSource.data = this.sessionInfoPlayList;

    });
  }

  sessionPlayListGoBack(index: number) {
    if (index === 0) {
      this.conversationUid = this.sessionInfoPlayList[this.sessionInfoPlayList.length - 1].conversationUid;
      this.currentPlayListIndex = this.sessionInfoPlayList.length - 1;
    } else {
      this.conversationUid = this.sessionInfoPlayList[index - 1].conversationUid;
      this.currentPlayListIndex = index - 1;
    }
  }

  sessionPlayListGoNext(index: number) {
    if (index === this.sessionInfoPlayList.length - 1) {
      this.conversationUid = this.sessionInfoPlayList[0].conversationUid;
      this.currentPlayListIndex = this.sessionInfoPlayList.length + 1;
    } else {
      this.conversationUid = this.sessionInfoPlayList[index + 1].conversationUid;
      this.currentPlayListIndex = index + 1;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  //TODO Client rolecheck
  triggeredByText(userShort: UserShort) {
    if (userShort === null) {
      return '';
    } else if (userShort.userName === 'System') {
      return 'SYSTEM';
    } else if (userShort.roleName === 'ROLE_CLIENT') {
      return 'CLIENT';
    } else {
      return 'BCONNECT';
    }
  }

}
