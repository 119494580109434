import { DatePipe } from '@angular/common';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import { AuthorizationService } from '../../../../core/authorization.service';
import { FaqAddition } from '../../../../model/support/review/faq-addition.model';
import { Review } from '../../../../model/support/review/review.model';
import { ReviewService } from '../../../service/review.service';
import { SupportService } from '../../../service/support.service';
import { ClientFaqDialogComponent } from '../../feedback-client-faqs/client-faq-dialog/client-faq-dialog.component';
import { FaqDialogComponent } from './faq-dialog/faq-dialog.component';

@Component({
  selector: 'app-feedback-faqs',
  templateUrl: './feedback-faqs.component.html',
  styleUrls: ['./feedback-faqs.component.scss']
})
export class FeedbackFaqsComponent implements OnInit, OnDestroy {

  @Input()
  review: Review;
  products: Observable<string[]>;
  componentDestroyed$ = new Subject<void>();

  faqInEdit: FaqAddition;

  pipeForcedAt: Date;

  constructor(private supportService: SupportService,
              private reviewService: ReviewService,
              public authorizationService: AuthorizationService,
              private dialog: MatDialog,
              private datePipe: DatePipe) {
  }

  addedByClientFn = (faqAddition: FaqAddition) => {
    return !!faqAddition.createdByClient;
  }

  notAddedByClientFn = (faqAddition: FaqAddition) => {
    return !faqAddition.createdByClient &&
      (!this.isClient || ['ACCEPTED', 'TO_CLIENT'].some(s => s === faqAddition.faqAdditionProcessStage));
  }

  faqSortFn = (a: FaqAddition, b: FaqAddition) => {
    return b.id - a.id;
  }

  ngOnInit(): void {
    if (this.review) {
      this.products = this.supportService.getProductsWithAccount(this.review.conversationUid);
    }
  }

  addFaq() {
    this.reviewService.createFaqAddition(this.review.id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((fa) => {
        this.review.faqAddition.push(fa);
        this.pipeForcedAt = new Date();
        this.editFaq(fa);
      });
  }

  canEdit(faqAddition: FaqAddition): boolean {
    const stage = faqAddition.faqAdditionProcessStage;
    const canEditByStage = (stage === 'TO_CLIENT' && this.isClient) ||
      (stage === 'CREATED' && faqAddition.createdBy.userName === this.authorizationService.currentUser.name) ||
      (stage !== 'ACCEPTED' && this.isLCDOrBetter());
    const faqIsNew = faqAddition.id === undefined;
    const reviewClosed = this.review.closedAt !== null;
    return (canEditByStage || faqIsNew) && !reviewClosed;
  }

  editFaq(faqAddition: FaqAddition) {
    const existingIndex = this.review.faqAddition.indexOf(faqAddition);

    if (this.authorizationService.isClient) {
      this.dialog.open(ClientFaqDialogComponent,
        {
          minWidth: '550px',
          maxHeight: '90vh',
          data: {
            canEdit: true,
            faqAddition,
          }
        }).afterClosed().subscribe((result: {faqAddition: FaqAddition, action: 'submit' | 'reject' | 'concept' | 'deleted' | 'cancel'}) => {
          if (result) {
            let observable: Observable<FaqAddition>;
            switch (result.action) {
              case 'reject' :
                observable = this.reviewService.returnFromClient([result.faqAddition], faqAddition.reviewId, true)
                  .pipe(map((f) => f[0]));
                break;
              case 'submit' :
                observable = this.reviewService.returnFromClient([result.faqAddition], faqAddition.reviewId, false)
                  .pipe(map((f) => f[0]));
                break;
              case 'concept' :
                observable = this.reviewService.saveSingleFaqAddition(result.faqAddition, faqAddition.reviewId);
                break;
              case 'cancel' :
                observable = this.reviewService.cancelFaqAddition(faqAddition.id, faqAddition.reviewId);
                break;
              case 'deleted' :
                this.review.faqAddition.splice(existingIndex, 1);
                this.pipeForcedAt = new Date();
            }
            if (observable) {
              observable.subscribe((f) => {
                this.review.faqAddition[existingIndex] = f;
                this.pipeForcedAt = new Date();
              });
            }
          }
      });
    } else {
      this.faqInEdit = faqAddition;
    }

  }

  onClose(result: {action: string, faq?: FaqAddition}) {
    const index = this.review.faqAddition.indexOf(this.faqInEdit);

    if (result?.action === 'saved') {
      if (index >= 0) {
        Object.assign(this.review.faqAddition[index], result.faq);
      } else {
        this.review.faqAddition.push(result.faq);
      }
    } else if (result?.action === 'deleted') {
      this.review.faqAddition.splice(index, 1);
    }

    this.faqInEdit = undefined;
    this.pipeForcedAt = new Date();
  }

  processStageText(faqAddition: FaqAddition) {
    switch (faqAddition.faqAdditionProcessStage) {
      case 'CREATED': return 'New';
      case 'TO_SUPERVISOR_SUPPORT': return 'Submitted for review';
      case 'TO_CLIENT': return `Under review by client (${this.datePipe.transform(faqAddition.clientAlertedAt)})`;
      case 'ACCEPTED_BY_CLIENT': return 'Accepted by client';
      case 'REJECTED_BY_CLIENT': return 'Rejected by client';
      case 'ACCEPTED': return 'Accepted';
      case 'CANCELED': return 'Canceled';
    }
  }

  faqAdditionLabel(faqAddition: FaqAddition): string {
    const qna = faqAddition.qnas.find(q => q.question && q.question.length > 0);

    if (qna) {
      return `${qna.question.substr(0, 150)}...`;
    } else {
      return 'No questions';
    }
  }

  isLCDOrBetter(): boolean {
    return this.authorizationService.isInAdminGroup || this.authorizationService.isLcd;
  }

  public get isTeamLead(): boolean {
    return this.authorizationService.isTeamLead && !this.authorizationService.isLcd;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get disabled(): boolean {
    return this.review.closedAt !== undefined && this.review.closedAt !== null;
  }

  get showClientInfoMessage(): boolean {
    return this.isClient && this.review.faqAddition.some((f) => f.faqAdditionProcessStage === 'TO_CLIENT');
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.componentDestroyed$.next();
  }
}
